import './PrintDailyRunSheet.scss';
import { DateTime, Interval } from 'luxon';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Table, UncontrolledTooltip } from 'reactstrap';
import { PrintIcon } from 'src/images/icons';
import { TIME_24_SIMPLE_HOURCYCLE_23 } from 'src/infrastructure/dateUtils';
import PrintButton from 'src/views/components/PrintButton';
import WorkshopShiftConflictIcon from 'src/views/components/workshop/shiftConflictIcon/WorkshopShiftConflictIcon';
import WorkshopShiftProgressStatusIcon from 'src/views/components/workshop/shiftProgressIcon/WorkshopShiftProgressStatusIcon';

type StaffMemberDto = Common.Dtos.StaffMemberDto;
type StaffMemberShift = Workshop.Domain.Queries.GetShiftDetailsForDay.StaffMemberShift;
type DailyRunSheetJob = Workshop.Domain.Queries.GetDailyRunSheet.DailyRunSheetJob;
type WorkshopRosterForDayDto = Workshop.Domain.Queries.GetShiftDetailsForDay.WorkshopRosterForDayDto;

export interface IPrintDailyRunSheetProps {
  date: DateTime;
  dailyRunSheetItem: Workshop.Domain.Queries.GetDailyRunSheet.DailyRunSheetItem | undefined;
  loadDailyRunSheet: (date: DateTime, depotId: number) => Promise<void>;
  dailyRunSheetIsLoading: boolean;
  workshopDepotId: number;
  workshopDepotName: string;
}

export const RenderJobInterval = (start: string, end: string, date: DateTime) => {
  const startDateTime = DateTime.fromISO(start).toLocal();
  const endDateTime = DateTime.fromISO(end).toLocal();
  const jobInterval = Interval.fromDateTimes(startDateTime, endDateTime);
  const dateInterval = Interval.fromDateTimes(
    date.setZone('local', { keepLocalTime: true }).startOf('day'),
    date.setZone('local', { keepLocalTime: true }).endOf('day')
  );

  const isJobOnSameDay =
    dateInterval.contains(jobInterval.start) && dateInterval.contains(jobInterval.end);
  // If the job has the same start and end time, the intersection() method will return
  // null even if the job is contained within the day. In that case we will use the
  // job interval as the actual intersection.
  const intersection =
    jobInterval.hasSame('milliseconds') && isJobOnSameDay
      ? jobInterval
      : jobInterval.intersection(dateInterval);

  if (!intersection) {
    throw new Error('Job and current day do not intersect');
  }

  const bookingStartTime = intersection.start.toLocaleString(TIME_24_SIMPLE_HOURCYCLE_23);
  const bookingEndTime = intersection.end.toLocaleString(TIME_24_SIMPLE_HOURCYCLE_23);
  const continuedFromYesterday = !startDateTime.hasSame(date, 'day');
  const continuedToTomorrow = !endDateTime.hasSame(date, 'day');

  const bookingTime =
    continuedFromYesterday && continuedToTomorrow
      ? 'All Day'
      : continuedFromYesterday
      ? `Continued From Yesterday - ${bookingEndTime}`
      : continuedToTomorrow
      ? `${bookingStartTime} - Continued To Tomorrow`
      : `${bookingStartTime} - ${bookingEndTime}`;

  return <span>{bookingTime}</span>;
};

const renderShifts = (extended: boolean | undefined, staffMembers: StaffMemberShift[]) => {
  return extended ? (
    <ul>
      {staffMembers.map(s => (
        <li key={s.shiftId}>
          <Link to={`/workshop/shifts/${s.shiftId}`}>
            <WorkshopShiftConflictIcon hasConflicts={s.hasUnacceptedConflicts} />
            <WorkshopShiftProgressStatusIcon status={s.progressStatus} showWarningsOnly />
            {s.staffMember.name}
          </Link>
        </li>
      ))}
    </ul>
  ) : (
    (staffMembers.map(s => s.staffMember.name) || []).join(', ')
  );
};

export const formatDateTimeRange = (
  from: string,
  to: string,
  currentDate: DateTime | undefined
): IShiftPeriod => {
  var today = (currentDate || DateTime.local()).toLocaleString(DateTime.DATE_SHORT);
  var fromDateTime = DateTime.fromISO(from);
  var fromDate = fromDateTime.toLocaleString(DateTime.DATE_SHORT);
  var fromTime = fromDateTime.toLocaleString(TIME_24_SIMPLE_HOURCYCLE_23);
  var toDateTime = DateTime.fromISO(to);
  var toDate = toDateTime.toLocaleString(DateTime.DATE_SHORT);
  var toTime = toDateTime.toLocaleString(TIME_24_SIMPLE_HOURCYCLE_23);

  if (fromDate === toDate) {
    return { from: fromTime, to: toTime };
  }

  if (fromDate === today) {
    return { from: fromTime, to: toDateTime.toFormat('dd MMM HH:ss') };
  }

  if (toDate === today) {
    return { from: fromDateTime.toFormat('dd MMM HH:ss'), to: toTime };
  }

  return {
    from: fromDateTime.toLocaleString(DateTime.DATETIME_MED),
    to: toDateTime.toLocaleString(DateTime.DATETIME_MED),
  };
};

export const RenderRoster = (
  currentDate: DateTime,
  roster: WorkshopRosterForDayDto,
  extended?: boolean
) => {
  return (
    <table>
      <tbody>
        {roster.staffRoster.map((r, i) => {
          const period = formatDateTimeRange(r.start, r.end, currentDate);
          return (
            <tr key={i}>
              <td className="shift-name">{r.shiftName}</td>
              <td className="shift-period">{period.from}</td>
              <td className="shift-period">{period.to}</td>
              <td className="shift-members">{renderShifts(extended, r.shifts)}</td>
            </tr>
          );
        })}
        {extended &&
          roster.staffMembersOnLeave &&
          roster.staffMembersOnLeave.map((r, i) => {
            const period = formatDateTimeRange(r.start, r.end, currentDate);
            return (
              <React.Fragment key={i}>
                {extended ? (
                  <tr className="leave">
                    <td className="shift-name">{r.leaveCategory.description}</td>
                    <td className="shift-period">{period.from}</td>
                    <td className="shift-period">{period.to}</td>
                    <td className="shift-members">
                      <Link to={`/people/leaves/${r.leaveId}`}>
                        <WorkshopShiftConflictIcon hasConflicts={r.hasUnacceptedConflicts} />
                        {r.staffMember.name}
                      </Link>
                    </td>
                  </tr>
                ) : (
                  <tr className="leave">
                    <td className="shift-name">On Leave</td>
                    <td colSpan={2} />
                    <td>{r.staffMember.name}</td>
                  </tr>
                )}
              </React.Fragment>
            );
          })}
        {!extended && roster.staffMembersOnLeave && (
          <>
            <tr className="leave">
              <td className="shift-name">On Leave</td>
              <td colSpan={2} />
              <td>{(roster.staffMembersOnLeave.map(s => s.staffMember.name) || []).join(', ')}</td>
            </tr>
          </>
        )}
      </tbody>
    </table>
  );
};

interface IShiftPeriod {
  from: string;
  to: string;
}

class PrintDailyRunSheet extends Component<IPrintDailyRunSheetProps> {
  private readonly displayRoster = (): boolean =>
    this.props.dailyRunSheetItem !== undefined && this.props.dailyRunSheetItem.roster !== undefined;

  private readonly renderNotes = () => {
    const item = this.props.dailyRunSheetItem;
    const lines = item && item.note && item.note.content && item.note.content.split(/\r?\n/);

    return (
      <div>
        {lines &&
          lines.map((line, index) => (
            <div className="note-line" key={index}>
              {line}
            </div>
          ))}
        <div className="note-line">&nbsp;</div>
      </div>
    );
  };

  private readonly renderTasks = (item: DailyRunSheetJob) => {
    const lines: Workshop.Domain.Queries.GetDailyRunSheet.TaskDescription[] = item.tasks;
    return (
      <div>
        {lines &&
          lines.map(line => (
            <div key={line.taskId}>
              {line.taskNumber}
              {' - '}
              {line.description}
            </div>
          ))}
      </div>
    );
  };

  private readonly renderStaffMembers = (staffMembers: StaffMemberDto[]) => {
    const staffNames = staffMembers.map(m => m.name).join(', ');
    return staffNames;
  };

  private readonly renderSuppliers = (supplierNames: string[] | undefined) => {
    const names = !!supplierNames ? supplierNames : [];
    return names.join(', ');
  };

  private readonly renderAsset = (item: DailyRunSheetJob) => {
    const lowFloorIndicator = item.assetIsLowFloor ? ' (LF)' : '';
    return (
      <span>
        <span className="asset">{item.assetName}</span>
        {lowFloorIndicator}
      </span>
    );
  };

  private readonly renderJobs = () => (
    <Table>
      <thead>
        <tr>
          <th className="col-asset">Asset</th>
          <th className="col-task">Task</th>
          <th className="col-time">Booking Time</th>
          <th className="col-staff">Staff</th>
          <th className="col-notes">Notes</th>
        </tr>
      </thead>
      <tbody>
        {this.props.dailyRunSheetItem &&
          this.props.dailyRunSheetItem.jobs.map((job, index) => (
            <tr key={index}>
              <td className="col-asset">{this.renderAsset(job)}</td>
              <td className="col-task">{this.renderTasks(job)}</td>
              <td className="col-time">
                {RenderJobInterval(job.startDateTime, job.endDateTime, this.props.date)}
              </td>
              <td className="col-staff">{this.renderStaffMembers(job.staffMembers)}</td>
              <td className="col-notes">{this.renderSuppliers(job.supplierNames)}</td>
            </tr>
          ))}
      </tbody>
    </Table>
  );

  private readonly renderPrintContent = () => {
    return (
      <div className="print-daily-run-sheet-component">
        <div className="title">
          Daily Run Sheet for {this.props.date.toLocaleString(DateTime.DATE_HUGE)}
          <br />
          <small>{this.props.workshopDepotName}</small>
        </div>
        {this.displayRoster() && (
          <div className="roster-section">
            <div className="subtitle">Roster</div>
            <div className="roster">
              {this.props.dailyRunSheetItem &&
                this.props.dailyRunSheetItem.roster &&
                RenderRoster(this.props.date, this.props.dailyRunSheetItem.roster)}
            </div>
          </div>
        )}
        <div className="notes-section">
          <div className="subtitle">Important Notes For Today</div>
          <div className="note-lines">{this.renderNotes()}</div>
        </div>
        <div className="jobs-section">
          <div className="subtitle">Jobs Scheduled For Today</div>
          <div className="jobs">{this.renderJobs()}</div>
        </div>
      </div>
    );
  };

  render() {
    const { loadDailyRunSheet, date, workshopDepotId } = this.props;
    return (
      <div>
        <span id="print-daily-run-sheet-button">
          <PrintButton
            size="sm"
            outline
            loadDataAsync={() => loadDailyRunSheet(date, workshopDepotId)}
            printContent={this.renderPrintContent}>
            <PrintIcon size="sm" />
          </PrintButton>
        </span>
        <UncontrolledTooltip placement="right" target="print-daily-run-sheet-button">
          Print Daily Run Sheet
        </UncontrolledTooltip>
      </div>
    );
  }
}

export default PrintDailyRunSheet;
