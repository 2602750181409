import './TimesheetTable.scss';

import Table from 'reactstrap/lib/Table';
import { DateFormat } from 'src/views/components/DateFormat';
import { DayOfWeekFormat } from 'src/views/components/DayOfWeekFormat';
import {
  jobStatusDescription,
  JobType,
  jobTypeDescription,
  onCallPaymentStatusDescription,
  WorkshopShiftType,
} from 'src/api/enums';
import { DurationFormat } from 'src/views/components/DurationFormat';
import { Link } from 'react-router-dom';
import TimesheetDayTotals from '../TimesheetDayTotals';
import { AmendedHoursFormat } from 'src/views/components/AmendedHoursFormat';
import TimesheetTotals from '../TimesheetTotals';
import {
  MapTimesheetItemToRecordsTotals,
  MapTimesheetTotalsToRecordsTotals,
} from 'src/views/routes/people/timesheet/MaintainTimesheet/MaintainTimesheet';
import { AllowancesSummaryTable } from 'src/views/kioskRoutes/components/presentational/Timesheet/AllowancesSummaryTable';
import { ENABLE_SHOW_WAITING_TIME } from 'src/appSettings';

type TimesheetShiftAllowanceItem = Common.Dtos.TimesheetShiftAllowanceItem;
type TimesheetJobAllowanceItem = Common.Dtos.TimesheetJobAllowanceItem;
type TimesheetItemForDriver = Common.Dtos.TimesheetItemForDriver;
type PrefilledTimesheetItemForDriver = Common.Dtos.PrefilledTimesheetItemForDriver;
type TimesheetJobItem = Common.Dtos.TimesheetJobItem;
type TimesheetWorkshopShiftItem = Common.Dtos.TimesheetWorkshopShiftItem;
type TimesheetLeaveItem = Common.Dtos.TimesheetLeaveItem;
type TimesheetPublicHolidayItem = Common.Dtos.TimesheetPublicHolidayItem;

interface ITimesheetJobTableProps {
  jobs: TimesheetJobItem[];
  isWorkshopKiosk: boolean;
  jobAllowances: TimesheetJobAllowanceItem[] | undefined;
  day: string;
}

const TimesheetJobTable: React.FC<ITimesheetJobTableProps> = ({
  jobs,
  isWorkshopKiosk,
  jobAllowances,
  day,
}) => {
  const hasAllowances = !!jobAllowances && jobAllowances.length > 0;
  const showWaitingTime =
    ENABLE_SHOW_WAITING_TIME &&
    jobs.filter(
      job => job.jobTypeId === JobType.Charter || job.jobTypeId === JobType.ContractCharter
    ).length > 0;
  return (
    <div className="timesheet-job-table-component">
      {jobs && jobs.length ? (
        <Table borderless>
          <thead>
            <tr>
              <th>Job</th>
              <th>Type</th>
              <th>Status</th>
              <th>Description</th>
              <th className="small-cell">Start</th>
              <th className="small-cell">End</th>
              <th className="small-cell">Total Hours</th>
              <th className="small-cell">Unpaid Breaks</th>
              <th className="small-cell">Working Hours</th>
              <th className="small-cell">Paid Hours</th>
              {showWaitingTime && <th className="small-cell">Waiting Time</th>}
            </tr>
          </thead>
          {jobs.map(record => {
            // we merge paid hours cells so that additional rows are displayed straight under description
            // instead of being pushed down by amendments - it's done to save the space on the page and printout
            const rowSpanCount =
              1 +
              (record.reasonForCompletionDetailsOverride || record.overridePaidHoursReason
                ? 1
                : 0) +
              (record.amendedPaidHoursReason ? 1 : 0) +
              (record.calculationLog ? 1 : 0) +
              (record.reasonForDifference ? 1 : 0) +
              (hasAllowances ? 1 : 0);
            const allowancesForJobRecord =
              jobAllowances?.filter(a => a.jobId === record.jobId && a.date === day) || [];

            return (
              <tbody key={record.jobId}>
                <tr>
                  <td>
                    {isWorkshopKiosk ? (
                      <>{record.jobNumber}</>
                    ) : (
                      <Link to={`/kiosk/complete-job/${record.jobId}/view`}>
                        {record.jobNumber}
                        {record.jobIsCancelled ? ' (Cancelled)' : ''}
                      </Link>
                    )}
                  </td>
                  <td>{jobTypeDescription(record.jobTypeId)}</td>
                  <td>{jobStatusDescription(record.jobStatusId)}</td>
                  <td>{record.jobDescription}</td>
                  <td>
                    <AmendedHoursFormat
                      hours={record.start}
                      amendedHours={record.calculatedStart}
                    />
                  </td>
                  <td>
                    <AmendedHoursFormat hours={record.end} amendedHours={record.calculatedEnd} />
                  </td>
                  <td>
                    <DurationFormat value={record.calculatedTotalHours} />
                  </td>
                  <td>
                    <AmendedHoursFormat
                      hours={record.unpaidBreaks}
                      amendedHours={record.calculatedUnpaidBreaks}
                    />
                  </td>
                  <td>
                    <DurationFormat value={record.calculatedWorkingHours} />
                  </td>
                  <td rowSpan={rowSpanCount}>
                    <AmendedHoursFormat
                      hours={record.calculatedWorkingHours}
                      defaultAmendedHours={record.paidHours}
                      amendedHours={record.amendedPaidHours}
                    />
                  </td>
                  {showWaitingTime && (
                    <td rowSpan={rowSpanCount}>
                      <AmendedHoursFormat
                        hours={record.waitingTime ?? '00:00'}
                        defaultAmendedHours={record.waitingTime}
                        amendedHours={record.waitingTime}
                      />
                    </td>
                  )}
                </tr>
                {record.overridePaidHoursReason || record.reasonForCompletionDetailsOverride ? (
                  <tr>
                    <td />
                    <td colSpan={8} className="details-line">
                      <span className="block">
                        <strong>Override reason:</strong>{' '}
                        {[record.overridePaidHoursReason, record.reasonForCompletionDetailsOverride]
                          .filter(x => !!x)
                          .join(', ')}
                      </span>
                    </td>
                  </tr>
                ) : null}
                {record.amendedPaidHoursReason ? (
                  <tr>
                    <td />
                    <td colSpan={8} className="details-line">
                      <span className="block">
                        <strong>Amendment reason:</strong> {record.amendedPaidHoursReason}
                      </span>
                    </td>
                  </tr>
                ) : null}
                {record.calculationLog ? (
                  <tr>
                    <td />
                    <td colSpan={8} className="details-line">
                      <span className="block">
                        <strong>Calculation log:</strong> {record.calculationLog}
                      </span>
                    </td>
                  </tr>
                ) : null}
                {record.reasonForDifference ? (
                  <tr>
                    <td />
                    <td colSpan={8} className="details-line">
                      <strong>Reason for difference:</strong> {record.reasonForDifference}
                      {record.approvalNumber ? (
                        <>
                          {' '}
                          <strong className="approval-number">Approval number:</strong>{' '}
                          {record.approvalNumber}
                        </>
                      ) : null}
                    </td>
                  </tr>
                ) : null}
                {hasAllowances && allowancesForJobRecord.length > 0 ? (
                  <tr>
                    <td />
                    <td colSpan={8} className="details-line">
                      <strong>Allowances: </strong>
                      {allowancesForJobRecord
                        .map((item: TimesheetJobAllowanceItem) => {
                          return item.allowanceName + ' x ' + item.quantity;
                        })
                        .join(', ')}
                    </td>
                  </tr>
                ) : null}
              </tbody>
            );
          })}
        </Table>
      ) : null}
    </div>
  );
};

interface ITimesheetWorkshopShiftTableProps {
  shifts: TimesheetWorkshopShiftItem[];
  workshopShiftAllowances: TimesheetShiftAllowanceItem[] | undefined;
  day: string;
}

const TimesheetWorkshopShiftTable: React.FC<ITimesheetWorkshopShiftTableProps> = ({
  shifts,
  workshopShiftAllowances,
  day,
}) => {
  const hasAllowances = !!workshopShiftAllowances && workshopShiftAllowances.length > 0;

  return (
    <div className="timesheet-workshop-shift-table-component">
      {shifts && shifts.length ? (
        <Table borderless>
          <thead>
            <tr>
              <th className="small-cell">Shift</th>
              <th className="small-cell nowrap">On Call</th>
              <th>ShiftName</th>
              <th className="small-cell">Start</th>
              <th className="small-cell">End</th>
              <th className="small-cell">Total Hours</th>
              <th className="small-cell">Unpaid Breaks</th>
              <th className="small-cell">Working Hours</th>
              <th className="small-cell">Paid Hours</th>
            </tr>
          </thead>
          {shifts.map((record, recordIndex) => {
            // we merge paid hours cells so that additional rows are displayed straight under description
            // instead of being pushed down by amendments - it's done to save the space on the page and printout
            const rowSpanCount =
              1 +
              (record.amendedPaidHoursReason ? 1 : 0) +
              (record.calculationLog ? 1 : 0) +
              (record.overtimeReason ? 1 : 0) +
              (record.boundsChangeReason ? 1 : 0) +
              (hasAllowances ? 1 : 0);
            const allowancesForShiftRecord =
              workshopShiftAllowances?.filter(
                a => a.shiftId === record.shiftId && a.date === day
              ) || [];

            return (
              <tbody key={`${record.shiftId}-${recordIndex}`}>
                <tr>
                  <td>{record.shiftNumber}</td>
                  <td>
                    {record.shiftType === WorkshopShiftType.OnCall &&
                      onCallPaymentStatusDescription(record.onCallPaymentStatus)}
                  </td>
                  <td>
                    {record.shiftName} <br />
                    <small>({record.shiftDepotName})</small>
                  </td>
                  <td>
                    <AmendedHoursFormat
                      hours={record.start}
                      amendedHours={record.calculatedStart}
                    />
                  </td>
                  <td>
                    <AmendedHoursFormat hours={record.end} amendedHours={record.calculatedEnd} />
                  </td>
                  <td>
                    <DurationFormat value={record.calculatedTotalHours} />
                  </td>
                  <td>
                    <AmendedHoursFormat
                      hours={record.unpaidBreaks}
                      amendedHours={record.calculatedUnpaidBreaks}
                    />
                  </td>
                  <td>
                    <DurationFormat value={record.calculatedWorkingHours} />
                  </td>
                  <td rowSpan={rowSpanCount}>
                    {record.shiftType !== WorkshopShiftType.OnCall && (
                      <AmendedHoursFormat
                        hours={record.calculatedWorkingHours}
                        defaultAmendedHours={record.paidHours}
                        amendedHours={record.amendedPaidHours}
                      />
                    )}
                  </td>
                </tr>
                {record.boundsChangeReason ? (
                  <tr>
                    <td />
                    <td colSpan={7} className="details-line">
                      <span className="block">
                        <strong>Reason for changing shift bounds:</strong>{' '}
                        {record.boundsChangeReason}
                      </span>
                    </td>
                  </tr>
                ) : null}
                {record.overtimeReason ? (
                  <tr>
                    <td />
                    <td colSpan={7} className="details-line">
                      <span className="block">
                        <strong>Overtime reason:</strong> {record.overtimeReason}
                      </span>
                    </td>
                  </tr>
                ) : null}
                {record.amendedPaidHoursReason ? (
                  <tr>
                    <td />
                    <td colSpan={7} className="details-line">
                      <span className="block">
                        <strong>Amendment reason:</strong> {record.amendedPaidHoursReason}
                      </span>
                    </td>
                  </tr>
                ) : null}
                {record.calculationLog ? (
                  <tr>
                    <td />
                    <td colSpan={7} className="details-line">
                      <span className="block">
                        <strong>Calculation log:</strong> {record.calculationLog}
                      </span>
                    </td>
                  </tr>
                ) : null}
                {hasAllowances && allowancesForShiftRecord.length > 0 ? (
                  <tr>
                    <td />
                    <td colSpan={8} className="details-line">
                      <strong>Allowances: </strong>
                      {allowancesForShiftRecord
                        .map((item: TimesheetShiftAllowanceItem) => {
                          return item.allowanceName + ' x ' + item.quantity;
                        })
                        .join(', ')}
                    </td>
                  </tr>
                ) : null}
              </tbody>
            );
          })}
        </Table>
      ) : null}
    </div>
  );
};

interface ITimesheetLeaveTableProps {
  leaves: TimesheetLeaveItem[];
  home: string;
}

const TimesheetLeaveTable: React.FC<ITimesheetLeaveTableProps> = ({ leaves, home }) => {
  return (
    <div className="timesheet-leave-table-component">
      {leaves && leaves.length ? (
        <Table borderless>
          <thead>
            <tr>
              <th>Leave Type</th>
              <th className="small-cell">Start</th>
              <th className="small-cell">End</th>
              <th className="small-cell">Leave Hours</th>
              <th className="small-cell">Paid Hours</th>
            </tr>
          </thead>
          {leaves.map(record => {
            return (
              <tbody key={record.leaveId}>
                <tr>
                  <td>
                    <Link to={`${home}/leave`}>{record.leaveCategory.description}</Link>
                  </td>
                  <td>
                    <DurationFormat value={record.start} />
                  </td>
                  <td>
                    <DurationFormat value={record.end} />
                  </td>
                  <td>
                    <DurationFormat value={record.leaveHours} />
                  </td>
                  <td rowSpan={record.amendedPaidHoursReason ? 2 : 1}>
                    <AmendedHoursFormat
                      hours={record.paidHours}
                      amendedHours={record.amendedPaidHours}
                    />
                  </td>
                </tr>
                {record.amendedPaidHoursReason ? (
                  <tr>
                    <td colSpan={4} className="details-line">
                      <span className="block">
                        <strong>Amendment reason:</strong> {record.amendedPaidHoursReason}
                      </span>
                    </td>
                  </tr>
                ) : null}
              </tbody>
            );
          })}
        </Table>
      ) : null}
    </div>
  );
};

interface ITimesheetPublicHolidaysTableProps {
  publicHolidays: TimesheetPublicHolidayItem[];
}

const TimesheetPublicHolidayTable: React.FC<ITimesheetPublicHolidaysTableProps> = ({
  publicHolidays,
}) => {
  return (
    <div className="timesheet-public-holiday-table-component">
      {publicHolidays && publicHolidays.length ? (
        <Table borderless>
          <thead>
            <tr>
              <th>Public Holiday</th>
              <th className="small-cell">Total Hours</th>
              <th className="small-cell">Paid Hours</th>
            </tr>
          </thead>
          {publicHolidays.map(record => {
            return (
              <tbody key={record.holidayId}>
                <tr>
                  <td>{record.description}</td>
                  <td>
                    <DurationFormat value={record.publicHolidayHours} />
                  </td>
                  <td rowSpan={record.amendedPaidHoursReason ? 2 : 1}>
                    <AmendedHoursFormat
                      hours={record.paidHours}
                      amendedHours={record.amendedPaidHours}
                    />
                  </td>
                </tr>
                {record.amendedPaidHoursReason ? (
                  <tr>
                    <td colSpan={2} className="details-line">
                      <span className="block">
                        <strong>Amendment reason:</strong> {record.amendedPaidHoursReason}
                      </span>
                    </td>
                  </tr>
                ) : null}
              </tbody>
            );
          })}
        </Table>
      ) : null}
    </div>
  );
};

interface ITimesheetTableProps {
  timesheet: TimesheetItemForDriver | PrefilledTimesheetItemForDriver;
  isWorkshopKiosk: boolean;
  home: string;
}

const TimesheetTable: React.FC<ITimesheetTableProps> = ({ timesheet, isWorkshopKiosk, home }) => {
  const allowancesSummary = (timesheet as TimesheetItemForDriver).timesheetAllowanceSummary;
  const shiftAllowances = (timesheet as TimesheetItemForDriver).timesheetShiftAllowanceItems;
  const jobAllowances = (timesheet as TimesheetItemForDriver).timesheetJobAllowanceItems;
  return (
    <div className="kiosk-timesheet-table-component">
      {timesheet.records.map(record => {
        const noRecords =
          (!record.jobs || record.jobs.length === 0) &&
          (!record.leaves || record.leaves.length === 0) &&
          (!record.publicHolidays || record.publicHolidays.length === 0) &&
          (!record.workshopShifts || record.workshopShifts.length === 0);

        const summary = noRecords ? undefined : MapTimesheetTotalsToRecordsTotals(record.totals);
        return (
          <div className="day-component dark-style" key={record.day}>
            <h4 className="title">
              <DayOfWeekFormat value={record.day} /> - <DateFormat value={record.day} />
            </h4>
            <TimesheetJobTable
              jobs={record.jobs}
              isWorkshopKiosk={isWorkshopKiosk}
              jobAllowances={jobAllowances?.filter(a => a.date === record.day)}
              day={record.day}
            />
            <TimesheetWorkshopShiftTable
              shifts={record.workshopShifts}
              workshopShiftAllowances={shiftAllowances?.filter(a => a.date === record.day)}
              day={record.day}
            />
            <TimesheetLeaveTable leaves={record.leaves} home={home} />
            <TimesheetPublicHolidayTable publicHolidays={record.publicHolidays} />
            {noRecords ? (
              <span className="no-records">No records</span>
            ) : summary ? (
              <div className="day-summary">
                <TimesheetDayTotals summary={summary} />
              </div>
            ) : null}
          </div>
        );
      })}
      <div className="timesheet-summary">
        <h4>Timesheet Summary</h4>
        <TimesheetTotals summary={MapTimesheetItemToRecordsTotals(timesheet)} />
      </div>
      {!!allowancesSummary && allowancesSummary.length > 0 ? (
        <div className="timesheet-summary">
          <h4>Allowances</h4>
          <div>
            <AllowancesSummaryTable allowances={allowancesSummary} />
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default TimesheetTable;
