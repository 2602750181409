import { UiRouteName } from 'src/domain/baseTypes';
import { IRootStoreModel } from 'src/domain/entities/RootStoreModel';
import UiRoute from 'src/domain/services/buildUiRoutes/UiRoute';

export default function buildUiRoutes(store: IRootStoreModel) {
  const documentCreate = new UiRoute(
    UiRouteName.workshopDocumentCreate,
    '/workshop/documents/create',
    () => 'Create Document',
    false
  );

  const documentEdit = new UiRoute(
    UiRouteName.workshopDocumentEdit,
    '/workshop/documents/:id',
    () => 'Manage Document',
    false
  );

  const documentsList = new UiRoute(
    UiRouteName.workshopDocumentsList,
    '/workshop/documents',
    () => 'Documents',
    false,
    false,
    [documentCreate, documentEdit]
  );

  return {
    [UiRouteName.workshopDocumentsList]: documentsList,
    [UiRouteName.workshopDocumentCreate]: documentCreate,
    [UiRouteName.workshopDocumentEdit]: documentEdit,
  };
}
