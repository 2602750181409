import CrudPage, { CrudPageMode, ICrudPageDef } from 'src/views/components/Page/pages/CrudPage';
import { RouteComponentProps } from 'react-router-dom';
import { observer } from 'mobx-react';
import { useRootStore } from 'src/domain/entities/RootStoreModel';
import React, { useEffect, useState } from 'react';
import {
  ActionType,
  FieldType,
  IFieldData,
  PagePrimarySize,
  PaneType,
  ShellModalSize,
} from 'src/views/definitionBuilders/types';
import { IFile } from 'src/views/components/Page/fields/MultiFilePageField';
import { maxFileSizeInMegaBytes } from 'src/views/components/Attachment/attachmentHelper';
import { FilePdfIcon, PlusIcon, TrashIcon } from 'src/images/icons';
import { getAddAssetsModalDef } from './getAddAssetsModalDef';
import { ChangeState } from 'src/api/enums';
import { getSubmitCloseModalActionGroupDef } from 'src/views/definitionBuilders/common';

type AssetNameDto = Common.Dtos.AssetNameDto;

type DocumentData = {
  isAvailableOnDriversTablet: boolean;
  assets: AssetNameDto[];
  file: IFile;
  fileName: string;
};

type UpdateData = {
  id: string;
  assets: AssetForUpdate[];
  fileName: string;
  isAvailableOnDriversTablet: boolean;
};

export interface AssetForUpdate {
  id: string;
  name: string;
  changeState: ChangeState;
}

export interface IMaintainDocumentProps {
  mode: CrudPageMode;
  route: RouteComponentProps<{ [x: string]: string | undefined }>;
}

const MaintainDocument: React.FC<IMaintainDocumentProps> = observer(
  (props: IMaintainDocumentProps) => {
    const rootStore = useRootStore();
    const assetsModel = rootStore.assets;
    const documentModel = rootStore.assetDocument;

    const onCreateDocument = documentModel.createDocument;
    const onUpdateDocument = documentModel.updateDocument;
    const replaceDocumentCommand = documentModel.replaceDocument;
    const deleteDocumentCommand = documentModel.deleteDocument;

    const loadDocument = documentModel.loadDocument;
    const document = documentModel.document;
    const [, setIsLoadingDocument] = useState<boolean | undefined>(undefined);

    const assets = assetsModel.assetListItems;

    const isUpdateMode = props.mode === 'update';
    const isCreateMode = props.mode === 'create';

    const documentId = props.route.match.params.id!;

    const assetsToLoad = assets.map(item => ({
      id: item.id,
      name: item.name,
    }));

    useEffect(() => {
      assetsModel.loadAssetListItems().then();
    }, []);

    const loadData = async () => {
      if (documentId) {
        setIsLoadingDocument(true);
        Promise.all([loadDocument(documentId)]).finally(() => setIsLoadingDocument(false));
      }
    };

    const handlePreSubmitForCreate = (a: DocumentData) => {
      return {
        isAvailableOnDriversTablet: a.isAvailableOnDriversTablet,
        assetIds: a.assets.map(a => a.id),
        file: a.file,
        fileName: a.fileName,
      };
    };

    const handlePreSubmitForUpdate = (a: UpdateData) => {
      return {
        id: documentId,
        assetIds: a.assets.filter(x => x.changeState !== ChangeState.Deleted).map(x => x.id),
        fileName: a.fileName,
        isAvailableOnDriversTablet: a.isAvailableOnDriversTablet,
      };
    };

    const getPageDef = (updating: boolean): ICrudPageDef => {
      return {
        primarySize: PagePrimarySize.half,
        primarySection: {
          title: isUpdateMode ? `Document ${document?.documentNumber}` : 'Create Document',
          primaryActions: [
            {
              actions: [
                {
                  actionType: ActionType.actionCollection,
                  hidden: updating || !isUpdateMode,
                  actionGroups: [
                    {
                      actions: [
                        {
                          actionType: ActionType.modalActionButton,
                          label: `Replace Document Attachment`,
                          icon: <FilePdfIcon fixedWidth />,
                          modalSize: ShellModalSize.oneThird,
                          modalDef: () => ({
                            title: `Replace Document Attachment`,
                            asForm: true,
                            panels: [
                              {
                                panes: [
                                  {
                                    paneType: PaneType.formFieldsPane,
                                    columnCount: 1,
                                    fields: [
                                      {
                                        fieldType: FieldType.fileField,
                                        label: 'Attachment',
                                        mandatory: true,
                                        dataAddr: 'fileReplacement',
                                        validate: fapi => {
                                          const fapiTyping = fapi as IFieldData<IFile>;
                                          if (fapiTyping.fieldValue && fapiTyping.fieldValue.file) {
                                            if (
                                              fapiTyping.fieldValue.file.type !== 'application/pdf'
                                            ) {
                                              return 'Invalid file type. Please select a pdf';
                                            }
                                            const maxSizeInBytes =
                                              maxFileSizeInMegaBytes * 1024 * 1024;
                                            if (fapiTyping.fieldValue.file.size > maxSizeInBytes) {
                                              return `${(
                                                fapiTyping.fieldValue.file.size /
                                                1024 /
                                                1024
                                              ).toFixed(
                                                2
                                              )}MB attachment too large. Attachments must be under 20MB`;
                                            }
                                          }

                                          return undefined;
                                        },
                                      },
                                      {
                                        fieldType: FieldType.textField,
                                        label: 'File name',
                                        mandatory: true,
                                        dataAddr: 'fileName',
                                        hidden: fapi => isCreateMode && !fapi.parentValue.file,
                                        validate: fapi => {
                                          if (
                                            fapi.fieldValue &&
                                            !fapi.fieldValue.endsWith('.pdf')
                                          ) {
                                            return 'Please ensure the filename ends with .pdf';
                                          }
                                          return undefined;
                                        },
                                      },
                                    ],
                                  },
                                ],
                              },
                            ],
                            secondaryActions: [getSubmitCloseModalActionGroupDef('Submit')],
                            onFormSubmit: values =>
                              replaceDocumentCommand({
                                file: values.fileReplacement,
                                fileName: values.fileName,
                                existingDocumentId: documentId,
                              }),
                          }),
                        },
                        {
                          actionType: ActionType.modalActionButton,
                          label: 'Delete Document',
                          icon: <TrashIcon fixedWidth />,
                          hidden: updating || !isUpdateMode,
                          modalSize: ShellModalSize.oneQuarter,
                          modalDef: _ => ({
                            title: 'Delete Document',
                            asForm: true,
                            panels: [
                              {
                                panes: [
                                  {
                                    paneType: PaneType.customPane,
                                    render: __ => (
                                      <div>
                                        <p>This Action is not reversible.</p>
                                        <p>Are you sure you want to delete this document</p>
                                      </div>
                                    ),
                                  },
                                ],
                              },
                            ],
                            secondaryActions: [getSubmitCloseModalActionGroupDef('Ok')],
                            onFormSubmit: () => deleteDocumentCommand(documentId),
                          }),
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
          panels: [
            {
              panes: [
                {
                  paneType: PaneType.formFieldsPane,
                  columnCount: 1,
                  fields: [
                    {
                      fieldType: FieldType.fileField,
                      label: 'Attachment',
                      mandatory: true,
                      hidden: !isCreateMode,
                      dataAddr: 'file',
                      validate: fapi => {
                        const fapiTyping = fapi as IFieldData<IFile>;
                        if (fapiTyping.fieldValue && fapiTyping.fieldValue.file) {
                          if (fapiTyping.fieldValue.file.type !== 'application/pdf') {
                            return 'Invalid file type. Please select a pdf';
                          }
                          const maxSizeInBytes = maxFileSizeInMegaBytes * 1024 * 1024;
                          if (fapiTyping.fieldValue.file.size > maxSizeInBytes) {
                            return `${(fapiTyping.fieldValue.file.size / 1024 / 1024).toFixed(
                              2
                            )}MB attachment too large. Attachments must be under 20MB`;
                          }
                        }

                        return undefined;
                      },
                      onChange: fapi => {
                        fapi.setFormValue(
                          ['fileName'],
                          (fapi.formValues.file && fapi.formValues.file.name) || ''
                        );
                      },
                    },
                    {
                      fieldType: FieldType.textField,
                      label: 'File name',
                      mandatory: true,
                      dataAddr: 'fileName',
                      hidden: fapi => isCreateMode && !fapi.parentValue.file,
                      validate: fapi => {
                        if (fapi.fieldValue && !fapi.fieldValue.endsWith('.pdf')) {
                          return 'Please ensure the filename ends with .pdf';
                        }
                        return undefined;
                      },
                    },
                    {
                      fieldType: FieldType.yesNoField,
                      tooltip:
                        "Specify if this attachment should appear for driver's to view in the tablet",
                      label: "Available on Driver's Tablet?",
                      dataAddr: 'isAvailableOnDriversTablet',
                      hidden: fapi => !fapi.parentValue.fileName,
                      mandatory: true,
                    },
                  ],
                },
                {
                  paneType: PaneType.tablePane,
                  dataAddr: 'assets',
                  title: 'Assets*',
                  neverEditable: true,
                  rowKey: d => d.paneValue.id,
                  validate: d => {
                    if (isCreateMode || updating) {
                      const assets: AssetForUpdate[] = d.parentValue.assets;
                      if (assets.length === 0) {
                        return 'At least one asset is required';
                      }
                      if (assets.every(a => a.changeState === ChangeState.Deleted)) {
                        return 'At least one asset is required';
                      }
                    }
                    return undefined;
                  },
                  fields: [
                    {
                      fieldType: FieldType.textField,
                      label: 'Name',
                      dataAddr: 'name',
                      readonly: true,
                    },
                    {
                      fieldType: FieldType.actionListField,
                      dataAddr: 'fake',
                      hidden: !(isCreateMode || updating),
                      columnAutoHide: true,
                      actionGroups: [
                        {
                          actions: [
                            {
                              actionType: ActionType.removeArrayItemActionButton,
                              label: 'Remove Line',
                              icon: <TrashIcon />,
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  paneType: PaneType.actionListPane,
                  hidden: !(isCreateMode || updating),
                  actionGroups: [
                    {
                      actions: [
                        {
                          actionType: ActionType.modalActionButton,
                          label: 'Add Asset',
                          level: 'primary',
                          icon: <PlusIcon />,
                          modalSize: ShellModalSize.half,
                          modalDef: getAddAssetsModalDef(assetsToLoad as AssetNameDto[]),
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
          onFormPreSubmit: isUpdateMode ? handlePreSubmitForUpdate : handlePreSubmitForCreate,
          onFormSubmit: isUpdateMode ? onUpdateDocument : onCreateDocument,
        },
      };
    };

    return (
      <CrudPage
        key={documentId}
        def={({ updating }) => getPageDef(updating)}
        mode={props.mode}
        onLoadData={loadData}
        data={document}
        createDefaultData={{ assets: [] }}
      />
    );
  }
);

export default MaintainDocument;
