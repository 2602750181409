import inject from 'src/views/injectFromKioskStore';
import Leave, { ILeaveProps } from 'src/views/kioskRoutes/workshopKiosk/Leave/Leave';
import { TIMEZONE } from 'src/appSettings';

const Container = inject<ILeaveProps>((allStores, p) => ({
  leaves: allStores.rootStore.kiosk.leaves.leaves.slice(),
  loadLeavesForDriver: allStores.rootStore.kiosk.leaves.loadLeavesForDriver,
  userDisplayName: allStores.rootStore.account.checkedName,
  canUseTimesheets: allStores.rootStore.account.canUseTimesheets,
  timezone: TIMEZONE,
  loadLeaveCategories: allStores.rootStore.kiosk.leaves.loadLeaveCategories,
  leaveCategories: allStores.rootStore.kiosk.leaves.leaveCategories.slice(),
}))(Leave);

export default Container;
