import { UiRouteName } from 'src/domain/baseTypes';
import { IRootStoreModel } from 'src/domain/entities/RootStoreModel';
import UiRoute from './UiRoute';
import buildWorkshopConfiguration from './workshop/configuration/workshopConfig';
import buildWorkshopAssets from './workshop/workshopAssets';
import buildWorkshopJobs from './workshop/workshopJobs/workshopJobs';
import buildWorkshopTasks from './workshop/tasks/tasks';
import buildWorkshopDefects from './workshop/workshopDefects';
import buildOperationsDefects from './operations/operationsDefects';
import buildWorkshopChecklists from './workshop/workshopChecklists';
import buildWorkshopInventory from './workshop/workshopInventory/workshopInventory';
import buildCustomers from './operations/sales/customer/customer';
import buildQuotes from './operations/sales/quote/quote';
import buildHolidays from './operations/holiday/holiday';
import buildVehicleTypes from './operations/sales/vehicleType/vehicleType';
import buildBoardingPoints from './operations/sales/boardingPoint/boardingPoint';
import buildShifts from './operations/urban/shift/shift';
import buildRosters from './operations/urban/roster/roster';
import buildRosterGroups from './operations/urban/rosterGroup/rosterGroup';
import buildOperationsJobs from './operations/job/job';
import buildStaffMembers from './people/staffMembers/staffMembers';
import buildRailTemplates from './operations/rail/railTemplates/railTemplates';
import buildRailBookings from './operations/rail/railBookings/railBookings';
import buildNotes from './people/notes/notes';
import buildRoles from './people/roles/roles';
import buildTimesheetAllowances from './people/timesheetAllowances/timesheetAllowances';
import buildSms from './people/sms/sms';
import buildBookingsForOps from './operations/bookingsForOps/bookingsForOps';
import buildOpsTasks from './operations/task/task';
import buildCodeOfConduct from './compliance/codeOfConduct/codeOfConduct';
import buildLostProperty from './compliance/lostProperty/lostProperty';
import buildCustomerComplaint from './compliance/customerComplaint/customerComplaint';
import buildCctvRequest from './compliance/cctvRequest/cctvRequest';
import buildFatigue from './compliance/fatigue/fatigue';
import buildAuthorisedDevices from './operations/authorisedDevices/authorisedDevices';
import buildSubcontractors from './operations/subcontractor/subcontractor';
import buildSkillSpecs from './people/staffMembers/skillSpecs';
import buildExtraType from './operations/sales/extraType/extraType';
import buildHourlyRates from './operations/sales/hourlyRate/hourlyRate';
import buildLeave from './people/leaves/leaves';
import buildTimesheet from './people/timesheets/timesheets';
import buildDriverAppUsers from './people/driverAppUsers/driverAppUsers';
import buildShiftTemplates from './workshop/shiftTemplates/shiftTemplates';
import buildRosterTemplates from './workshop/rosterTemplates/rosterTemplates';
import buildWorkshopShifts from './workshop/shifts/shifts';
import buildWorkshopRosters from './workshop/rosters/rosters';
import buildOperationsDiaryRoutes from './operations/operationsDiary/operationsDiary';
import buildReportableEventRoutes from './compliance/reportableEvents/reportableEvents';
import buildGenerateJobOperationRoutes from './operations/generateJobOperation/generateJobOperation';
import buildOperationsListAssetOdometerReadings from './operations/asset/listAssetOdometerReadings';
import buildAccountingSection from './operations/accounting/accountingSection';
import buildExternalIdentities from './people/externalIdentities/externalIdentities';
import buildEquipmentTypeRoutes from './people/configuration/equipmentTypes';
import buildDocumentationSection from './documentation/documentationSection';
import buildProcessDriverAuthorizationRoutes from './operations/processDriverAuthorization/processDriverAuthorization';
import buildFatigueBreachEvents from './compliance/fatigueBreachEvents/fatigueBreachEvents';
import buildPurchaseTransactions from './workshop/purchaseTransactions/purchaseTransactions';
import buildCharterContracts from './operations/charterContracts/charterContracts';

export default function buildUiRoutes(store: IRootStoreModel) {
  const workshopConfiguration = buildWorkshopConfiguration(store);
  const assets = buildWorkshopAssets(store);
  const jobs = buildWorkshopJobs(store);
  const defects = buildWorkshopDefects(store);
  const tasks = buildWorkshopTasks(store);
  const checklists = buildWorkshopChecklists(store);
  const inventory = buildWorkshopInventory(store);
  const purchaseTransactions = buildPurchaseTransactions(store);

  const customers = buildCustomers(store);
  const quotes = buildQuotes(store);
  const holidays = buildHolidays(store);
  const vehicleTypes = buildVehicleTypes(store);
  const extraTypes = buildExtraType(store);
  const boardingPoints = buildBoardingPoints(store);
  const shifts = buildShifts(store);
  const rosters = buildRosters(store);
  const rosterGroups = buildRosterGroups(store);
  const operationsJobs = buildOperationsJobs(store);
  const staffMembers = buildStaffMembers(store);
  const leaves = buildLeave(store);
  const skillSpecs = buildSkillSpecs(store);
  const railTemplates = buildRailTemplates(store);
  const railBookings = buildRailBookings(store);
  const bookingForOps = buildBookingsForOps(store);
  const notes = buildNotes(store);
  const roles = buildRoles(store);
  const timesheetAllowances = buildTimesheetAllowances(store);
  const sms = buildSms(store);
  const opsTasks = buildOpsTasks(store);
  const cctvRequests = buildCctvRequest(store);
  const fatigue = buildFatigue(store);
  const authorisedDevices = buildAuthorisedDevices(store);
  const operationsDiary = buildOperationsDiaryRoutes(store);
  const reportableEvent = buildReportableEventRoutes(store);
  const externalIdentities = buildExternalIdentities(store);
  const equipmentTypes = buildEquipmentTypeRoutes(store);
  const fatigueBreachEvents = buildFatigueBreachEvents(store);
  const charterContracts = buildCharterContracts(store);

  const lostProperty = buildLostProperty(store);
  const codeOfConduct = buildCodeOfConduct(store);
  const customerComplaint = buildCustomerComplaint(store);

  const subcontractor = buildSubcontractors(store);
  const hourlyRates = buildHourlyRates(store);
  const timesheets = buildTimesheet(store);
  const driverAppUsers = buildDriverAppUsers(store);
  const operationsDefects = buildOperationsDefects(store);
  const shiftTemplates = buildShiftTemplates(store);
  const rosterTemplates = buildRosterTemplates(store);
  const workshopShifts = buildWorkshopShifts(store);
  const workshopRosters = buildWorkshopRosters(store);
  const generateJobOperation = buildGenerateJobOperationRoutes(store);

  const operationsAssetOdometerReadings = buildOperationsListAssetOdometerReadings(store);

  const accountingSection = buildAccountingSection(store);

  const documentationSection = buildDocumentationSection(store);

  const processDriverAuthorization = buildProcessDriverAuthorizationRoutes(store);

  const workshop = new UiRoute(UiRouteName.workshop, '/workshop', () => 'Workshop', true, false, [
    workshopConfiguration.workshopOwnerList,
    workshopConfiguration.workshopEngineList,
    workshopConfiguration.workshopAssetGroupsList,
    workshopConfiguration.workshopPartsGroupsList,
    workshopConfiguration.workshopPartCategoriesList,
    workshopConfiguration.workshopMaintainComponentTypes,
    workshopConfiguration.workshopMaintainLabourPrices,
    workshopConfiguration.workshopTechSpecsEdit,
    workshopConfiguration.workshopTaskSubcategoriesList,
    workshopConfiguration.workshopMachineryScheduleTemplate,
    workshopConfiguration.workshopDocumentsList,
    assets.workshopAssetServicesDueByDate,
    assets.workshopAssetServicesDueByKms,
    assets.workshopAssetsView,
    checklists.workshopChecklistsView,
    jobs.workshopJobSchedule,
    jobs.workshopMachineryJobSchedule,
    jobs.workshopWeeklyJobSchedule,
    jobs.workshopJobsList,
    defects.workshopDefectsList,
    tasks.workshopTasksList,
    inventory.workshopPartsList,
    inventory.workshopSupplierList,
    inventory.workshopPurchaseOrdersList,
    inventory.workshopPurchaseOrderEdit,
    shiftTemplates.workshopShiftTemplateList,
    rosterTemplates.workshopRosterTemplateList,
    workshopShifts.workshopShiftList,
    workshopRosters.workshopRosterList,
    purchaseTransactions.workshopPurchaseTransactionsList,
  ]);

  const accounting = new UiRoute(
    UiRouteName.accounting,
    '/accounting',
    () => 'Accounting',
    true,
    false,
    [
      accountingSection.accountingContractCharterBookings,
      accountingSection.accountingRailBookings,
      accountingSection.accountingBookings,
      accountingSection.reporting,
    ]
  );

  const documentation = new UiRoute(
    UiRouteName.documentation,
    '/documentation',
    () => 'Documentation',
    true,
    false,
    [
      documentationSection.documentationPageLayout,
      documentationSection.documentationListPage,
      documentationSection.documentationCrudPage,
      documentationSection.documentationForms,
      documentationSection.documentationActions,
      documentationSection.documentationModals,
    ]
  );

  const sales = new UiRoute(UiRouteName.sales, '/sales', () => 'Sales', true, false, [
    quotes.salesQuoteList,
    customers.salesCustomerList,
    boardingPoints.salesBoardingPointList,
    extraTypes.salesExtraTypeList,
    hourlyRates.salesHourlyRateManage,
    vehicleTypes.salesVehicleTypeList,
  ]);

  const compliance = new UiRoute(
    UiRouteName.compliance,
    '/compliance',
    () => 'Compliance',
    true,
    false,
    [
      lostProperty.complianceLostPropertyList,
      codeOfConduct.complianceCodeOfConductList,
      cctvRequests.complianceCctvRequestList,
      customerComplaint.complianceCustomerComplaintList,
      reportableEvent.complianceReportableEventList,
      fatigueBreachEvents.complianceFatigueBreachEventList,
    ]
  );

  const operations = new UiRoute(
    UiRouteName.operations,
    '/operations',
    () => 'Operations',
    true,
    false,
    [
      shifts.urbanShiftList,
      rosters.urbanRosterList,
      rosterGroups.urbanRosterGroupList,
      operationsJobs.operationsJobList,
      operationsJobs.operationsJobForCharterList,
      railTemplates.operationsRailTemplateList,
      railBookings.operationsRailBookingList,
      bookingForOps.operationsBookingForOpsManage,
      opsTasks.operationsTasksForDay,
      holidays.operationsHolidayList,
      subcontractor.operationsSubcontractorList,
      operationsDiary.operationsOperationsDiaryEntryList,
      operationsDiary.operationsMaintainOperationsDiaryEntrySubjects,
      authorisedDevices.operationsAuthorisedDevicesList,
      generateJobOperation.operationsJobListGenerateJobsOptions,
      operationsAssetOdometerReadings.operationsListAssetOdometerReadings,
      processDriverAuthorization.operationsProcessDriverAuthorization,
      charterContracts.operationsCharterContractsList,
    ]
  );

  const people = new UiRoute(UiRouteName.people, '/people', () => 'People', true, false, [
    staffMembers.peopleStaffMemberList,
    notes.peopleNoteList,
    roles.peopleRoleList,
    timesheetAllowances.peopleTimesheetAllowanceList,
    sms.peopleSmsList,
    skillSpecs.peopleSkillSpecsEdit,
    leaves.peopleLeaveList,
    timesheets.peopleTimesheetList,
    externalIdentities.peopleExternalIdentityList,
    equipmentTypes.peopleEquipmentTypesList,
  ]);

  const home = new UiRoute(UiRouteName.home, '/', () => 'Home', false, false, [
    workshop,
    sales,
    operations,
    people,
    compliance,
    accounting,
  ]);

  return {
    [UiRouteName.home]: home,
    [UiRouteName.workshop]: workshop,
    ...assets,
    ...defects,
    ...tasks,
    ...jobs,
    ...checklists,
    ...inventory,
    ...purchaseTransactions,
    ...workshopConfiguration,
    ...shiftTemplates,
    ...rosterTemplates,
    ...workshopShifts,
    ...workshopRosters,
    [UiRouteName.sales]: sales,
    ...quotes,
    ...customers,
    ...boardingPoints,
    ...extraTypes,
    ...hourlyRates,
    [UiRouteName.operations]: operations,
    ...shifts,
    ...rosters,
    ...rosterGroups,
    ...operationsJobs,
    ...railTemplates,
    ...railBookings,
    ...bookingForOps,
    ...opsTasks,
    ...holidays,
    ...vehicleTypes,
    ...subcontractor,
    ...operationsDefects,
    ...operationsDiary,
    ...authorisedDevices,
    ...generateJobOperation,
    ...operationsAssetOdometerReadings,
    ...processDriverAuthorization,
    ...charterContracts,
    assetsWithUnknownKms: new UiRoute(
      UiRouteName.assetsWithUnknownKmsList,
      '/operations/assets-unknown-kms/list',
      () => 'Assets with unknown Kms',
      false
    ),
    [UiRouteName.compliance]: compliance,
    ...codeOfConduct,
    ...customerComplaint,
    ...cctvRequests,
    ...lostProperty,
    ...fatigue,
    ...reportableEvent,
    ...fatigueBreachEvents,
    [UiRouteName.people]: people,
    ...staffMembers,
    ...skillSpecs,
    ...notes,
    ...roles,
    ...timesheetAllowances,
    ...sms,
    ...leaves,
    ...timesheets,
    ...driverAppUsers,
    ...externalIdentities,
    ...equipmentTypes,
    [UiRouteName.accounting]: accounting,
    ...accountingSection,
    [UiRouteName.documentation]: documentation,
    ...documentationSection,
  };
}
