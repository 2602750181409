import cn from 'classnames';
import { DateTime, Interval } from 'luxon';
import { observer } from 'mobx-react';
import { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { allLeaveStatus, allLeaveType, HolidayType, LeaveStatus } from 'src/api/enums';
import { TIMEZONE } from 'src/appSettings';
import { ListPageLoadCause } from 'src/domain/baseTypes';
import { IScheduledHoliday } from 'src/domain/entities/operations/holiday/HolidaysModel';
import { IScheduledLeave } from 'src/domain/entities/people/leave/ScheduledLeaveModel';
import { useRootStore } from 'src/domain/entities/RootStoreModel';
import { getZonedDaysFromInterval } from 'src/infrastructure/dateUtils';
import Page from 'src/views/components/Page';
import CalendarPicker from 'src/views/components/Page/fields/subfields/CalendarPicker';
import { IHolidayEvent, IScheduleEvent } from 'src/views/components/Scheduler/ScheduleEvent';
import WeeklySchedule from 'src/views/components/Scheduler/WeeklySchedule';
import {
  ActionType,
  FieldDefs,
  FieldType,
  IPageDef,
  PagePrimarySize,
  PaneType,
  ShellModalSize,
} from 'src/views/definitionBuilders/types';
import styles from './LeaveCalendar.module.scss';
import LeaveCalendarActionBar from './LeaveCalendarActionBar';
import { LeaveCalendarLegend } from './LeaveCalendarLegend';
import LeaveSchedulePopover from './LeaveSchedulePopover';

const zone = TIMEZONE;
const leaveTypeKey = 'leaveId';
const leaveStatusKey = 'statusId';
const staffMemberKey = 'staffMemberId';
const roleKey = 'roleId';
const depotKey = 'depotId';

const LeaveCalendar: React.FC = observer(() => {
  const rootStore = useRootStore();
  const peopleModel = rootStore.people;
  const leaveModel = peopleModel.scheduledLeave;
  const leave = peopleModel.scheduledLeave.leave;
  const staffMemberModel = peopleModel.staffMembers;
  const query = new URLSearchParams(location.search);
  const nowUtc = DateTime.utc();

  const localIsoWeek = query.get('week') || nowUtc.setZone(zone).toISOWeekDate();
  const localWeek = DateTime.fromISO(localIsoWeek, { zone });

  const previousQuerystring = useRef(location.search);
  const [selectedEventId, setSelectedEventId] = useState<string | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const history = useHistory();
  const loadLeaveCategories = peopleModel.leaves.loadLeaveCategories;
  const leaveCategories = peopleModel.leaves.leaveCategories.slice();

  useEffect(() => {
    staffMemberModel.loadAllStaffMemberNames;
    peopleModel.roles.listItems({
      loadCause: ListPageLoadCause.mount,
      query: { size: 9999 },
    });
    peopleModel.depots.loadStaffDepots();
    loadLeave();
    loadLeaveCategories();
  }, []);

  useEffect(() => {
    if (previousQuerystring.current === location.search) return;

    loadLeave();
    previousQuerystring.current = location.search;
  }, [location.search]);

  const loadLeave = async () => {
    setIsLoading(true);
    const query = new URLSearchParams(location.search);

    const leaveTypeIds = query.getAll(leaveTypeKey).map(i => parseInt(i));
    const statusTypeIds = query.getAll(leaveStatusKey).map(i => parseInt(i));
    const staffMemberIds = query.getAll(staffMemberKey);
    const roleIds = query.getAll(roleKey);
    const depotIds = query.getAll(depotKey).map(i => parseInt(i));

    await Promise.all([
      rootStore.operations.holidays.loadHolidays(localWeek, depotIds),
      leaveModel.loadLeave(
        localWeek.weekYear,
        localWeek.weekNumber,
        zone,
        leaveTypeIds,
        statusTypeIds,
        staffMemberIds,
        roleIds,
        depotIds
      ),
    ]).then(() => setIsLoading(false));
  };

  const getLeaveStatusClass = (status: LeaveStatus) => {
    switch (status) {
      case LeaveStatus.Approved:
        return 'status-approved';
      case LeaveStatus.Declined:
        return 'status-declined';
      default:
        return 'status-default';
    }
  };

  const getHolidayTypeClass = (holidayType: HolidayType) => {
    switch (holidayType) {
      case HolidayType.PublicHoliday:
        return 'public-holiday';
      default:
        return 'school-holiday';
    }
  };

  const getTimeDisplay = (interval: Interval, start: DateTime, end: DateTime) => {
    const days = Math.ceil(interval.length('days'));

    if (
      start.toISOTime() === start.startOf('day').toISOTime() &&
      end.toISOTime() === end.startOf('day').toISOTime()
    ) {
      if (days === 1) {
        return '1 day';
      }

      return `${days} days - ${start
        .setLocale('local')
        .toLocaleString(DateTime.DATE_SHORT)} - ${end
        .setLocale('local')
        .toLocaleString(DateTime.DATE_SHORT)}`;
    }

    if (days > 1) {
      return `${days} days - ${start
        .setLocale('local')
        .toLocaleString(DateTime.DATETIME_SHORT)} - ${end
        .setLocale('local')
        .toLocaleString(DateTime.DATETIME_SHORT)}`;
    }

    return `${start.setLocale('local').toLocaleString(DateTime.TIME_SIMPLE)} - ${end
      .setLocale('local')
      .toLocaleString(DateTime.TIME_SIMPLE)}`;
  };

  const getEventBody = (leave: IScheduledLeave) => (
    <div
      className={cn(
        styles['event-item'],
        styles[getLeaveStatusClass(leave.status)],
        leave.id === selectedEventId ? styles['selected'] : ''
      )}>
      <div className={cn(styles['event-description'])}>
        {leave.staffMemberName} ({leave.employmentStatus})
        <br />
        <small>
          {getTimeDisplay(
            leave.leaveInterval,
            DateTime.fromISO(leave.leaveStart, { zone }),
            DateTime.fromISO(leave.leaveEnd, { zone })
          )}
        </small>
      </div>
    </div>
  );

  const getHolidayBody = (holiday: IScheduledHoliday) => (
    <div
      className={cn(
        styles['event-item'],
        styles[getHolidayTypeClass(HolidayType[HolidayType[holiday.holidayType.id]])],
        holiday.id === selectedEventId ? styles['selected'] : ''
      )}>
      <div className={cn(styles['event-description'])}>
        {holiday.holidayType.description
          .split(' ')
          .map(n => n[0])
          .join('')}
        : {holiday.description}
        <br />
        <small>
          {getTimeDisplay(
            holiday.holidayInterval,
            DateTime.fromISO(holiday.startDate, { zone }),
            DateTime.fromISO(holiday.endDate, { zone }).plus({ days: -1 })
          )}
        </small>
      </div>
    </div>
  );

  const leaveToScheduleEventItems = () => {
    return leave
      .map(l =>
        getZonedDaysFromInterval(l.leaveInterval, zone).map(d => ({
          leave: l,
          day: d.toISODate(),
        }))
      )
      .reduce(
        (m, x) =>
          x.reduce(
            (_, y) =>
              m.set(y.day, [
                ...(m.get(y.day) || []),
                {
                  eventId: y.leave.id,
                  eventTitle: y.leave.staffMemberName,
                  eventBody: getEventBody(y.leave),
                  eventStartDateTime: DateTime.fromISO(y.leave.leaveStart),
                  eventEndDateTime: DateTime.fromISO(y.leave.leaveEnd),
                  eventInterval: y.leave.leaveInterval,
                  eventCalloutBody: LeaveSchedulePopover({
                    leave: y.leave,
                    timezone: zone,
                    leaveCategories,
                  }),
                },
              ]),
            m
          ),
        new Map<string, IScheduleEvent[]>()
      );
  };

  const holidaysToScheduleEventItems = () => {
    return rootStore.operations.holidays.holidays
      .map(h =>
        getZonedDaysFromInterval(h.holidayInterval, zone).map(d => ({
          holiday: h,
          day: d.toISODate(),
        }))
      )
      .reduce(
        (m, x) =>
          x.reduce(
            (_, y) =>
              m.set(y.day, [
                ...(m.get(y.day) || []),
                {
                  eventId: y.holiday.id,
                  eventTitle: y.holiday.description,
                  eventBody: getHolidayBody(y.holiday),
                  eventStartDateTime: DateTime.fromISO(y.holiday.startDate),
                  eventEndDateTime: DateTime.fromISO(y.holiday.endDate),
                  eventInterval: y.holiday.holidayInterval,
                  holidayType: HolidayType[HolidayType[y.holiday.holidayType.id]],
                },
              ]),
            m
          ),
        new Map<string, IHolidayEvent[]>()
      );
  };

  const getWeekNavigationLink = (week: string) => {
    const query = new URLSearchParams(location.search);

    query.set('week', week);

    return `calendar?${query.toString()}`;
  };

  const gotoWeek = (date: DateTime) => {
    history.push(getWeekNavigationLink(date.startOf('week').toISOWeekDate()));
  };

  const getFilterFieldDefs = (): { [key: string]: FieldDefs } => {
    return {
      staffMemberIds: {
        fieldType: FieldType.selectMultiField,
        dataAddr: staffMemberKey,
        label: 'Staff Member',
        valueKey: 'id',
        descriptionKey: 'name',
        optionItems: staffMemberModel.activeStaffMembers,
        useValueOnly: true,
      } as FieldDefs,
      roles: {
        fieldType: FieldType.selectMultiField,
        label: 'Role',
        dataAddr: roleKey,
        valueKey: 'id',
        descriptionKey: 'name',
        optionItems: peopleModel.roles.items,
        useValueOnly: true,
      } as FieldDefs,
      leaveTypes: {
        fieldType: FieldType.selectMultiField,
        label: 'Leave Type',
        dataAddr: leaveTypeKey,
        valueKey: 'value',
        descriptionKey: 'description',
        useValueOnly: true,
        optionItems: allLeaveType,
      },
      leaveStatuses: {
        fieldType: FieldType.selectMultiField,
        label: 'Leave Status',
        dataAddr: leaveStatusKey,
        valueKey: 'value',
        descriptionKey: 'description',
        useValueOnly: true,
        optionItems: allLeaveStatus,
      },
      depots: {
        fieldType: FieldType.selectMultiField,
        dataAddr: depotKey,
        label: 'Depot',
        valueKey: 'id',
        descriptionKey: 'description',
        optionItems: peopleModel.depots.staffDepots,
        useValueOnly: true,
      } as FieldDefs,
    };
  };

  const getPageDef = (): IPageDef => {
    const filterFieldDefsLookup = getFilterFieldDefs();

    return {
      primarySize: PagePrimarySize.full,
      primarySection: {
        title: 'Leave Calendar',
        subtitle: `${localWeek.setLocale('local').toLocaleString(DateTime.DATE_MED)} - ${localWeek
          .plus({ days: 6 })
          .setLocale('local')
          .toLocaleString(DateTime.DATE_MED)}`,
        panels: [
          {
            panes: [
              {
                paneType: PaneType.customPane,
                render: () => (
                  <div className={cn(styles['leave-calendar'])}>
                    <LeaveCalendarActionBar
                      zonedNow={nowUtc.setZone(zone)}
                      zonedWeekToDisplay={localWeek}
                      getNavigationLink={week => getWeekNavigationLink(week)}
                      isLoading={isLoading || leaveModel.loadingLeave}
                    />
                    <LeaveCalendarLegend />
                    <CalendarPicker
                      className={cn(styles['day-picker'])}
                      buttonClassName="day-picker-button"
                      today={localWeek}
                      value={localWeek}
                      onChange={selectedDate => gotoWeek(selectedDate)}
                    />
                    <hr />
                    <WeeklySchedule
                      now={nowUtc}
                      weekToDisplay={localWeek}
                      timezone={zone}
                      holidayEvents={holidaysToScheduleEventItems()}
                      scheduleEvents={leaveToScheduleEventItems()}
                      onEventSelected={event => setSelectedEventId(event?.eventId)}
                    />
                  </div>
                ),
              },
            ],
          },
        ],
        secondaryActions: [
          {
            actions: [
              {
                actionType: ActionType.filterActionButton,
                filterSize: ShellModalSize.oneQuarter,
                filterFields: Object.keys(filterFieldDefsLookup).map(k => filterFieldDefsLookup[k]),
              },
            ],
          },
        ],
      },
    };
  };

  return (
    <Page def={getPageDef()} showPrimarySectionSpinner={leaveModel.loadingLeave || isLoading} />
  );
});

export default LeaveCalendar;
