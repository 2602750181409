import { types, flow, getRoot } from 'mobx-state-tree';
import { getAjax } from 'src/domain/services';
import { IRootStoreModel } from 'src/domain/entities/RootStoreModel';
import { IFile } from 'src/views/components/Page/fields/MultiFilePageField';
import { getBase64 } from 'src/infrastructure/fileUtil';
import { isValidAttachment } from 'src/views/components/Attachment/attachmentHelper';

type CreateDocumentCommand = Workshop.Domain.Commands.Document.CreateDocument.CreateDocumentCommand;
type UpdateDocumentCommand = Workshop.Domain.Commands.Document.UpdateDocument.UpdateDocumentCommand;
type ReplaceDocumentCommand = Workshop.Domain.Commands.Document.ReplaceDocument.ReplaceDocumentCommand;
type DocumentDto = Common.Dtos.DocumentDto;

export type CreateDocumentData = {
  fileName: string;
  file: IFile;
  isAvailableOnDriversTablet: boolean;
  assetIds: string[];
};

export type ReplaceDocumentData = {
  file: IFile;
  fileName: string;
  existingDocumentId: string;
};

export type UpdateDocumentData = {
  id: string;
  fileName: string;
  isAvailableOnDriversTablet: boolean;
  assetIds: string[];
};
export const AssetDocumentModel = types
  .model('AssetDocumentModel', {
    document: types.maybe(types.frozen<DocumentDto>()),
  })
  .actions(self => {
    const ajax = getAjax(self);
    const root = getRoot(self) as IRootStoreModel;

    const createDocument = flow(function*(data: CreateDocumentData) {
      if (!isValidAttachment(data.file)) {
        return;
      }
      const command: CreateDocumentCommand = {
        fileName: data.fileName,
        assetIds: data.assetIds,
        mimeType: data.file.file!.type,
        data: yield getBase64(data.file.file!),
        isAvailableOnDriversTablet: data.isAvailableOnDriversTablet,
      };
      const result = yield ajax.workshop.documents.createDocument(command);
      root.history.push(`/workshop/documents/${result}`);
    });

    const loadDocument = flow(function*(DocumentId: string) {
      self.document = yield ajax.raw
        .get(`/api/workshop/Documents/${DocumentId}`)
        .toPromise()
        .then<DocumentDto>(r => r.response);
    });

    const updateDocument = flow(function*(data: UpdateDocumentData) {
      const command: UpdateDocumentCommand = {
        id: data.id,
        assetIds: data.assetIds,
        fileName: data.fileName,
        isAvailableOnDriversTablet: data.isAvailableOnDriversTablet,
      };
      yield ajax.workshop.documents.updateDocument(command, command.id);
      yield loadDocument(command.id);
    });

    const replaceDocument = flow(function*(data: ReplaceDocumentData) {
      const command: ReplaceDocumentCommand = {
        existingDocumentId: data.existingDocumentId,
        fileName: data.fileName,
        mimeType: data.file.file!.type,
        data: yield getBase64(data.file.file!),
      };
      const result = yield ajax.workshop.documents.replaceDocument(
        command,
        command.existingDocumentId
      );
      root.history.push(`/workshop/documents/${result}`);
    });

    const deleteDocument = flow(function*(DocumentId: string) {
      yield ajax.workshop.documents.deleteDocument(DocumentId);
      root.history.push(`/workshop/Documents?defaultFilter=true`);
    });

    return {
      createDocument,
      updateDocument,
      replaceDocument,
      deleteDocument,
      loadDocument,
    };
  });
