import { Redirect, Route, Switch } from 'react-router-dom';
import queryString from 'query-string';
import ErrorBoundary from 'src/views/components/ErrorBoundary';
import LoadingIndicator from 'src/views/components/FullPageLoadingIndicator';
import Shell from 'src/views/components/Shell';
import SignInFailed from './error/SignInFailed';
import Home from './home/HomeContainer';
import MaintainBookingForOps from './operations/bookingsForOps/maintainBookingForOps';
import ListHoliday from './operations/holiday/ListHoliday';
import MaintainHoliday from './operations/holiday/maintainHoliday/maintainHoliday';
import Allocations from './operations/job/allocations/Allocations';
import ListJob from './operations/job/listJob/ListJob';
import ListJobForCharter from './operations/job/listJobForCharter/ListJobForCharterContainer';
import OperationsMaintainJob from './operations/job/maintainJob/MaintainJob';
import OperationsMaintainJobProgress from './operations/job/maintainJobProgress/MaintainJobProgressContainer';
import Operations from './operations/Operations';
import CreateRailBooking from './operations/rail/createRailBooking/CreateRailBooking';
import ListRailBookings from './operations/rail/listRailBookings/ListRailBookings';
import ListRailTemplate from './operations/rail/listRailTemplates/ListRailTemplate';
import MaintainRailBooking from './operations/rail/maintainRailBooking/MaintainRailBooking';
import MaintainRailTemplate from './operations/rail/maintainRailTemplate/MaintainRailTemplate';
import ListBoardingPoint from './operations/sales/boardingPoints/ListBoardingPoint/ListBoardingPoint';
import MaintainBoardingPoint from './operations/sales/boardingPoints/maintainBoardingPoint/maintainBoardingPointContainer';
import ListCustomer from './operations/sales/customer/ListCustomer/ListCustomer';
import MaintainCustomer from './operations/sales/customer/MaintainCustomer/maintainCustomer';
import WorkshopMaintainJobProgress from './workshop/jobs/maintainJob/MaintainJobProgressContainer';

import ListVehicleType from './operations/sales/vehicleType/ListVehicleTypeContainer';
import MaintainVehicleType from './operations/sales/vehicleType/maintainVehicleType/maintainVehicleTypeContainer';

import Accounting from './operations/sales/quote/Accounting/AccountingContainer';
import BookingsForAccounting from './operations/sales/quote/Accounting/Bookings/BookingsForAccounting';
import RailBookingsForAccounting from './operations/sales/quote/Accounting/RailBookings/RailBookingsForAccounting';
import Reporting from './operations/sales/quote/Accounting/Reporting/Reporting';

import { RENDER_SMS_NAV_ELEMENTS } from 'src/appSettings';
import { UiRoutes } from 'src/domain/entities/app/HistoryModel';
import ListCctvRequest from 'src/views/routes/compliance/cctvRequest/listCctvRequest/ListCctvRequest';
import MaintainCctvRequestContainer from 'src/views/routes/compliance/cctvRequest/maintainCctvRequest/MaintainCctvRequestContainer';
import ListCodeOfConduct from 'src/views/routes/compliance/codeOfConduct/listCodeOfConduct/ListCodeOfConduct';
import MaintainCodeOfConduct from 'src/views/routes/compliance/codeOfConduct/maintainCodeOfConduct/MaintainCodeOfConductContainer';
import Compliance from 'src/views/routes/compliance/ComplianceContainer';
import ListCustomerComplaint from 'src/views/routes/compliance/customerComplaint/listCustomerComplaint/ListCustomerComplaint';
import MaintainCustomerComplaint from 'src/views/routes/compliance/customerComplaint/maintainCustomerComplaint/MaintainCustomerComplaintContainer';
import FatigueDetails from 'src/views/routes/compliance/fatigue/details/FatigueDetails';
import ListFatigueBreachEvents from 'src/views/routes/compliance/fatigueBreachEvents/listFatigueBreachEvents/ListFatigueBreachEvents';
import ListLostProperty from 'src/views/routes/compliance/lostProperty/listLostProperty/ListLostProperty';
import MaintainLostProperty from 'src/views/routes/compliance/lostProperty/maintainLostProperty/MaintainLostPropertyContainer';
import ListAssetOdometerReadingsContainer from 'src/views/routes/operations/asset/ListAssetOdometerReadingsContainer';
import ListAuthorisedDevices from 'src/views/routes/operations/authorisedDevices/ListAuthorisedDevices';
import ListGenerateJobsOperations from 'src/views/routes/operations/generateJobOperations/listGenerateJobsOperation/ListGenerateJobsOperationContainer';
import ListJobProgressWarnings from 'src/views/routes/operations/job/listJobProgressWarnings/ListJobProgressWarningsContainer';
import ListProcessDriverAuthorization from 'src/views/routes/operations/processDriverAuthorization/listProcessDriverAuthorization/ListProcessDriverAuthorizationContainer';
import ProcessResultsFile from 'src/views/routes/operations/processDriverAuthorization/processResultsFile/ProcessResultsFileContainer';
import MaintainHourlyRates from 'src/views/routes/operations/sales/hourlyRates/maintainHourlyRates';
import ListSubcontractor from 'src/views/routes/operations/subcontractor/ListSubcontractor/ListSubcontractorContainer';
import MaintainSubcontractor from 'src/views/routes/operations/subcontractor/MaintainSubcontractor/MaintainSubcontractorContainer';
import LeaveCalendar from 'src/views/routes/people/leaves/LeaveCalendar/LeaveCalendar';
import ListLeave from 'src/views/routes/people/leaves/ListLeave/ListLeave';
import MaintainLeave from 'src/views/routes/people/leaves/MaintainLeave/MaintainLeave';
import People from 'src/views/routes/people/PeopleContainer';
import MaintainSkillSpecs from 'src/views/routes/people/staffMembers/SkillSpecs/MaintainSkillSpecsContainer';
import ListTimesheet from 'src/views/routes/people/timesheet/ListTimesheet/ListTimesheets';
import MaintainTimesheet from 'src/views/routes/people/timesheet/MaintainTimesheet/MaintainTimesheet';
import CreateOdometerReadings from 'src/views/routes/workshop/assets/odometerReadings/CreateOdometerReadings/CreateOdometerReadingsContainer';
import ListDataFuel from 'src/views/routes/workshop/dataFuel/ListDataFuelEntriesContainer';
import ListDataFuelUploads from 'src/views/routes/workshop/dataFuel/uploads/ListDataFuelUploadsContainer';
import ListPurchaseTransactions from 'src/views/routes/workshop/purchaseOrders/ListPurchaseTransactions/ListPurchaseTransactions';
import CreateWorkshopRoster from 'src/views/routes/workshop/rosters/createRoster/CreateRoster';
import ListWorkshopRosters from 'src/views/routes/workshop/rosters/listRosters/ListRosters';
import MaintainWorkshopRoster from 'src/views/routes/workshop/rosters/maintainRoster/MaintainRoster';
import WorkshopRosterChanges from 'src/views/routes/workshop/rosters/rosterChanges/RosterChangesContainer';
import ListRosterTemplates from 'src/views/routes/workshop/rosterTemplates/listRosterTemplates/ListRosterTemplatesContainer';
import MaintainRosterTemplate from 'src/views/routes/workshop/rosterTemplates/maintainRosterTemplate/MaintainRosterTemplateContainer';
import ListWorkshopShifts from 'src/views/routes/workshop/shifts/listShifts/ListShifts';
import MaintainWorkshopShift from 'src/views/routes/workshop/shifts/maintainShift/MaintainShift';
import ListShiftTemplates from 'src/views/routes/workshop/shiftTemplates/listShiftTemplates/ListShiftTemplatesContainer';
import MaintainShiftTemplate from 'src/views/routes/workshop/shiftTemplates/maintainShiftTemplate/MaintainShiftTemplateContainer';
import ListReportableEvents from './compliance/reportableEvent/listReportableEvents/ListReportableEvents';
import MaintainReportableEvent from './compliance/reportableEvent/maintainReportableEvent/MaintainReportableEventContainer';
import ListOperationsDiary from './operations/operationsDiary/ListOperationsDiary/ListOperationsDiary';
import MaintainOperationsDiaryEntry from './operations/operationsDiary/MaintainOperationsDiaryEntry/MaintainOperationsDiaryEntryContainer';
import MaintainSubjects from './operations/operationsDiary/MaintainSubjects/MaintainSubjectsContainer';
import ListExtraType from './operations/sales/extraType/ListExtraType/ListExtraType';
import MaintainExtraType from './operations/sales/extraType/ManageExtraType/MaintainExtraType';
import MaintainTasksForDay from './operations/task/tasksForDay/MaintainTasksForDay';
import ListAssetsWithUnknownKms from './operations/unknownKms/ListAssetsWithUnknownKmsContainer';
import ListRoster from './operations/urban/roster/listRoster/ListRoster';
import MaintainRoster from './operations/urban/roster/maintainRoster/MaintainRosterContainer';
import ListShift from './operations/urban/shift/listShift/ListShift';
import MaintainShift from './operations/urban/shift/maintainShift/maintainShift';
import ListExternalIdentities from './people/externalIdentity/ListExternalIdentities/ListExternalIdentitiesContainer';
import ListNotes from './people/notes/ListNotes/ListNotes';
import MaintainNote from './people/notes/MaintainNote/MaintainNoteContainer';
import ListRoles from './people/roles/ListRoles/ListRolesContainer';
import MaintainRole from './people/roles/MaintainRole/MaintainRoleContainer';
import ListSms from './people/sms/ListSms/ListSms';
import MaintainSms from './people/sms/MaintainSms/MaintainSmsContainer';
import ListStaffMembers from './people/staffMembers/ListStaffMembers/ListStaffMembers';
import MaintainStaffMember from './people/staffMembers/MaintainStaffMember/MaintainStaffMember';
import ListTimesheetAllowances from './people/timesheetAllowances/ListTimesheetAllowances/ListTimesheetAllowancesContainer';
import MaintainTimesheetAllowance from './people/timesheetAllowances/MaintainTimesheetAllowance/MaintainTimesheetAllowanceContainer';
import ListAssetGroups from './workshop/assetGroups/listAssetGroups/ListAssetGroupsContainer';
import MaintainAssetGroup from './workshop/assetGroups/maintainAssetGroup/MaintainAssetGroupContainer';
import AssetComponentsRegister from './workshop/assets/componentsRegister/ComponentsRegisterContainer';
import AssetCubicRegister from './workshop/assets/cubicRegister/CubicRegister';
import ListAsset from './workshop/assets/listAssets/ListAssets';
import MaintainAsset from './workshop/assets/maintainAssets/MaintainAsset';
import OdometerReadings from './workshop/assets/odometerReadings/OdometerReadingsContainer';
import AssetPartsRegister from './workshop/assets/partsRegister/PartsRegisterContainer';
import AssetServiceSchedule from './workshop/assets/serviceHistory/ServiceHistoryContainer';
import ServicesDuebyDate from './workshop/assets/servicesDue/ServicesDueByDateContainer';
import ServicesDuebyKms from './workshop/assets/servicesDue/ServicesDueByKms';
import ListUnallocatedAsset from './workshop/assets/unallocatedAssets/ListUnallocatedAssetsContainer';
import ListChecklists from './workshop/checklists/ListChecklists/ListChecklistsContainer';
import MaintainChecklist from './workshop/checklists/maintainChecklist/MaintainChecklist';
import MaintainComponentTypes from './workshop/componentTypes/maintainComponentTypes/MaintainComponentTypesContainer';
import ListDefects from './workshop/defects/ListDefects/ListDefects';
import MaintainDefect from './workshop/defects/maintainDefect/MaintainDefectContainer';
import ListEngines from './workshop/engines/ListEngines/ListEnginesContainer';
import MaintainEngine from './workshop/engines/maintainEngine/MaintainEngineContainer';
import RecordStocktake from './workshop/inventory/recordStocktake/recordStocktakeContainer';
import JobSchedule from './workshop/jobs/jobSchedule/JobSchedule';
import ListJobs from './workshop/jobs/listJobs/ListJobs';
import MachineryJobSchedule from './workshop/jobs/machineryJobSchedule/MachineryJobSchedule';
import MaintainJob from './workshop/jobs/maintainJob/MaintainJobContainer';
import WeeklyJobSchedule from './workshop/jobs/weeklyJobSchedule/WeeklyJobSchedule';
import ListOwners from './workshop/owners/ListOwners/ListOwnersContainer';
import MaintainOwner from './workshop/owners/MaintainOwner/MaintainOwner';
import ListPartCategories from './workshop/parts/listPartCategories/ListPartCategoriesContainer';
import ListParts from './workshop/parts/ListParts/ListParts';
import ListPartsGroups from './workshop/parts/listPartsGroups/ListPartsGroupsContainer';
import ListPartTransactions from './workshop/parts/ListPartTransactions/ListPartTransactionsContainer';
import MaintainPart from './workshop/parts/maintainPart/maintainPartContainer';
import MaintainPartCategory from './workshop/parts/maintainPartCategory/MaintainPartCategory';
import MaintainPartsGroup from './workshop/parts/maintainPartsGroup/MaintainPartsGroupContainer';
import ListPurchaseOrders from './workshop/purchaseOrders/ListPurchaseOrders/ListPurchaseOrders';
import MaintainGoodsReceived from './workshop/purchaseOrders/MaintainGoodsReceived/MaintainGoodsReceivedContainer';
import MaintainPurchaseOrder from './workshop/purchaseOrders/MaintainPurchaseOrder/MaintainPurchaseOrderContainer';
import MaintainPurchaseOrderInvoices from './workshop/purchaseOrders/MaintainPurchaseOrderInvoices/MaintainPurchaseOrderInvoicesContainer';
import MaintainReturnedPartCredit from './workshop/purchaseOrders/MaintainReturnedPartCredit/MaintainReturnedPartCreditContainer';
import ListSuppliers from './workshop/suppliers/ListSuppliers/ListSuppliers';
import MaintainSupplier from './workshop/suppliers/MaintainSupplier/MaintainSupplierContainer';
import ListTasks from './workshop/tasks/listTasks/ListTasks';
import ListTaskSubcategories from './workshop/tasks/listTaskSubcategories/ListTaskSubcategories';
import MaintainTask from './workshop/tasks/maintainTask/MaintainTaskContainer';
import MaintainTaskSubcategory from './workshop/tasks/maintainTaskSubcategory/MaintainTaskSubcategory';
import MaintainTechSpecDropdownOptions from './workshop/techSpecDropdownOptions/MaintainTechSpecDropdownOptionsContainer';
import MaintainTechSpecs from './workshop/techSpecs/MaintainTechSpecsContainer';
import Workshop from './workshop/WorkshopContainer';

import Sales from 'src/views/routes/operations/sales/Sales';
import ListQuotes from './operations/sales/quote/ListQuotes/ListQuotes';
import MaintainQuote from './operations/sales/quote/maintainQuote/MaintainQuote';

import MaintainFatigueBreachEvent from 'src/views/routes/compliance/fatigueBreachEvents/MaintainFatigueBreachEvent/MaintainFatigueBreachEventContainer';
import PageLayout from './documentation/PageLayout';
import Todo from './documentation/Todo';
import ListCharterContracts from './operations/charterContract/ListCharterContracts';
import { MaintainCharterContract } from './operations/charterContract/MaintainCharterContract';
import GenerateJobs from './operations/job/generateJobs/GenerateJobs';
import ListContractCharterBookings from './operations/sales/quote/Accounting/ListContractCharterBookings/ListContractCharterBookings';
import MaintainContractCharterBooking from './operations/sales/quote/Accounting/MaintainContractCharterBooking/MaintainContractCharterBooking';
import ListRosterGroups from './operations/urban/rosterGroups/listRosterGroups/ListRosterGroups';
import { MaintainRosterGroup } from './operations/urban/rosterGroups/MaintainRosterGroup';
import MaintainLabourPrices from './workshop/labourPrices/MaintainLabourPrices';
import { MaintainEquipmentType } from './people/staffMembers/MaintainEquipmentType/maintainEquipmentType';
import ListEquipmentTypes from './people/staffMembers/listEquipmentTypes/ListEquipmentTypes';
import MaintainMachineryScheduleTemplate from './workshop/machineryScheduleTemplate/MaintainMachineryScheduleTemplate';

import ListDocuments from './workshop/assets/documents/listDocuments/ListDocuments';
import MaintainDocument from './workshop/assets/documents/maintainDocuments/MaintainDocument';

interface IAuthedRoutesProps {
  uiRoutes: UiRoutes;
}

const AuthedRoutes: React.FC<IAuthedRoutesProps> = ({ uiRoutes }) => (
  <Shell>
    <ErrorBoundary>
      <Switch>
        <Route exact path={uiRoutes.home.pathPattern} component={Home} />
        <Route
          exact
          path={uiRoutes.operationsAuthorisedDevicesList.pathPattern}
          component={ListAuthorisedDevices}
        />
        <Route path="/workshop" render={() => <WorkshopRoutes uiRoutes={uiRoutes} />} />
        <Route path="/operations" render={() => <OperationsRoutes uiRoutes={uiRoutes} />} />
        <Route path="/sales" render={() => <SalesRoutes uiRoutes={uiRoutes} />} />
        <Route path="/people" render={() => <PeopleRoutes uiRoutes={uiRoutes} />} />
        <Route path="/compliance" render={() => <ComplianceRoutes uiRoutes={uiRoutes} />} />
        <Route path="/accounting" render={() => <AccountingRoutes uiRoutes={uiRoutes} />} />
        <Route path="/documentation" render={() => <DocumentationRoutes uiRoutes={uiRoutes} />} />
        <Redirect to={uiRoutes.home.pathPattern} />
      </Switch>
    </ErrorBoundary>
  </Shell>
);

const AnonRoutes: React.FC = () => <Route component={SignInFailed} />;

export interface IRoutesProps extends IAuthedRoutesProps {
  isAuthenticated: boolean | undefined;
}

const Routes: React.FC<IRoutesProps> = ({ isAuthenticated, uiRoutes }) => {
  switch (isAuthenticated) {
    case true:
      return <AuthedRoutes uiRoutes={uiRoutes} />;
    case false:
      return <AnonRoutes />;
    default:
      return <LoadingIndicator isLoading />;
  }
};

export default Routes;

function WorkshopRoutes({ uiRoutes }: IAuthedRoutesProps) {
  return (
    <Switch>
      <Route exact path={uiRoutes.workshop.pathPattern} component={Workshop} />
      <Route exact path={uiRoutes.workshopAssetsView.pathPattern} component={ListAsset} />
      <Route
        exact
        path={uiRoutes.workshopUnallocatedAssets.pathPattern}
        component={ListUnallocatedAsset}
      />
      <Route
        exact
        path={uiRoutes.workshopMaintainJobProgress.pathPattern}
        // @ts-ignore: Some issues with TS not quite matching 'match'
        render={p => <WorkshopMaintainJobProgress {...p} mode="update" />}
      />
      <Route exact path={uiRoutes.workshopTasksList.pathPattern} component={ListTasks} />
      <Route
        exact
        path={uiRoutes.workshopAssetServicesDueByDate.pathPattern}
        component={ServicesDuebyDate}
      />
      <Route
        exact
        path={uiRoutes.workshopAssetServicesDueByKms.pathPattern}
        component={ServicesDuebyKms}
      />
      <Route
        exact
        path={uiRoutes.workshopAssetsOdometerReadings.pathPattern}
        component={OdometerReadings}
      />

      <Route
        exact
        path={uiRoutes.workshopAssetsOdometerReadingsCreate.pathPattern}
        component={CreateOdometerReadings}
      />
      <Route exact path={uiRoutes.workshopJobsList.pathPattern} component={ListJobs} />
      <Route exact path={uiRoutes.workshopJobSchedule.pathPattern} component={JobSchedule} />
      <Route
        exact
        path={uiRoutes.workshopWeeklyJobSchedule.pathPattern}
        component={WeeklyJobSchedule}
      />
      <Route
        exact
        path={uiRoutes.workshopMachineryJobSchedule.pathPattern}
        component={MachineryJobSchedule}
      />
      <Route
        exact
        path={uiRoutes.workshopJobCreate.pathPattern}
        render={p => <MaintainJob {...p} mode="create" />}
      />
      <Route
        exact
        path={uiRoutes.workshopJobEdit.pathPattern}
        render={p => <MaintainJob {...p} mode="update" />}
      />

      <Route exact path={uiRoutes.workshopDefectsList.pathPattern} component={ListDefects} />
      <Route
        exact
        path={uiRoutes.workshopDefectCreate.pathPattern}
        render={p => <MaintainDefect {...p} mode="create" />}
      />
      <Route
        exact
        path={uiRoutes.workshopDefectEdit.pathPattern}
        render={p => <MaintainDefect {...p} mode="update" />}
      />

      <Route exact path={uiRoutes.workshopEngineList.pathPattern} component={ListEngines} />
      <Route
        exact
        path={uiRoutes.workshopEngineCreate.pathPattern}
        render={p => <MaintainEngine {...p} mode="create" />}
      />
      <Route
        exact
        path={uiRoutes.workshopEngineEdit.pathPattern}
        render={p => <MaintainEngine {...p} mode="update" />}
      />

      <Route exact path={uiRoutes.workshopDocumentsList.pathPattern} component={ListDocuments} />

      <Route
        exact
        path={uiRoutes.workshopDocumentCreate.pathPattern}
        render={p => <MaintainDocument route={p} mode={'create'} />}
      />

      <Route
        exact
        path={uiRoutes.workshopDocumentEdit.pathPattern}
        render={p => <MaintainDocument route={p} mode={'update'} />}
      />

      <Route
        exact
        path={uiRoutes.workshopAssetGroupCreate.pathPattern}
        render={p => <MaintainAssetGroup {...p} mode="create" />}
      />
      <Route
        exact
        path={uiRoutes.workshopAssetGroupEdit.pathPattern}
        render={p => <MaintainAssetGroup {...p} mode="update" />}
      />
      <Route
        exact
        path={uiRoutes.workshopAssetGroupsList.pathPattern}
        component={ListAssetGroups}
      />
      <Route exact path={uiRoutes.workshopChecklistsView.pathPattern} component={ListChecklists} />

      <Route
        exact
        path={uiRoutes.workshopPartsGroupsList.pathPattern}
        component={ListPartsGroups}
      />
      <Route
        exact
        path={uiRoutes.workshopPartsGroupCreate.pathPattern}
        render={p => <MaintainPartsGroup {...p} mode="create" />}
      />
      <Route
        exact
        path={uiRoutes.workshopPartsGroupEdit.pathPattern}
        render={p => <MaintainPartsGroup {...p} mode="update" />}
      />
      <Route
        exact
        path={uiRoutes.workshopInventoryStocktake.pathPattern}
        component={RecordStocktake}
      />
      <Route
        exact
        path={uiRoutes.workshopPartsTransactions.pathPattern}
        component={ListPartTransactions}
      />
      <Route
        exact
        path={uiRoutes.workshopChecklistCreate.pathPattern}
        render={p => <MaintainChecklist route={p} mode="create" />}
      />
      <Route
        exact
        path={uiRoutes.workshopChecklistEdit.pathPattern}
        render={p => <MaintainChecklist route={p} mode="update" />}
      />
      <Route
        exact
        path={uiRoutes.workshopJobTasksCreate.pathPattern}
        render={p => <MaintainTask {...p} mode="create" />}
      />
      <Route
        exact
        path={uiRoutes.workshopJobTasksMaintain.pathPattern}
        render={p => <MaintainTask {...p} mode="update" />}
      />
      <Route
        exact
        path={uiRoutes.workshopAssetComponentsRegister.pathPattern}
        render={p => <AssetComponentsRegister {...p} mode="update" />}
      />
      <Route
        exact
        path={uiRoutes.workshopAssetPartsRegister.pathPattern}
        render={p => <AssetPartsRegister {...p} mode="update" />}
      />
      <Route
        exact
        path={uiRoutes.workshopAssetCubicRegister.pathPattern}
        render={p => <AssetCubicRegister route={p} mode="update" />}
      />
      <Route
        exact
        path={uiRoutes.workshopAssetServiceSchedule.pathPattern}
        render={p => <AssetServiceSchedule {...p} mode="update" />}
      />
      <Route exact path={uiRoutes.workshopOwnerList.pathPattern} component={ListOwners} />
      <Route
        exact
        path={uiRoutes.workshopOwnerCreate.pathPattern}
        // @ts-ignore: Some issues with TS not quite matching 'match'
        render={p => <MaintainOwner {...p} mode="create" />}
      />
      <Route
        exact
        path={uiRoutes.workshopOwnerEdit.pathPattern}
        // @ts-ignore: Some issues with TS not quite matching 'match'
        render={p => <MaintainOwner {...p} mode="update" />}
      />
      <Route
        exact
        path={uiRoutes.workshopAssetCreate.pathPattern}
        render={p => <MaintainAsset route={p} mode="create" />}
      />
      <Route
        exact
        path={uiRoutes.workshopAssetEdit.pathPattern}
        render={p => <MaintainAsset route={p} mode="update" />}
      />
      <Route exact path={uiRoutes.workshopSupplierList.pathPattern} component={ListSuppliers} />
      <Route
        exact
        path={uiRoutes.workshopSupplierCreate.pathPattern}
        render={p => <MaintainSupplier {...p} mode="create" />}
      />
      <Route
        exact
        path={uiRoutes.workshopSupplierEdit.pathPattern}
        render={p => <MaintainSupplier {...p} mode="update" />}
      />
      <Route
        exact
        path={uiRoutes.workshopPurchaseOrdersList.pathPattern}
        component={ListPurchaseOrders}
      />
      <Route
        exact
        path={uiRoutes.workshopPurchaseOrderCreate.pathPattern}
        render={p => <MaintainPurchaseOrder {...p} mode="create" />}
      />
      <Route
        exact
        path={uiRoutes.workshopPurchaseOrderEdit.pathPattern}
        render={p => <MaintainPurchaseOrder {...p} mode="update" />}
      />
      <Route
        exact
        path={uiRoutes.workshopPurchaseOrderGoodsReceivedCreate.pathPattern}
        render={p => <MaintainGoodsReceived {...p} mode="create" />}
      />
      <Route
        exact
        path={uiRoutes.workshopPurchaseOrderGoodsReceivedEdit.pathPattern}
        render={p => <MaintainGoodsReceived {...p} mode="update" />}
      />
      <Route
        exact
        path={uiRoutes.workshopReturnedPartCreditCreate.pathPattern}
        render={p => <MaintainReturnedPartCredit {...p} mode="create" />}
      />
      <Route
        exact
        path={uiRoutes.workshopReturnedPartCreditEdit.pathPattern}
        render={p => <MaintainReturnedPartCredit {...p} mode="update" />}
      />
      <Route
        exact
        path={uiRoutes.workshopPurchaseOrderInvoicesEdit.pathPattern}
        render={p => <MaintainPurchaseOrderInvoices {...p} mode="update" />}
      />
      <Route
        exact
        path={uiRoutes.workshopPurchaseTransactionsList.pathPattern}
        render={p => <ListPurchaseTransactions {...p} />}
      />

      <Route
        exact
        path={uiRoutes.workshopPartCategoriesList.pathPattern}
        component={ListPartCategories}
      />
      <Route
        exact
        path={uiRoutes.workshopPartCategoryCreate.pathPattern}
        // @ts-ignore: Some issues with TS not quite matching 'match'
        render={p => <MaintainPartCategory {...p} mode="create" />}
      />
      <Route
        exact
        path={uiRoutes.workshopPartCategoryEdit.pathPattern}
        // @ts-ignore: Some issues with TS not quite matching 'match'
        render={p => <MaintainPartCategory {...p} mode="update" />}
      />
      <Route
        exact
        path={uiRoutes.workshopMaintainComponentTypes.pathPattern}
        render={p => <MaintainComponentTypes {...p} mode="update" />}
      />
      <Route
        exact
        path={uiRoutes.workshopTechSpecsEdit.pathPattern}
        render={p => <MaintainTechSpecs {...p} mode="update" />}
      />
      <Route
        exact
        path={uiRoutes.workshopTechSpecDropdownOptionsEdit.pathPattern}
        render={p => <MaintainTechSpecDropdownOptions {...p} mode="update" />}
      />

      <Route
        exact
        path={uiRoutes.workshopTaskSubcategoriesList.pathPattern}
        component={ListTaskSubcategories}
      />

      <Route
        exact
        path={uiRoutes.workshopTaskSubcategoryCreate.pathPattern}
        render={p => <MaintainTaskSubcategory route={p} mode="create" />}
      />
      <Route
        exact
        path={uiRoutes.workshopTaskSubcategoryEdit.pathPattern}
        render={p => <MaintainTaskSubcategory route={p} mode="update" />}
      />

      <Route
        exact
        path={uiRoutes.workshopPartCreate.pathPattern}
        render={p => <MaintainPart {...p} mode="create" />}
      />
      <Route
        exact
        path={uiRoutes.workshopPartEdit.pathPattern}
        render={p => <MaintainPart {...p} mode="update" />}
      />
      <Route exact path={uiRoutes.workshopPartsList.pathPattern} component={ListParts} />

      <Route
        exact
        path={uiRoutes.workshopShiftTemplateCreate.pathPattern}
        render={p => <MaintainShiftTemplate {...p} mode="create" />}
      />
      <Route
        exact
        path={uiRoutes.workshopShiftTemplateManage.pathPattern}
        render={p => <MaintainShiftTemplate {...p} mode="update" />}
      />
      <Route
        exact
        path={uiRoutes.workshopShiftTemplateList.pathPattern}
        component={ListShiftTemplates}
      />

      <Route
        exact
        path={uiRoutes.workshopRosterTemplateCreate.pathPattern}
        render={p => <MaintainRosterTemplate {...p} mode="create" />}
      />
      <Route
        exact
        path={uiRoutes.workshopRosterTemplateManage.pathPattern}
        render={p => <MaintainRosterTemplate {...p} mode="update" />}
      />
      <Route
        exact
        path={uiRoutes.workshopRosterTemplateList.pathPattern}
        component={ListRosterTemplates}
      />

      <Route
        exact
        path={uiRoutes.workshopShiftCreate.pathPattern}
        render={p => <MaintainWorkshopShift route={p} mode="create" />}
      />
      <Route
        exact
        path={uiRoutes.workshopShiftManage.pathPattern}
        render={p => <MaintainWorkshopShift route={p} mode="update" />}
      />
      <Route exact path={uiRoutes.workshopShiftList.pathPattern} component={ListWorkshopShifts} />

      <Route
        exact
        path={uiRoutes.workshopRosterCreate.pathPattern}
        render={() => <CreateWorkshopRoster />}
      />
      <Route
        exact
        path={uiRoutes.workshopRosterManage.pathPattern}
        render={p => <MaintainWorkshopRoster route={p} />}
      />
      <Route
        exact
        path={uiRoutes.workshopRosterViewChanges.pathPattern}
        component={WorkshopRosterChanges}
      />
      <Route
        exact
        path={uiRoutes.workshopDataFuelEntriesList.pathPattern}
        component={ListDataFuel}
      />
      <Route
        exact
        path={uiRoutes.workshopMaintainLabourPrices.pathPattern}
        component={MaintainLabourPrices}
      />

      <Route
        exact
        path={uiRoutes.workshopDataFuelUploadsList.pathPattern}
        component={ListDataFuelUploads}
      />

      <Route
        exact
        path={uiRoutes.workshopMachineryScheduleTemplate.pathPattern}
        render={p => <MaintainMachineryScheduleTemplate mode="update" />}
      />

      <Route
        exact
        path={uiRoutes.workshopMachineryScheduleTemplate.pathPattern}
        render={p => <MaintainMachineryScheduleTemplate mode="view" />}
      />
      <Route exact path={uiRoutes.workshopRosterList.pathPattern} component={ListWorkshopRosters} />

      <Redirect to={uiRoutes.home.pathPattern} />
    </Switch>
  );
}

function OperationsRoutes({ uiRoutes }: IAuthedRoutesProps) {
  return (
    <Switch>
      <Route exact path={uiRoutes.operations.pathPattern} component={Operations} />
      <Route exact path={uiRoutes.urbanShiftList.pathPattern} component={ListShift} />
      <Route
        exact
        path={uiRoutes.urbanShiftCreate.pathPattern}
        render={p => <MaintainShift route={p} mode="create" />}
      />
      <Route
        exact
        path={uiRoutes.urbanShiftManage.pathPattern}
        render={p => <MaintainShift route={p} mode="update" />}
      />

      <Route exact path={uiRoutes.urbanRosterList.pathPattern} component={ListRoster} />
      <Route
        exact
        path={uiRoutes.urbanRosterCreate.pathPattern}
        render={p => <MaintainRoster {...p} mode="create" />}
      />
      <Route
        exact
        path={uiRoutes.urbanRosterManage.pathPattern}
        render={p => <MaintainRoster {...p} mode="update" />}
      />

      <Route exact path={uiRoutes.urbanRosterGroupList.pathPattern} component={ListRosterGroups} />
      <Route
        exact
        path={uiRoutes.urbanRosterGroupCreate.pathPattern}
        render={p => <MaintainRosterGroup route={p} mode="create" />}
      />
      <Route
        exact
        path={uiRoutes.urbanRosterGroupManage.pathPattern}
        render={p => <MaintainRosterGroup route={p} mode="update" />}
      />

      <Route exact path={uiRoutes.operationsJobAllocations.pathPattern} component={Allocations} />
      <Route exact path={uiRoutes.operationsJobList.pathPattern} component={ListJob} />
      <Route
        exact
        path={uiRoutes.operationsJobForCharterList.pathPattern}
        component={ListJobForCharter}
      />
      <Route
        exact
        path={uiRoutes.operationsJobCreate.pathPattern}
        // @ts-ignore: Some issues with TS not quite matching 'match'
        render={p => <OperationsMaintainJob {...p} mode="create" />}
      />

      <Route
        exact
        path={uiRoutes.operationsJobUpdate.pathPattern}
        // @ts-ignore: Some issues with TS not quite matching 'match'
        render={p => <OperationsMaintainJob {...p} mode="update" />}
      />

      <Route
        exact
        path={uiRoutes.operationsJobProgressUpdate.pathPattern}
        // @ts-ignore: Some issues with TS not quite matching 'match'
        render={p => <OperationsMaintainJobProgress {...p} mode="update" />}
      />

      <Route
        exact
        path={uiRoutes.operationsRailTemplateList.pathPattern}
        component={ListRailTemplate}
      />

      <Route
        exact
        path={uiRoutes.operationsRailTemplateCreate.pathPattern}
        render={p => <MaintainRailTemplate route={p} mode="create" />}
      />
      <Route
        exact
        path={uiRoutes.operationsRailTemplateManage.pathPattern}
        render={p => <MaintainRailTemplate route={p} mode="update" />}
      />

      <Route
        exact
        path={uiRoutes.operationsRailBookingList.pathPattern}
        component={ListRailBookings}
      />

      <Route
        exact
        path={uiRoutes.operationsRailBookingCreate.pathPattern}
        component={CreateRailBooking}
      />

      <Route
        exact
        path={uiRoutes.operationsRailBookingManage.pathPattern}
        // @ts-ignore: Some issues with TS not quite matching 'match'
        render={p => <MaintainRailBooking {...p} />}
      />

      <Route
        exact
        path={uiRoutes.operationsTasksForDay.pathPattern}
        render={p => <MaintainTasksForDay {...p} />}
      />

      <Route
        exact
        path={uiRoutes.operationsBookingForOpsManage.pathPattern}
        render={p => <MaintainBookingForOps route={p} />}
      />

      <Route exact path={uiRoutes.operationsHolidayList.pathPattern} component={ListHoliday} />
      <Route
        exact
        path={uiRoutes.operationsHolidayCreate.pathPattern}
        render={p => <MaintainHoliday route={p} mode="create" />}
      />
      <Route
        exact
        path={uiRoutes.operationsHolidayManage.pathPattern}
        render={p => <MaintainHoliday route={p} mode="update" />}
      />

      <Route
        exact
        path={uiRoutes.operationsSubcontractorList.pathPattern}
        component={ListSubcontractor}
      />
      <Route
        exact
        path={uiRoutes.operationsSubcontractorCreate.pathPattern}
        render={p => <MaintainSubcontractor {...p} mode="create" />}
      />
      <Route
        exact
        path={uiRoutes.operationsSubcontractorUpdate.pathPattern}
        render={p => <MaintainSubcontractor {...p} mode="update" />}
      />
      <Route
        exact
        path={uiRoutes.operationsDefectCreate.pathPattern}
        render={p => <MaintainDefect {...p} mode="create" />}
      />
      <Route
        exact
        path={uiRoutes.assetsWithUnknownKms.pathPattern}
        render={p => <ListAssetsWithUnknownKms {...p} />}
      />
      <Route
        exact
        path={uiRoutes.operationsJobProgressWarningsList.pathPattern}
        component={ListJobProgressWarnings}
      />

      <Route
        exact
        path={uiRoutes.operationsMaintainOperationsDiaryEntrySubjects.pathPattern}
        render={p => <MaintainSubjects {...p} mode="update" />}
      />
      <Route
        exact
        path={uiRoutes.operationsOperationsDiaryEntryCreate.pathPattern}
        render={p => <MaintainOperationsDiaryEntry {...p} mode="create" />}
      />
      <Route
        exact
        path={uiRoutes.operationsOperationsDiaryEntryUpdate.pathPattern}
        render={p => <MaintainOperationsDiaryEntry {...p} mode="update" />}
      />
      <Route
        exact
        path={uiRoutes.operationsOperationsDiaryEntryList.pathPattern}
        component={ListOperationsDiary}
      />
      <Route
        exact
        path={uiRoutes.operationsJobListGenerateJobsOptions.pathPattern}
        component={ListGenerateJobsOperations}
      />
      <Route
        exact
        path={uiRoutes.operationsJobCreateGenerateJobsOption.pathPattern}
        render={p => <GenerateJobs route={p} mode="create" />}
      />
      <Route
        exact
        path={uiRoutes.operationsJobGenerateJobsDetails.pathPattern}
        render={p => <GenerateJobs route={p} mode="update" />}
      />
      <Route
        exact
        path={uiRoutes.operationsListAssetOdometerReadings.pathPattern}
        component={ListAssetOdometerReadingsContainer}
      />

      <Route
        exact
        path={uiRoutes.operationsProcessDriverAuthorization.pathPattern}
        component={ListProcessDriverAuthorization}
      />

      <Route
        exact
        path={uiRoutes.operationsProcessResultsFile.pathPattern}
        component={ProcessResultsFile}
      />

      <Route
        exact
        path={uiRoutes.operationsCharterContractsList.pathPattern}
        component={ListCharterContracts}
      />

      <Route
        exact
        path={uiRoutes.operationsCharterContractCreate.pathPattern}
        render={p => <MaintainCharterContract route={p} mode="create" />}
      />
      <Route
        exact
        path={uiRoutes.operationsCharterContractManage.pathPattern}
        render={p => <MaintainCharterContract route={p} mode="update" />}
      />

      <Redirect to={uiRoutes.home.pathPattern} />
    </Switch>
  );
}

function ComplianceRoutes({ uiRoutes }: IAuthedRoutesProps) {
  return (
    <Switch>
      <Route exact path={uiRoutes.compliance.pathPattern} component={Compliance} />

      <Route
        exact
        path={uiRoutes.complianceCodeOfConductList.pathPattern}
        component={ListCodeOfConduct}
      />

      <Route
        exact
        path={uiRoutes.complianceCustomerComplaintList.pathPattern}
        component={ListCustomerComplaint}
      />

      <Route
        exact
        path={uiRoutes.complianceLostPropertyList.pathPattern}
        component={ListLostProperty}
      />

      <Route
        exact
        path={uiRoutes.complianceCctvRequestList.pathPattern}
        component={ListCctvRequest}
      />

      <Route
        exact
        path={uiRoutes.complianceLostPropertyCreate.pathPattern}
        render={p => <MaintainLostProperty {...p} mode="create" />}
      />

      <Route
        exact
        path={uiRoutes.complianceLostPropertyUpdate.pathPattern}
        render={p => <MaintainLostProperty {...p} mode="update" />}
      />

      <Route
        exact
        path={uiRoutes.complianceCustomerComplaintCreate.pathPattern}
        render={p => <MaintainCustomerComplaint {...p} mode="create" />}
      />

      <Route
        exact
        path={uiRoutes.complianceCustomerComplaintUpdate.pathPattern}
        render={p => <MaintainCustomerComplaint {...p} mode="update" />}
      />

      <Route
        exact
        path={uiRoutes.complianceCctvRequestCreate.pathPattern}
        render={p => <MaintainCctvRequestContainer {...p} mode="create" />}
      />

      <Route
        exact
        path={uiRoutes.complianceCctvRequestUpdate.pathPattern}
        render={p => <MaintainCctvRequestContainer {...p} mode="update" />}
      />

      <Route
        exact
        path={uiRoutes.complianceCodeOfConductCreate.pathPattern}
        render={p => <MaintainCodeOfConduct {...p} mode="create" />}
      />

      <Route
        exact
        path={uiRoutes.complianceCodeOfConductUpdate.pathPattern}
        render={p => <MaintainCodeOfConduct {...p} mode="update" />}
      />

      <Route
        exact
        path={uiRoutes.complianceFatigueDetails.pathPattern}
        render={p => <FatigueDetails {...p} />}
      />

      <Route
        exact
        path={uiRoutes.complianceReportableEventCreate.pathPattern}
        render={p => <MaintainReportableEvent {...p} mode="create" />}
      />
      <Route
        exact
        path={uiRoutes.complianceReportableEventManage.pathPattern}
        render={p => <MaintainReportableEvent {...p} mode="update" />}
      />
      <Route
        exact
        path={uiRoutes.complianceReportableEventList.pathPattern}
        component={ListReportableEvents}
      />
      <Route
        exact
        path={uiRoutes.complianceFatigueBreachEventList.pathPattern}
        render={p => <ListFatigueBreachEvents {...p} />}
      />
      <Route
        exact
        path={uiRoutes.complianceFatigueBreachEventManage.pathPattern}
        render={p => <MaintainFatigueBreachEvent {...p} mode="update" />}
      />

      <Redirect to={uiRoutes.home.pathPattern} />
    </Switch>
  );
}

function AccountingRoutes({ uiRoutes }: IAuthedRoutesProps) {
  return (
    <Switch>
      <Route exact path={uiRoutes.accounting.pathPattern} component={Accounting} />
      <Route
        exact
        path={uiRoutes.accountingRailBookings.pathPattern}
        component={RailBookingsForAccounting}
      />
      <Route
        exact
        path={uiRoutes.accountingBookings.pathPattern}
        component={BookingsForAccounting}
      />
      <Route
        exact
        path={uiRoutes.accountingContractCharterBookings.pathPattern}
        render={p => <ListContractCharterBookings {...queryString.parse(p.location.search)} />}
      />
      <Route
        exact
        path={uiRoutes.accountingCreateContractCharterBookings.pathPattern}
        render={p => <MaintainContractCharterBooking mode="create" route={p} />}
      />

      <Route
        exact
        path={uiRoutes.accountingUpdateContractCharterBookings.pathPattern}
        render={p => <MaintainContractCharterBooking mode="update" route={p} />}
      />
      <Route exact path={uiRoutes.reporting.pathPattern} component={Reporting} />
    </Switch>
  );
}

function DocumentationRoutes({ uiRoutes }: IAuthedRoutesProps) {
  return (
    <Switch>
      <Route exact path={uiRoutes.documentationPageLayout.pathPattern} component={PageLayout} />
      <Route exact path={uiRoutes.documentationListPage.pathPattern} component={Todo} />
      <Route exact path={uiRoutes.documentationCrudPage.pathPattern} component={Todo} />
      <Route exact path={uiRoutes.documentationForms.pathPattern} component={Todo} />
      <Route exact path={uiRoutes.documentationActions.pathPattern} component={Todo} />
      <Route exact path={uiRoutes.documentationModals.pathPattern} component={Todo} />
    </Switch>
  );
}

function SalesRoutes({ uiRoutes }: IAuthedRoutesProps) {
  return (
    <Switch>
      <Route exact path={uiRoutes.sales.pathPattern} component={Sales} />
      <Route exact path={uiRoutes.peopleStaffMemberList.pathPattern} component={ListStaffMembers} />
      <Route exact path={uiRoutes.salesQuoteList.pathPattern} component={ListQuotes} />
      <Route exact path={uiRoutes.salesCustomerList.pathPattern} component={ListCustomer} />
      <Route
        exact
        path={uiRoutes.salesCustomerCreate.pathPattern}
        render={p => <MaintainCustomer route={p} mode="create" />}
      />
      <Route
        exact
        path={uiRoutes.salesCustomerEdit.pathPattern}
        render={p => <MaintainCustomer route={p} mode="update" />}
      />
      <Route
        exact
        path={uiRoutes.salesQuoteCreate.pathPattern}
        render={p => <MaintainQuote route={p} mode="create" />}
      />
      <Route
        exact
        path={uiRoutes.salesQuoteUpdate.pathPattern}
        // @ts-ignore: Some issues with TS not quite matching 'match'
        render={p => <MaintainQuote route={p} mode="update" />}
      />
      <Route
        exact
        path={uiRoutes.salesBookingUpdate.pathPattern}
        // @ts-ignore: Some issues with TS not quite matching 'match'
        render={p => <MaintainQuote route={p} mode="update" />}
      />

      <Route
        exact
        path={uiRoutes.salesBoardingPointList.pathPattern}
        component={ListBoardingPoint}
      />
      <Route
        exact
        path={uiRoutes.salesBoardingPointCreate.pathPattern}
        render={p => <MaintainBoardingPoint {...p} mode="create" />}
      />
      <Route
        exact
        path={uiRoutes.salesBoardingPointManage.pathPattern}
        render={p => <MaintainBoardingPoint {...p} mode="update" />}
      />
      <Route exact path={uiRoutes.salesExtraTypeList.pathPattern} component={ListExtraType} />
      <Route
        exact
        path={uiRoutes.salesExtraTypeCreate.pathPattern}
        render={p => <MaintainExtraType route={p} mode="create" />}
      />
      <Route
        exact
        path={uiRoutes.salesExtraTypeUpdate.pathPattern}
        render={p => <MaintainExtraType route={p} mode="update" />}
      />
      <Route
        exact
        path={uiRoutes.salesHourlyRateManage.pathPattern}
        component={MaintainHourlyRates}
      />
      <Route exact path={uiRoutes.salesVehicleTypeList.pathPattern} component={ListVehicleType} />
      <Route
        exact
        path={uiRoutes.salesVehicleTypeCreate.pathPattern}
        render={p => <MaintainVehicleType {...p} mode="create" />}
      />
      <Route
        exact
        path={uiRoutes.salesVehicleTypeManage.pathPattern}
        render={p => <MaintainVehicleType {...p} mode="update" />}
      />
      <Redirect to={uiRoutes.home.pathPattern} />
    </Switch>
  );
}

function PeopleRoutes({ uiRoutes }: IAuthedRoutesProps) {
  return (
    <Switch>
      <Route exact path={uiRoutes.people.pathPattern} component={People} />
      <Route exact path={uiRoutes.peopleStaffMemberList.pathPattern} component={ListStaffMembers} />
      <Route
        exact
        path={uiRoutes.peopleStaffMemberCreate.pathPattern}
        render={p => <MaintainStaffMember route={p} mode="create" />}
      />
      <Route
        exact
        path={uiRoutes.peopleStaffMemberManage.pathPattern}
        render={p => <MaintainStaffMember route={p} mode="update" />}
      />
      <Route exact path={uiRoutes.peopleNoteList.pathPattern} component={ListNotes} />
      <Route
        exact
        path={uiRoutes.peopleNoteCreate.pathPattern}
        render={p => <MaintainNote {...p} mode="create" />}
      />
      <Route
        exact
        path={uiRoutes.peopleNoteManage.pathPattern}
        render={p => <MaintainNote {...p} mode="update" />}
      />

      <Route exact path={uiRoutes.peopleRoleList.pathPattern} component={ListRoles} />
      <Route
        exact
        path={uiRoutes.peopleRoleCreate.pathPattern}
        render={p => <MaintainRole {...p} mode="create" />}
      />
      <Route
        exact
        path={uiRoutes.peopleRoleManage.pathPattern}
        render={p => <MaintainRole {...p} mode="update" />}
      />

      <Route
        exact
        path={uiRoutes.peopleTimesheetAllowanceList.pathPattern}
        component={ListTimesheetAllowances}
      />
      <Route
        exact
        path={uiRoutes.peopleTimesheetAllowanceCreate.pathPattern}
        render={p => <MaintainTimesheetAllowance {...p} mode="create" />}
      />
      <Route
        exact
        path={uiRoutes.peopleTimesheetAllowanceManage.pathPattern}
        render={p => <MaintainTimesheetAllowance {...p} mode="update" />}
      />

      <Route
        exact
        path={uiRoutes.peopleExternalIdentityList.pathPattern}
        component={ListExternalIdentities}
      />

      <Route
        exact
        path={uiRoutes.peopleEquipmentTypesList.pathPattern}
        component={ListEquipmentTypes}
      />
      <Route
        exact
        path={uiRoutes.peopleEquipmentTypeCreate.pathPattern}
        // @ts-ignore: Some issues with TS not quite matching 'match'
        render={p => <MaintainEquipmentType {...p} mode="create" />}
      />
      <Route
        exact
        path={uiRoutes.peopleEquipmentTypeEdit.pathPattern}
        // @ts-ignore: Some issues with TS not quite matching 'match'
        render={p => <MaintainEquipmentType {...p} mode="update" />}
      />

      {/* Do not consolidate these three Routes as it breaks the Leave and Timesheets Routes */}
      {RENDER_SMS_NAV_ELEMENTS && (
        <Route exact path={uiRoutes.peopleSmsList.pathPattern} component={ListSms} />
      )}

      {RENDER_SMS_NAV_ELEMENTS && (
        <Route
          exact
          path={uiRoutes.peopleSmsCreate.pathPattern}
          render={p => <MaintainSms {...p} mode="create" />}
        />
      )}

      {RENDER_SMS_NAV_ELEMENTS && (
        <Route
          exact
          path={uiRoutes.peopleSmsManage.pathPattern}
          render={p => <MaintainSms {...p} mode="update" />}
        />
      )}

      <Route
        exact
        path={uiRoutes.peopleSkillSpecsEdit.pathPattern}
        render={p => <MaintainSkillSpecs {...p} mode="update" />}
      />

      <Route exact path={uiRoutes.peopleLeaveCalendar.pathPattern} component={LeaveCalendar} />
      <Route exact path={uiRoutes.peopleLeaveList.pathPattern} component={ListLeave} />
      <Route
        exact
        path={uiRoutes.peopleLeaveCreate.pathPattern}
        render={p => <MaintainLeave route={p} mode="create" />}
      />
      <Route
        exact
        path={uiRoutes.peopleLeaveManage.pathPattern}
        render={p => <MaintainLeave route={p} mode="update" />}
      />
      <Route exact path={uiRoutes.peopleTimesheetList.pathPattern} component={ListTimesheet} />
      <Route
        exact
        path={uiRoutes.peopleTimesheetCreate.pathPattern}
        render={p => <MaintainTimesheet route={p} mode="create" />}
      />
      <Route
        exact
        path={uiRoutes.peopleTimesheetManage.pathPattern}
        render={p => <MaintainTimesheet route={p} mode="update" />}
      />

      <Redirect to={uiRoutes.home.pathPattern} />
    </Switch>
  );
}
