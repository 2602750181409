import { ActionType, IActionLinkDef } from 'src/views/definitionBuilders/types';
import {
  ClipboardIcon,
  WrenchIcon,
  ObjectGroupIcon,
  ListIcon,
  FlagCheckered,
  Tasks,
  UserIcon,
  PlusIcon,
  FileIcon,
  CogIcon,
  PlaneIcon,
  PurchaseIcon,
  ClockIcon,
  BusIcon,
  TrainIcon,
  BookIcon,
  VideoIcon,
  HandHoldingDollarIcon,
  UmbrellaBeach,
  SmsIcon,
  CalendarPlusIcon,
  HistoryIcon,
  CalendarIcon,
  WarnIcon,
  ToolsIcon,
  PeopleGroupIcon,
  DeviceIcon,
  CalculatorIcon,
  ShirtIcon,
  FilePdfIcon,
} from 'src/images/icons';
import { ITaskCard } from './TaskCard';
import {
  LostPropertyItemStatus,
  JobTaskStatus,
  DataFuelStatus,
  TransactionType,
} from 'src/api/enums';
import { DateTime } from 'luxon';

export function toActionLinkDef(t: ITaskCard) {
  return {
    actionType: ActionType.actionLink as ActionType.actionLink,
    label: t.name,
    to: t.link,
    icon: t.icon,
  } as IActionLinkDef;
}

const raiseDefect: ITaskCard = {
  name: 'Raise a Defect',
  link: '/workshop/defects/create',
  icon: <ClipboardIcon />,
};

const createAsset: ITaskCard = {
  name: 'Create an Asset',
  link: '/workshop/assets/create',
  icon: <PlusIcon />,
};

const partsGroup: ITaskCard = {
  name: 'Parts Groups',
  link: '/workshop/parts-groups',
  icon: <ObjectGroupIcon />,
};

const createPartsGroup: ITaskCard = {
  name: 'Create a Parts Group',
  link: '/workshop/parts-groups/create',
  icon: <ObjectGroupIcon />,
};

const taskSubcategory: ITaskCard = {
  name: 'Task Subcategories',
  link: '/workshop/task-subcategories',
  icon: <ObjectGroupIcon />,
};

const createTaskSubcategory: ITaskCard = {
  name: 'Create a Task Subcategory',
  link: '/workshop/task-subcategories/create',
  icon: <ObjectGroupIcon />,
};

const engines: ITaskCard = {
  name: 'Engines',
  link: '/workshop/engines',
  icon: <WrenchIcon />,
};

const documents: ITaskCard = {
  name: 'Documents',
  link: '/workshop/documents?defaultFilter=true',
  icon: <FilePdfIcon />,
};

const createDocument: ITaskCard = {
  name: 'Create Document',
  link: '/workshop/documents/create',
  icon: <PlusIcon />,
};

const owners: ITaskCard = {
  name: 'Owners',
  link: '/workshop/owners?defaultFilter=true',
  icon: <UserIcon />,
};

const assetGroups: ITaskCard = {
  name: 'Asset Groups',
  link: '/workshop/asset-groups',
  icon: <ObjectGroupIcon />,
};

const createAdhocTask: ITaskCard = {
  name: 'Create an Adhoc Task',
  link: '/workshop/tasks/create',
  icon: <ClipboardIcon />,
};

const lowFloorFutureTasks: ITaskCard = {
  name: 'Low Floor Future Tasks',
  link: `/workshop/tasks?isLowFloor=true&statuses=${JobTaskStatus.Future}`,
  icon: <Tasks />,
};

const createJob: ITaskCard = {
  name: 'Create a Job',
  link: '/workshop/jobs/create',
  icon: <ClipboardIcon />,
};

const checklists: ITaskCard = {
  name: 'Checklists',
  link: '/workshop/checklists',
  icon: <ListIcon />,
};

const createChecklist: ITaskCard = {
  name: 'Create a Checklist',
  link: '/workshop/checklists/create',
  icon: <ClipboardIcon />,
};

const createEngine: ITaskCard = {
  name: 'Create an Engine',
  link: '/workshop/engines/create',
  icon: <PlusIcon />,
};

const createAssetGroup: ITaskCard = {
  name: 'Create an Asset Group',
  link: '/workshop/asset-groups/create',
  icon: <ObjectGroupIcon />,
};

const odometerReadings: ITaskCard = {
  name: 'Daily Odometer Readings',
  link: '/workshop/assets/odometer-readings?defaultFilter=true',
  icon: <FlagCheckered />,
};

const unallocatedAssets: ITaskCard = {
  name: 'Unallocated Assets By Day',
  link: '/workshop/assets/unallocated?defaultFilter=true',
  icon: <BusIcon />,
};

const createOdometerReadings: ITaskCard = {
  name: 'Create Odometer Readings',
  link: '/workshop/assets/odometer-readings/create',
  icon: <FlagCheckered />,
};

const labourPrices: ITaskCard = {
  name: 'Labour Prices',
  link: '/workshop/labour-prices',
  icon: <CalculatorIcon />,
};

const jobTask = (jobTaskId: string | undefined): ITaskCard => {
  return {
    name: 'Edit Task',
    link: `/workshop/tasks/${jobTaskId}`,
    icon: <Tasks />,
  };
};

const jobHistory = (assetId: string): ITaskCard => {
  return {
    name: 'Job History',
    link: `/workshop/asset/${assetId}`,
    icon: <Tasks />,
  };
};

const assetComponentsRegister = (assetId: string): ITaskCard => {
  return {
    name: 'Components Register',
    link: `/workshop/assets/${assetId}/components`,
    icon: <ObjectGroupIcon />,
  };
};

const assetPartsRegister = (assetId: string): ITaskCard => {
  return {
    name: 'Parts Register',
    link: `/workshop/assets/${assetId}/parts`,
    icon: <ObjectGroupIcon />,
  };
};

const assetCubicRegister = (assetId: string): ITaskCard => {
  return {
    name: 'Cubic Register',
    link: `/workshop/assets/${assetId}/cubic`,
    icon: <ObjectGroupIcon />,
  };
};
const assetUsedParts = (assetId: string, startDate: DateTime): ITaskCard => {
  return {
    name: 'Used Parts',
    link: `/workshop/parts/transactions?assets=${assetId}&dateFrom=${startDate.toISODate()}&transactionTypes=${
      TransactionType.Used
    }`,
    icon: <ObjectGroupIcon />,
  };
};

const serviceSchedule = (assetId: string): ITaskCard => {
  return {
    name: 'Service Schedule',
    link: `/workshop/assets/${assetId}/service-schedule`,
    icon: <WrenchIcon />,
  };
};

const servicesDueByDate: ITaskCard = {
  name: 'Services Due By Date',
  link: '/workshop/assets/services-due-by-date',
  icon: <WrenchIcon />,
};

const servicesDueBykms: ITaskCard = {
  name: 'Services Due By Kms',
  link: '/workshop/assets/services-due-by-Kms',
  icon: <WrenchIcon />,
};

const createCustomer: ITaskCard = {
  name: 'Create a Customer',
  link: '/sales/customers/create',
  icon: <UserIcon />,
};

const createSubcontractor: ITaskCard = {
  name: 'Create Subcontractor',
  link: '/operations/subcontractors/create',
  icon: <BusIcon />,
};

const listSubcontractor: ITaskCard = {
  name: 'Subcontractors',
  link: '/operations/subcontractors',
  icon: <BusIcon />,
};

const createOperationsDiaryEntry: ITaskCard = {
  name: 'Create Daily Event Log Entry',
  link: '/operations/daily-event-log/create',
  icon: <CalendarIcon />,
};

const maintainOperationsDiaryEntrySubjects: ITaskCard = {
  name: 'Daily Event Log Subjects',
  link: '/operations/daily-event-log-subjects',
  icon: <CalendarIcon />,
};

const overwriteTabletOdometerReadings: ITaskCard = {
  name: 'Overwrite Tablet Odometer Readings',
  link: `/operations/asset/odometer-readings`,
  icon: <BusIcon />,
};

const listRosterGroups: ITaskCard = {
  name: 'Roster Groups',
  link: `/operations/roster-groups`,
  icon: <ClipboardIcon />,
};

const listProcessDriverAuthorization: ITaskCard = {
  name: 'Process DA Results',
  link: '/operations/process-driver-authorization',
  icon: <UserIcon />,
};

const listAuthorisedDevices: ITaskCard = {
  name: 'Authorised Devices',
  link: '/operations/authorised-devices',
  icon: <DeviceIcon />,
};

const processResultsFile: ITaskCard = {
  name: 'Process Results File',
  link: '/operations/process-driver-authorization/create',
  icon: <FileIcon />,
};

const createRosterGroup: ITaskCard = {
  name: 'Create Roster Group',
  link: '/operations/roster-groups/create',
  icon: <ObjectGroupIcon />,
};

const createCharterContract: ITaskCard = {
  name: 'Create a Charter Contract',
  link: '/operations/charter-contracts/create',
  icon: <ObjectGroupIcon />,
};

const listCharterContracts: ITaskCard = {
  name: 'Charter Contracts',
  link: `/operations/charter-contracts`,
  icon: <FileIcon />,
};

const createQuote: ITaskCard = {
  name: 'Create a Quote',
  link: '/sales/quotes/create',
  icon: <FileIcon />,
};

const createSupplier: ITaskCard = {
  name: 'Create a Supplier',
  link: '/workshop/suppliers/create',
  icon: <UserIcon />,
};

const createPurchaseOrder: ITaskCard = {
  name: 'Create a Purchase Order',
  link: '/workshop/purchase-orders/create',
  icon: <PurchaseIcon />,
};

const createOwner: ITaskCard = {
  name: 'Create an Owner',
  link: '/workshop/owners/create',
  icon: <UserIcon />,
};

const componentTypes: ITaskCard = {
  name: 'Component Types',
  link: '/workshop/component-types',
  icon: <ObjectGroupIcon />,
};

const techSpecs: ITaskCard = {
  name: 'Tech Specs',
  link: '/workshop/tech-specs',
  icon: <ObjectGroupIcon />,
};

const createTechSpecs: ITaskCard = {
  name: 'Create a Tech Spec',
  link: '/workshop/tech-specs/create',
  icon: <ObjectGroupIcon />,
};

const partCategory: ITaskCard = {
  name: 'Part Categories',
  link: '/workshop/part-categories?defaultFilter=true',
  icon: <ObjectGroupIcon />,
};

const createPartCategory: ITaskCard = {
  name: 'Create a Part Category',
  link: '/workshop/part-categories/create',
  icon: <ObjectGroupIcon />,
};

const createPart: ITaskCard = {
  name: 'Create a Part',
  link: '/workshop/parts/create',
  icon: <CogIcon />,
};

const machineryTemplate: ITaskCard = {
  name: 'Machinery Template',
  link: '/workshop/machinery-schedule-template',
  icon: <CalendarIcon />,
};

const createTask: ITaskCard = {
  name: 'Create Adoc Task',
  link: '/workshop/tasks/create',
  icon: <ClipboardIcon />,
};

const recordStocktake = (depotId: number): ITaskCard => ({
  name: 'Conduct a Stocktake',
  link: `/workshop/parts/stocktake?depotId=${depotId}`,
  icon: <ClipboardIcon />,
});

const partsTransactions = (
  startDate: DateTime,
  endDate: DateTime,
  depotId: number | undefined
): ITaskCard => ({
  name: 'Part Transactions',
  link: `/workshop/parts/transactions?dateFrom=${startDate.toISODate()}&dateTo=${endDate.toISODate()}${
    depotId ? '&depotIds=' + depotId : ''
  }`,
  icon: <ListIcon />,
});

const partsUsedTransactions = (
  startDate: DateTime,
  endDate: DateTime,
  depotId: number | undefined
): ITaskCard => ({
  name: 'Parts Used Listing',
  link: `/workshop/parts/transactions?dateFrom=${startDate.toISODate()}&dateTo=${endDate.toISODate()}&transactionTypes=${
    TransactionType.Used
  }${depotId ? '&depotIds=' + depotId : ''}`,
  icon: <ListIcon />,
});

const tpsUsedReport = (startDate: DateTime, endDate: DateTime): ITaskCard => ({
  name: 'TP Providers Used Listing',
  link: `/workshop/third-party-providers/tps-used?dateFrom=${startDate.toISODate()}&dateTo=${endDate.toISODate()}`,
  icon: <ListIcon />,
});

const dataFuel: ITaskCard = {
  name: 'Process Fuel Report',
  link: `/workshop/assets/datafuel/entries?statusIds=${DataFuelStatus.Failed}`,
  icon: <ToolsIcon />,
};

const shiftTemplates: ITaskCard = {
  name: 'Shift Templates',
  link: '/workshop/shift-templates',
  icon: <CalendarPlusIcon />,
};

const createShiftTemplate: ITaskCard = {
  name: 'Create Shift Template',
  link: '/workshop/shift-templates/create',
  icon: <CalendarPlusIcon />,
};

const rosterTemplates: ITaskCard = {
  name: 'Roster Templates',
  link: '/workshop/roster-templates',
  icon: <CalendarPlusIcon />,
};

const createRosterTemplate: ITaskCard = {
  name: 'Create Roster Template',
  link: '/workshop/roster-templates/create',
  icon: <CalendarPlusIcon />,
};

const createWorkshopRoster: ITaskCard = {
  name: 'Create Roster',
  link: '/workshop/rosters/create',
  icon: <CalendarPlusIcon />,
};

const createWorkshopShift: ITaskCard = {
  name: 'Create Shift',
  link: '/workshop/shifts/create',
  icon: <CalendarPlusIcon />,
};

const viewWorkshopRosterChanges = (rosterId: string): ITaskCard => {
  return {
    name: 'View Roster Changes',
    link: `/workshop/rosters/${rosterId}/changes`,
    icon: <HistoryIcon />,
  };
};

const listHolidays: ITaskCard = {
  name: 'Holidays',
  link: '/operations/holidays?defaultFilter=true',
  icon: <PlaneIcon />,
};

const createHoliday: ITaskCard = {
  name: 'Create Holiday',
  link: '/operations/holidays/create',
  icon: <PlaneIcon />,
};

const listVehicleTypes: ITaskCard = {
  name: 'Vehicle Types',
  link: '/sales/vehicle-types',
  icon: <BusIcon />,
};

const createVehicleType: ITaskCard = {
  name: 'Create Vehicle Type',
  link: '/sales/vehicle-types/create',
  icon: <BusIcon />,
};

const createBoardingPoint: ITaskCard = {
  name: 'Create Boarding Point',
  link: '/sales/boarding-points/create',
  icon: <PlaneIcon />,
};

const listExtraTypes: ITaskCard = {
  name: 'Extras',
  link: '/sales/extra-types?defaultFilter=true',
  icon: <PlusIcon />,
};

const createExtraType: ITaskCard = {
  name: 'Create Extras',
  link: '/sales/extra-types/create',
  icon: <PlusIcon />,
};

const createShift: ITaskCard = {
  name: 'Create Shift',
  link: '/operations/shifts/create',
  icon: <PlusIcon />,
};

const createRoster: ITaskCard = {
  name: 'Create Roster',
  link: '/operations/rosters/create',
  icon: <ClockIcon />,
};

const listGenerateJobsOperations: ITaskCard = {
  name: 'Generate Jobs',
  link: '/operations/generate-jobs-operations',
  icon: <BusIcon />,
};

const createGenerateJobsOperation: ITaskCard = {
  name: 'Generate New Jobs',
  link: '/operations/generate-jobs-operations/create',
  icon: <BusIcon />,
};

const createOperationsJob: ITaskCard = {
  name: 'Create Adhoc Job',
  link: '/operations/jobs/create',
  icon: <BusIcon />,
};

const createStaffMember: ITaskCard = {
  name: 'Create Staff Member',
  link: '/people/staff-members/create',
  icon: <UserIcon />,
};

const createDriverUser: ITaskCard = {
  name: 'Create Driver User',
  link: '/people/driver-app/create',
  icon: <UserIcon />,
};

const listExternalIdentities: ITaskCard = {
  name: 'External Identities',
  link: '/people/external-identities',
  icon: <UserIcon />,
};

const skillSpecs: ITaskCard = {
  name: 'Skill Specs',
  link: '/people/skill-specs',
  icon: <ObjectGroupIcon />,
};

const listEquipmentTypes: ITaskCard = {
  name: 'Equipment Types',
  link: `/people/equipment-types`,
  icon: <ShirtIcon />,
};

const createEquipmentType: ITaskCard = {
  name: 'Create an Equipment Type',
  link: '/people/equipment-types/create',
  icon: <ShirtIcon />,
};

const createNote: ITaskCard = {
  name: 'Create Note',
  link: '/people/notes/create',
  icon: <FileIcon />,
};

const createSms: ITaskCard = {
  name: 'Create SMS',
  link: '/people/sms/create',
  icon: <SmsIcon />,
};

const listRoles: ITaskCard = {
  name: 'Roles',
  link: '/people/roles',
  icon: <PeopleGroupIcon />,
};

const createRole: ITaskCard = {
  name: 'Create Role',
  link: '/people/roles/create',
  icon: <PeopleGroupIcon />,
};

const listTimesheetAllowances: ITaskCard = {
  name: 'Timesheet Allowances',
  link: '/people/timesheet-allowances?defaultFilter=true',
  icon: <HandHoldingDollarIcon />,
};

const createTimesheetAllowance: ITaskCard = {
  name: 'Create TimesheetAllowance',
  link: '/people/timesheet-allowances/create',
  icon: <HandHoldingDollarIcon />,
};

const createRailTemplate: ITaskCard = {
  name: 'Create Rail Template',
  link: '/operations/rail/rail-templates/create',
  icon: <TrainIcon />,
};

const createRailBooking: ITaskCard = {
  name: 'Create Rail Booking',
  link: '/operations/rail/rail-bookings/create',
  icon: <TrainIcon />,
};

const listCodeOfConduct: ITaskCard = {
  name: 'Code Of Conduct',
  link: '/compliance/code-of-conducts',
  icon: <BookIcon />,
};

const createCodeOfConduct: ITaskCard = {
  name: 'Create Code Of Conduct',
  link: '/compliance/code-of-conducts/create',
  icon: <BookIcon />,
};

const listLostPropertyItems: ITaskCard = {
  name: 'Lost Property',
  link: `/compliance/lost-property-items?status=${LostPropertyItemStatus.OnHand}`,
  icon: <BookIcon />,
};

const createLostPropertyItem: ITaskCard = {
  name: 'Create Lost Property Item',
  link: '/compliance/lost-property-items/create',
  icon: <BookIcon />,
};

const createCustomerComplaint: ITaskCard = {
  name: 'Create Customer Complaint',
  link: '/compliance/customer-complaints/create',
  icon: <BookIcon />,
};

const listCustomerComplaint: ITaskCard = {
  name: 'Customer Complaint',
  link: '/compliance/customer-complaints?defaultFilter=true',
  icon: <BookIcon />,
};

const listCctvRequests: ITaskCard = {
  name: 'CCTV Requests',
  link: '/compliance/cctv-requests?defaultFilter=true',
  icon: <VideoIcon />,
};

const createCctvRequest: ITaskCard = {
  name: 'Create CCTV Request',
  link: '/compliance/cctv-requests/create',
  icon: <VideoIcon />,
};

const createReportableEvent: ITaskCard = {
  name: 'Create Reportable Event',
  link: '/compliance/reportable-events/create',
  icon: <WarnIcon />,
};

const listFatigueBreachEvents: ITaskCard = {
  name: 'Fatigue Breach Events',
  link: '/compliance/fatigue-breach-events?defaultFilter=true',
  icon: <WarnIcon />,
};

const raiseOperationsDefect: ITaskCard = {
  name: 'Raise a Defect',
  link: '/operations/defects/create',
  icon: <ClipboardIcon />,
};

const maintainHourlyRates: ITaskCard = {
  name: 'Hourly Rates',
  link: '/sales/hourly-rates',
  icon: <HandHoldingDollarIcon />,
};

const createLeave: ITaskCard = {
  name: 'Create Leave',
  link: '/people/leaves/create',
  icon: <UmbrellaBeach />,
};

const createTimesheet: ITaskCard = {
  name: 'Create Timesheet',
  link: '/people/timesheets/create',
  icon: <ClockIcon />,
};

const assetsWithUnknownKms: ITaskCard = {
  name: 'Assets with unknown Kms',
  link: '/operations/assets-unknown-kms/list?defaultFilter=true',
  icon: <BusIcon />,
};

const createContractCharterBooking: ITaskCard = {
  name: 'Create Contract Booking',
  link: '/accounting/contract-charter-bookings/create',
  icon: <PlusIcon />,
};

const TaskCardItem = {
  raiseDefect,
  createAsset,
  partsGroup,
  createPartsGroup,
  engines,
  owners,
  assetGroups,
  taskSubcategory,
  createTaskSubcategory,
  createAdhocTask,
  lowFloorFutureTasks,
  createJob,
  checklists,
  createChecklist,
  createEngine,
  createAssetGroup,
  odometerReadings,
  unallocatedAssets,
  createOdometerReadings,
  labourPrices,
  jobTask,
  jobHistory,
  assetComponentsRegister,
  assetPartsRegister,
  assetCubicRegister,
  assetUsedParts,
  serviceSchedule,
  servicesDueByDate,
  servicesDueBykms,
  assetsWithUnknownKms,
  createRosterGroup,
  operations: {
    rail: {
      railTemplates: {
        createRailTemplate,
      },
      railBookings: {
        createRailBooking,
      },
    },
    holidays: {
      listHolidays,
      createHoliday,
    },
    subcontractors: {
      createSubcontractor,
      listSubcontractor,
    },
    operationsDiary: {
      createOperationsDiaryEntry,
    },
    compliance: {
      codeOfConduct: {
        createCodeOfConduct,
        listCodeOfConduct,
      },
      lostProperty: {
        createLostPropertyItem,
        listLostPropertyItems,
      },
      customerComplaint: {
        createCustomerComplaint,
        listCustomerComplaint,
      },
      cctvRequest: {
        createCctvRequest,
        listCctvRequests,
      },
      reportableEvent: {
        createReportableEvent,
      },
      listFatigueBreachEvents,
    },
    charterContracts: {
      listCharterContracts,
      createCharterContract,
    },
    contractCharterBookings: { createContractCharterBooking },
    raiseOperationsDefect,
    maintanOperationsDiaryEntrySubjects: maintainOperationsDiaryEntrySubjects,
    overwriteTabletOdometerReadings,
    listProcessDriverAuthorization,
    processResultsFile,
    listRosterGroups,
    listAuthorisedDevices,
  },
  sales: {
    createCustomer,
    createQuote,
    createBoardingPoint,
    listExtraTypes,
    createExtraType,
    maintainHourlyRates,
    listVehicleTypes,
    createVehicleType,
  },
  urban: {
    createShift,
    createRoster,
    listGenerateJobsOperations,
    createOperationsJob,
    createGenerateJobsOperation,
  },
  workshop: {
    createSupplier,
    createTask,
    partCategory,
    createPartCategory,
    createPart,
    createPurchaseOrder,
    recordStocktake,
    partsUsedTransactions,
    partsTransactions,
    tpsUsedReport,
    createOwner,
    componentTypes,
    techSpecs,
    createTechSpecs,
    shiftTemplates,
    createShiftTemplate,
    rosterTemplates,
    createRosterTemplate,
    createWorkshopRoster,
    createWorkshopShift,
    viewWorkshopRosterChanges,
    dataFuel,
    taskSubcategory,
    createTaskSubcategory,
    labourPrices,
    machineryTemplate,
    documents,
    createDocument,
  },
  people: {
    createStaffMember,
    listExternalIdentities,
    createNote,
    createSms,
    skillSpecs,
    createLeave,
    createTimesheet,
    listRoles,
    createRole,
    listTimesheetAllowances,
    createTimesheetAllowance,
    createDriverUser,
    equipmentTypes: {
      listEquipmentTypes,
      createEquipmentType,
    },
  },
};

export default TaskCardItem;
