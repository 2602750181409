import CreateLeave, {
  ICreateLeaveProps,
} from 'src/views/kioskRoutes/components/connected/CreateLeaveForm/CreateLeaveForm';
import inject from 'src/views/injectFromKioskStore';
import { TIMEZONE } from 'src/appSettings';

type RemainingKeys = Exclude<keyof ICreateLeaveProps, 'afterSubmitAction'>;
type InjectedProps = Pick<ICreateLeaveProps, RemainingKeys>;

const Container = inject<InjectedProps, ICreateLeaveProps>((allStores, p) => ({
  createLeave: allStores.rootStore.kiosk.leaves.createLeaveForDriver,
  employmentStatus: allStores.rootStore.account.employmentStatus,
  canUseTimesheets: allStores.rootStore.account.canUseTimesheets,
  timezone: TIMEZONE,
  leaveCategories: allStores.rootStore.kiosk.leaves.leaveCategories.slice(),
  loadLeaveCategories: allStores.rootStore.kiosk.leaves.loadLeaveCategories,
}))(CreateLeave);

export default Container;
