import './CreateLeaveForm.scss';
import { Input, Button, FormGroup, Label, FormFeedback, InputGroup } from 'reactstrap';
import DateTimeInput from 'src/views/components/calendar/DateTimeInput';
import Select from 'react-select';
import { DateTime } from 'luxon';
import YesNoToggleButton from 'src/views/kioskRoutes/components/presentational/YesNoToggleButton/YesNoToggleButton';
import DateInput, {
  parseEditingFormattedDateString,
} from 'src/views/components/Page/fields/subfields/DateInput';
import { formatDuration } from 'src/infrastructure/formattingUtils';
import FocusWrapper from 'src/views/components/FocusWrapper';
import { Component } from 'react';
import { isCasual } from 'src/domain/entities/people/employmentStatus/employmentStatusHelpers';
import { ENABLE_SHOW_OVERSEAS_TRAVEL_FIELD } from 'src/appSettings';

type EmploymentStatusDto = Common.Dtos.EmploymentStatusDto;
type ListLeaveCategoriesQuery = Common.Queries.People.ListLeaveCategories.ListLeaveCategoriesQuery;
type LeaveCategoryDto = Common.Dtos.LeaveCategoryDto;

export interface ICreateLeaveProps {
  employmentStatus?: EmploymentStatusDto;
  createLeave: (
    command: People.Domain.Commands.Leaves.CreateLeaveForDriver.CreateLeaveForDriverCommand
  ) => Promise<void>;
  canUseTimesheets: boolean | undefined;
  afterSubmitAction?: () => void;
  timezone: string;
  leaveCategories: LeaveCategoryDto[];
  loadLeaveCategories: (query: Partial<ListLeaveCategoriesQuery>) => Promise<void>;
}

interface ICreateLeaveState {
  leaveCategoryId?: number;
  selectFullDays: boolean;
  start?: string;
  startInternal: string;
  end?: string;
  endInternal: string;
  overseasTravel?: boolean;
  description: string;
  duration: string;
  // tslint:disable-next-line:no-any
  formErrors: any;
  submitting: boolean;
}

class CreateLeave extends Component<ICreateLeaveProps, ICreateLeaveState> {
  constructor(props: ICreateLeaveProps) {
    super(props);
    this.state = {
      leaveCategoryId: this.setDefaultIfCasual(props.employmentStatus?.id),
      selectFullDays: true,
      start: undefined,
      startInternal: '',
      end: undefined,
      endInternal: '',
      description: '',
      duration: '',
      formErrors: {},
      submitting: false,
      overseasTravel: undefined,
    };
  }
  setDefaultIfCasual = (employmentStatusId: number | undefined): number | undefined => {
    return employmentStatusId && isCasual(employmentStatusId)
      ? this.props.leaveCategories.find(x => x.isValidForCasualStaff)?.id
      : 1;
  };

  private readonly showOverseasTravelField = ENABLE_SHOW_OVERSEAS_TRAVEL_FIELD;

  private readonly validLeaveTypes = this.props.leaveCategories
    .filter(x => x.canStaffMemberRequest)
    .map(x => x.id);

  private readonly casualValidLeaveTypes = this.props.leaveCategories
    .filter(x => x.isValidForCasualStaff)
    .map(x => x.id);

  parseDateString(dateAsString?: string): DateTime | undefined {
    const { timezone } = this.props;
    if (!dateAsString) {
      return undefined;
    }
    return this.state.selectFullDays
      ? parseEditingFormattedDateString(dateAsString, timezone)
      : DateTime.fromISO(dateAsString, { zone: timezone });
  }

  validateStart() {
    const start = this.parseDateString(this.state.startInternal);

    if (!start || !start.isValid) {
      return this.state.selectFullDays
        ? 'A valid date is required'
        : 'A valid date & time is required';
    }
    if (start < DateTime.local().setZone(this.props.timezone)) {
      return 'Cannot enter leave in the past';
    }
    return undefined;
  }

  validateEnd() {
    const start = this.parseDateString(this.state.startInternal)!;
    const end = this.parseDateString(this.state.endInternal);

    if (!end || !end.isValid) {
      return this.state.selectFullDays
        ? 'A valid date is required'
        : 'A valid date & time is required';
    }
    if (start && start.isValid && end <= start) {
      return 'End cannot be before start';
    }
    return undefined;
  }

  validateDescription() {
    return !this.state.description ? 'Please provide a valid reason' : undefined;
  }

  validateLeaveCategory() {
    return !this.state.leaveCategoryId ? 'Leave type is required' : undefined;
  }

  validateOverseasTravel() {
    return this.state.overseasTravel === undefined
      ? 'Please indicate if your leave involves overseas travel'
      : undefined;
  }

  validate() {
    return {
      start: this.validateStart(),
      end: this.validateEnd(),
      leaveCategoryId: this.validateLeaveCategory(),
      description: this.validateDescription(),
      overseasTravel: this.showOverseasTravelField && this.validateOverseasTravel(),
    };
  }

  validateForm(updateFields?: string[]) {
    let errors = this.validate();
    if (updateFields && updateFields.length > 0) {
      // poor man's change tracking
      Object.keys(errors).forEach(key => {
        if (updateFields.indexOf(key) === -1) {
          errors[key] = undefined;
        }
      });
    }

    this.setState({ formErrors: errors });
    const msgs = Object.keys(errors)
      .map(key => {
        return errors[key];
      })
      .filter(val => !!val);
    return msgs.length === 0;
  }

  handleSubmit(event: React.FormEvent<EventTarget>) {
    event.preventDefault();
    if (!this.validateForm()) {
      return;
    }

    this.setState({ submitting: true });

    const { selectFullDays, start, end, leaveCategoryId, description, overseasTravel } = this.state;

    const promise = this.props.createLeave({
      start: this.parseDateString(start)!.toISO(),
      end: this.parseDateString(end)!.toISO(),
      leaveCategoryId: leaveCategoryId!,
      description: description,
      selectFullDays: selectFullDays,
      overseasTravel: this.showOverseasTravelField ? !!overseasTravel : false,
    });

    if (this.props.afterSubmitAction) {
      promise
        .then(this.props.afterSubmitAction)
        .finally(() => this.setState({ submitting: false }));
    } else {
      promise.finally(() => this.setState({ submitting: false }));
    }
  }

  private formatLeaveDuration = (startValue?: string, endValue?: string): string => {
    const start = this.parseDateString(startValue!);
    const end = this.parseDateString(endValue!);

    if (start && start.isValid && end && end.isValid) {
      const duration = end.diff(start);
      return formatDuration(duration);
    }
    return '';
  };

  updateState(values: Partial<ICreateLeaveState>) {
    this.setState(
      {
        ...values,
      } as ICreateLeaveState,
      () => {
        this.validateForm(Object.keys(values));
      }
    );
  }

  startDateOnChange = (newDateValue?: string): void => {
    this.updateState({
      startInternal: newDateValue,
    });
  };

  startDateOnBlur = (): void => {
    this.updateState({
      start: this.state.startInternal,
      duration: this.formatLeaveDuration(this.state.startInternal, this.state.endInternal),
    });
  };

  endDateOnChange = (newDateValue?: string): void => {
    this.updateState({
      endInternal: newDateValue,
    });
  };

  endDateOnBlur = (): void => {
    this.updateState({
      end: this.state.endInternal,
      duration: this.formatLeaveDuration(this.state.startInternal, this.state.endInternal),
    });
  };

  render() {
    return (
      <div className="create-leave-component">
        <form autoComplete="off" onSubmit={e => this.handleSubmit(e)}>
          <div className="form">
            <div className="leave-type">
              <FormGroup>
                <Label for="leaveType">Leave Type*</Label>
                <Select
                  clearable
                  options={this.props.leaveCategories.filter(
                    x =>
                      (this.props.employmentStatus && isCasual(this.props.employmentStatus.id)
                        ? this.casualValidLeaveTypes
                        : this.validLeaveTypes
                      ).indexOf(x.id) !== -1
                  )}
                  valueKey="id"
                  labelKey="description"
                  value={this.state.leaveCategoryId}
                  onChange={s => {
                    const category = s as LeaveCategoryDto;
                    this.setState({ leaveCategoryId: category && category.id });
                  }}
                  matchProp="label"
                />
                <FormFeedback>{this.state.formErrors.leaveType}</FormFeedback>
              </FormGroup>
            </div>

            <div className="select-full-days">
              <FormGroup>
                <Label for="select-full-days">Select Full Days*</Label>
                <InputGroup>
                  <YesNoToggleButton
                    value={this.state.selectFullDays}
                    onChange={value => {
                      this.updateState({
                        selectFullDays: value,
                        start: undefined,
                        startInternal: '',
                        end: undefined,
                        endInternal: '',
                        duration: '',
                      });
                    }}
                  />
                </InputGroup>
                <FormFeedback>{this.state.formErrors.selectFullDays}</FormFeedback>
              </FormGroup>
            </div>

            <div className="start">
              <FormGroup>
                {this.state.selectFullDays ? (
                  <>
                    <Label for="start">First Day Of Leave* </Label>
                    <FocusWrapper onBlur={this.startDateOnBlur}>
                      <InputGroup>
                        <DateInput
                          displayAsInvalid={false}
                          onChange={this.startDateOnChange}
                          value={this.state.startInternal}
                          timezone={this.props.timezone}
                        />
                      </InputGroup>
                    </FocusWrapper>
                  </>
                ) : (
                  <>
                    <Label for="start">Start*</Label>
                    <FocusWrapper onBlur={this.startDateOnBlur}>
                      <DateTimeInput
                        onChange={this.startDateOnChange}
                        value={this.state.startInternal}
                        timezone={this.props.timezone}
                      />
                    </FocusWrapper>
                  </>
                )}
                <FormFeedback>{this.state.formErrors.start}</FormFeedback>
              </FormGroup>
            </div>
            <div className="end">
              <FormGroup>
                {this.state.selectFullDays ? (
                  <>
                    <Label for="end">Returning To Work On*</Label>
                    <FocusWrapper onBlur={this.endDateOnBlur}>
                      <InputGroup>
                        <DateInput
                          displayAsInvalid={false}
                          onChange={this.endDateOnChange}
                          value={this.state.endInternal}
                          timezone={this.props.timezone}
                        />
                      </InputGroup>
                    </FocusWrapper>
                  </>
                ) : (
                  <>
                    <Label for="end">End*</Label>
                    <FocusWrapper onBlur={this.endDateOnBlur}>
                      <DateTimeInput
                        onChange={this.endDateOnChange}
                        value={this.state.endInternal}
                        timezone={this.props.timezone}
                      />
                    </FocusWrapper>
                  </>
                )}
                <FormFeedback>{this.state.formErrors.end}</FormFeedback>
              </FormGroup>
            </div>

            <div className="duration">
              <FormGroup>
                <Label for="duration">Duration</Label>
                <Input
                  autoComplete="off"
                  className="readonly"
                  type="text"
                  name="duration"
                  id="duration"
                  disabled
                  value={this.state.duration}
                />
              </FormGroup>
            </div>

            {this.showOverseasTravelField && (
              <div className="overseas-travel">
                <FormGroup>
                  <Label for="overseasTravel">Will this leave involve overseas travel?*</Label>
                  <InputGroup>
                    <YesNoToggleButton
                      value={this.state.overseasTravel}
                      onChange={value => {
                        this.updateState({
                          overseasTravel: value,
                        });
                      }}
                    />
                  </InputGroup>
                  <FormFeedback>{this.state.formErrors.overseasTravel}</FormFeedback>
                </FormGroup>
              </div>
            )}

            <div className="description">
              <FormGroup>
                <Label for="description">
                  Please add further information to support your application*
                </Label>
                <Input
                  autoComplete="off"
                  type="textarea"
                  name="description"
                  id="description"
                  onChange={event =>
                    this.updateState({
                      description: event.target.value,
                    })
                  }
                  value={this.state.description}
                />
                <FormFeedback>{this.state.formErrors.description}</FormFeedback>
              </FormGroup>
            </div>
          </div>

          <div className="submit">
            <Button type="submit" size="lg" disabled={this.state.submitting}>
              Submit
            </Button>
          </div>
        </form>
      </div>
    );
  }
}

export default CreateLeave;
