import styles from './LeaveSchedulePopover.module.scss';

import { Link } from 'react-router-dom';
import { getLeaveStatusDescriptor } from 'src/api/enums';
import { IScheduledLeave } from 'src/domain/entities/people/leave/ScheduledLeaveModel';
import cn from 'classnames';
import { DateTime } from 'luxon';
import { formatDuration } from 'src/infrastructure/formattingUtils';

interface ILeaveSchedulePopoverProps {
  leave: IScheduledLeave;
  timezone: string;
  leaveCategories: Common.Dtos.LeaveCategoryDto[];
}

const LeaveSchedulePopover: React.FC<ILeaveSchedulePopoverProps> = ({
  leave,
  timezone,
  leaveCategories,
}) => {
  const zonedStart = DateTime.fromISO(leave.leaveStart, { zone: timezone });
  const zonedEnd = DateTime.fromISO(leave.leaveEnd, { zone: timezone });

  const formatDateTime = (dateTime: DateTime) => {
    const format =
      dateTime.toISOTime() === dateTime.startOf('day').toISOTime()
        ? DateTime.DATE_SHORT
        : DateTime.DATETIME_SHORT;
    return dateTime.setLocale('local').toLocaleString(format);
  };

  return (
    <div className={cn(styles['leave-schedule-popover-list'])}>
      <h5>Leave Request</h5>
      <dl>
        <dt>Leave Number:</dt>
        <dd>
          <Link to={`/people/leaves/${leave.id}`}>{leave.leaveNumber}</Link>
        </dd>
      </dl>
      <dl>
        <dt>Who:</dt>
        <dd>
          <Link to={`/people/staff-members/${leave.staffMemberId}`}>{leave.staffMemberName}</Link> (
          {leave.employmentStatus})
        </dd>
      </dl>
      <dl>
        <dt>Type:</dt>
        <dd>{leaveCategories.find(x => x.id === leave.leaveCategoryId)?.description}</dd>
      </dl>
      <dl>
        <dt>Start:</dt>
        <dd>{formatDateTime(zonedStart)}</dd>
      </dl>
      <dl>
        <dt>End:</dt>
        <dd>{formatDateTime(zonedEnd)}</dd>
      </dl>
      <dl>
        <dt>Duration:</dt>
        <dd>{formatDuration(leave.leaveInterval.toDuration())}</dd>
      </dl>
      <dl>
        <dt>Status:</dt>
        <dd>{getLeaveStatusDescriptor(leave.status).description}</dd>
      </dl>
    </div>
  );
};

export default LeaveSchedulePopover;
