import { ChangeState } from 'src/api/enums';
import { getSubmitCloseModalActionGroupDef } from 'src/views/definitionBuilders/common';
import { FieldType, ModalDefBuilder, PaneType } from 'src/views/definitionBuilders/types';

type AssetDto = Common.Dtos.AssetNameDto;
export const getAddAssetsModalDef = (assets: AssetDto[]): ModalDefBuilder => {
  return api => {
    const linkedAssets = api.parentFormApi.getValue('assets');
    return {
      title: 'Add Assets',
      asForm: true,
      onFormSubmit: values => {
        var items = values.assetsToLoad.map((item: AssetDto) => ({
          ...item,
          changeState: ChangeState.Added,
        }));
        const assets = api.parentFormApi.getValue('assets');
        api.parentFormApi.setValue(['assets'], assets.concat(items));
        return Promise.resolve();
      },
      panels: [
        {
          panes: [
            {
              paneType: PaneType.formFieldsPane,
              fields: [
                {
                  fieldType: FieldType.selectMultiField,
                  label: 'Assets',
                  dataAddr: 'assetsToLoad',
                  optionItems: assets,
                  valueKey: 'id',
                  descriptionKey: 'name',
                  valuesToDisable: () => {
                    return linkedAssets.map((a: { id: any }) => a.id);
                  },
                },
              ],
            },
          ],
        },
      ],
      secondaryActions: [getSubmitCloseModalActionGroupDef('Submit')],
    };
  };
};
