import { types, flow, getRoot } from 'mobx-state-tree';
import { DateTime } from 'luxon';
import { getAjax } from 'src/domain/services/storeEnvironment';
import { kioskUrls } from 'src/domain/services/apiUrls';
import { LeaveStatus } from 'src/api/enums';
import { IKioskRootStoreModel } from '../../KioskRootStoreModel';

type LeaveDto = Common.Dtos.LeaveDto;
type LeaveCategoryDto = Common.Dtos.LeaveCategoryDto;

export const LeavesModel = types
  .model('LeavesModel', {
    leaves: types.array(types.frozen<LeaveDto>()),
    weeksLeaves: types.array(types.frozen<LeaveDto>()),
    leaveCategories: types.array(types.frozen<LeaveCategoryDto>()),
  })
  .actions(self => {
    const ajax = getAjax(self);
    const root = getRoot<IKioskRootStoreModel>(self);

    const loadLeavesForDriver = flow(function*(from: DateTime, to: DateTime) {
      self.leaves.clear();
      self.leaves = yield ajax.raw
        .get(kioskUrls.jobUrls.loadLeavesForDriver(from, to))
        .toPromise()
        .then<LeaveDto[]>(r => r.response);
    });

    const loadWeeksLeavesForDriver = flow(function*(from: DateTime, to: DateTime) {
      self.weeksLeaves.clear();
      self.weeksLeaves = yield ajax.raw
        .get(kioskUrls.jobUrls.loadLeavesForDriver(from, to))
        .toPromise()
        .then<LeaveDto[]>(r => r.response);
    });

    const createLeaveForDriver = flow(function*(
      command: People.Domain.Commands.Leaves.CreateLeaveForDriver.CreateLeaveForDriverCommand
    ) {
      yield ajax.raw.post(`/api/kiosk/leaves`, command).toPromise();
      root.history.push(`${root.home}/leave`);
    });

    const loadLeaveCategories = flow(function*() {
      self.leaveCategories.clear();
      self.leaveCategories = yield ajax.raw
        .get(`/api/people/leave-categories`)
        .toPromise()
        .then<LeaveCategoryDto[]>(r => r.response);
    });

    return {
      loadLeavesForDriver,
      loadWeeksLeavesForDriver,
      createLeaveForDriver,
      loadLeaveCategories,
    };
  })
  .views(self => ({
    get approvedLeaves() {
      return self.leaves && self.leaves.filter(l => l.status === LeaveStatus.Approved);
    },
  }));
