import cn from 'classnames';
import { Link } from 'react-router-dom';
import { ConflictType } from 'src/api/enums';
import { AddressCardIcon, BusIcon, UserIcon } from 'src/images/icons';
import { IntervalFormat } from 'src/views/components/IntervalFormat';
import './UnacceptableConflictFieldItem.scss';

type WorkshopJobConflictItem = Operations.Domain.Queries.ViewJob.WorkshopJobConflictItem;
type VehicleMarkedOutOfServiceConflictItem = Operations.Domain.Queries.ViewJob.VehicleMarkedOutOfServiceConflictItem;
type PeopleLeaveConflictItem = Operations.Domain.Queries.ViewJob.PeopleLeaveConflictItem;
type LicenceConflictItem = Operations.Domain.Queries.ViewJob.LicenceConflictItem;

export interface IUnacceptableConflictFieldItemProps {
  conflict:
    | WorkshopJobConflictItem
    | PeopleLeaveConflictItem
    | VehicleMarkedOutOfServiceConflictItem
    | LicenceConflictItem;
}

const UnacceptableConflictFieldItem: React.FC<IUnacceptableConflictFieldItemProps> = ({
  conflict,
}: IUnacceptableConflictFieldItemProps) => {
  const getLink = () => {
    switch (conflict.conflictType.id) {
      case ConflictType.WorkshopJobAsset:
        const wConflict = conflict as WorkshopJobConflictItem;
        const wUrl = `/workshop/jobs/${wConflict.workshopJobId}`;
        return <Link to={wUrl}>W Maintenance {wConflict.jobNumber}</Link>;
      case ConflictType.VehicleMarkedOutOfService:
        const vConflict = conflict as VehicleMarkedOutOfServiceConflictItem;
        const vUrl = `/workshop/job/${vConflict.workshopJobId}`;
        return <Link to={vUrl}>W Out Of Service</Link>;
      case ConflictType.PeopleLeave:
        const lConflict = conflict as PeopleLeaveConflictItem;
        const lUrl = `/people/staff-members/${lConflict.staffMemberId}`;
        return <Link to={lUrl}>L {lConflict.leaveCategory.description}</Link>;
      case ConflictType.DriverLicence:
        const dlConflict = conflict as LicenceConflictItem;
        const dlUrl = `/people/staff-members/${dlConflict.staffMemberId}`;
        return <Link to={dlUrl}>DL {dlConflict.staffMemberName}</Link>;
      case ConflictType.DriverAuthorisation:
        const daConflict = conflict as LicenceConflictItem;
        const daUrl = `/people/staff-members/${daConflict.staffMemberId}`;
        return <Link to={daUrl}>DA {daConflict.staffMemberName}</Link>;
      default:
        throw new Error('Unsupported Conflict Type');
    }
  };

  const getIcon = () => {
    switch (conflict.conflictType.id) {
      case ConflictType.WorkshopJobAsset:
      case ConflictType.VehicleMarkedOutOfService:
        return <BusIcon />;
      case ConflictType.PeopleLeave:
        return <UserIcon />;
      case ConflictType.DriverAuthorisation:
      case ConflictType.DriverLicence:
        return <AddressCardIcon />;
      default:
        throw new Error('Unsupported Conflict Type');
    }
  };

  return (
    <li className={cn('unacceptable-conflict-field-item conflict-item')}>
      <span className="conflict-type-icon" title={conflict.conflictType.description}>
        {getIcon()}
      </span>
      <span className="conflict-summary">{getLink()}</span>
      <IntervalFormat
        className="conflict-interval"
        startValue={conflict.conflictStart}
        endValue={conflict.conflictEnd}
      />
    </li>
  );
};

export default UnacceptableConflictFieldItem;
