import { FieldType, FieldDefs } from 'src/views/definitionBuilders/types/field';
import ListPage, { IListPageDef } from 'src/views/components/Page/pages/ListPage';
import { PagePrimarySize } from 'src/views/definitionBuilders/types';
import { observer } from 'mobx-react';
import { useRootStore } from 'src/domain/entities/RootStoreModel';
import withQueryParams, { IQueryParamsProps } from 'src/views/hocs/withQueryParams';
import { BasicSearch } from 'src/domain/baseTypes';
import { formatDateMed } from 'src/domain/dateHelper';
import PrimaryTitle from 'src/views/components/Page/PrimaryTitle/PrimaryTitle';
import VehicleCountIndicator from './VehicleCountIndicator';
import { useEffect, useState } from 'react';
import { TaskCardItem } from 'src/views/components/TaskCard';

const ListAttachments: React.FC<IQueryParamsProps<BasicSearch>> = observer(props => {
  const rootStore = useRootStore();
  const model = rootStore.workshop.listAssetAttachments;
  const [isLoadingFilters, setIsLoadingFilters] = useState<boolean>(true);
  const assetModel = rootStore.asset;
  const vehicleTypesModel = rootStore.operations.vehicleTypes;

  useEffect(() => {
    Promise.all([
      assetModel.loadAssetCategories(),
      assetModel.loadAssetSubcategories(),
      vehicleTypesModel.loadVehicleTypes(),
    ]).then(() => setIsLoadingFilters(false));
  }, []);
  const getPageDef = (): IListPageDef => {
    const filterFieldDefs: FieldDefs[] = [
      {
        fieldType: FieldType.yesNoField,
        label: 'Available On Drivers Tablet',
        dataAddr: 'isAvailableOnDriversTablet',
      },
      {
        fieldType: FieldType.selectMultiField,
        label: 'Category',
        dataAddr: 'categories',
        useValueOnly: true,
        valueKey: 'id',
        descriptionKey: 'description',
        optionItems: assetModel.categories,
      },
      {
        fieldType: FieldType.selectMultiField,
        label: 'Subcategory',
        dataAddr: 'subcategories',
        useValueOnly: true,
        valueKey: 'id',
        descriptionKey: 'description',
        optionItems: assetModel.subcategories.slice(),
      },
      {
        fieldType: FieldType.selectMultiField,
        label: 'Vehicle Types',
        dataAddr: 'vehicleTypes',
        useValueOnly: true,
        valueKey: 'id',
        descriptionKey: 'description',
        optionItems: vehicleTypesModel.vehicleTypes.slice(),
      },
    ];
    return {
      primaryTitle: <PrimaryTitle title="Asset Documents"></PrimaryTitle>,
      primarySize: PagePrimarySize.full,
      onLoadData: model.listItems,
      createLink: TaskCardItem.workshop.createDocument,
      externalSearch: true,
      hasMoreData: model.hasMoreItems,
      primaryFields: [
        {
          fieldType: FieldType.textField,
          dataAddr: 'id',
          label: 'Document',
          formatReadonly: d => 'DOC-' + d.parentValue.documentNumber,
          linkTo: d => `/workshop/documents/${d.parentValue.id}`,
        },
        {
          fieldType: FieldType.textField,
          dataAddr: 'fileName',
          label: 'File name',
        },
        {
          fieldType: FieldType.textField,
          dataAddr: 'id',
          label: 'Vehicles',
          formatReadonly: d => (
            <VehicleCountIndicator
              vehicleCount={d.parentValue.vehicleCount}
              asset={d.parentValue.asset}
            />
          ),
          columnWidth: '4rem',
        },
        {
          fieldType: FieldType.textField,
          dataAddr: 'createdOn',
          label: 'Uploaded Date',
          formatReadonly: d => d.parentValue.createdOn && formatDateMed(d.parentValue.createdOn),
        },
        {
          fieldType: FieldType.yesNoField,
          dataAddr: 'isAvailableOnDriversTablet',
          label: "Available on Driver's Tablet",
        },
      ],
      filterAction: {
        disabled: isLoadingFilters,
        filterFields: filterFieldDefs,
      },
    };
  };

  return <ListPage data={model.items.slice()} def={getPageDef()} />;
});

export default withQueryParams(ListAttachments);
