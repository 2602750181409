import './LeaveTable.scss';

import Table from 'reactstrap/lib/Table';
import { DateTimeFormat } from 'src/views/components/DateTimeFormat';
import { leaveStatusDescription, LeaveStatus } from 'src/api/enums';
import { DateTime } from 'luxon';
import { formatDuration } from 'src/infrastructure/formattingUtils';

type LeaveDto = Common.Dtos.LeaveDto;

interface ILeaveTableProps {
  leaves: LeaveDto[];
  timezone: string;
}

function displayDuration(leave: LeaveDto) {
  const start = DateTime.fromISO(leave.start);
  const end = DateTime.fromISO(leave.end);
  const duration = end.diff(start);
  return formatDuration(duration);
}

const LeaveTable: React.FC<ILeaveTableProps> = ({ leaves, timezone }) => {
  return (
    <div className="leave-table-component">
      <Table borderless>
        <thead>
          <tr>
            <th>Type</th>
            <th>Status</th>
            <th>Start</th>
            <th>Returning</th>
            <th>Duration</th>
          </tr>
        </thead>
        {leaves.map(leave => {
          return (
            <tbody key={leave.id}>
              <tr>
                <td>{leave.leaveCategory.description}</td>
                <td>{leaveStatusDescription(leave.status)}</td>
                <td>
                  <DateTimeFormat value={leave.start} timezone={timezone} />
                </td>
                <td>
                  <DateTimeFormat
                    value={leave.end}
                    previousValue={leave.start}
                    timezone={timezone}
                  />
                </td>
                <td>{displayDuration(leave)}</td>
              </tr>
              {leave.status === LeaveStatus.Declined ? (
                <tr>
                  <td />
                  <td colSpan={4}>Decline Reason: {leave.declineReason}</td>
                </tr>
              ) : null}
            </tbody>
          );
        })}
      </Table>
    </div>
  );
};

export default LeaveTable;
