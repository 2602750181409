import { types, flow } from 'mobx-state-tree';
import { getAjax } from 'src/domain/services';
import { IFile } from 'src/views/components/Page/fields/MultiFilePageField';
import { getBase64 } from 'src/infrastructure/fileUtil';
import { UpdateAttachmentFormData } from 'src/views/routes/workshop/attachment/getUpdateAttachmentModalDef';
import {
  AggregatesWithAttachments,
  DownloadAttachmentQuery,
  isValidAttachment,
  LoadAttachmentsQuery,
  SwapAttachmentOrderData,
} from 'src/views/components/Attachment/attachmentHelper';
import { DeleteAttachmentFormData } from 'src/views/routes/workshop/attachment/getDeleteAttachmentModalDef';

type DeleteAttachmentCommand = Workshop.Domain.Commands.Attachment.DeleteAttachment.DeleteAssetAttachmentCommand;
type UpdateAttachmentCommand = Workshop.Domain.Commands.Attachment.UpdateAttachment.UpdateSupplierAttachmentCommand;
type AttachmentDto = Workshop.Domain.Queries.Attachment.WorkshopAttachmentDetailsDto;
type AssetAttachmentsDto = Common.Dtos.AssetAttachmentsDto;
type AddAttachmentCommand = Workshop.Domain.Commands.Attachment.AddAttachment.AddSupplierAttachmentCommand;
type AddAssetAttachmentCommand = Workshop.Domain.Commands.Attachment.AddAttachment.AddAssetAttachmentCommand;
type UpdateAssetAttachmentCommand = Workshop.Domain.Commands.Attachment.UpdateAttachment.UpdateAssetAttachmentCommand;

export type AddAttachmentData = {
  aggregateId: string;
  fileName: string;
  aggregateType: AggregatesWithAttachments;
  file: IFile;
  attachmentExpiryDateTo: string | undefined;
  attachmentExpiryDateFrom: string | undefined;
  isAvailableOnDriversTablet: boolean;
};

export type AddAssetAttachmentData = {
  aggregateId: string;
  fileName: string;
  aggregateType: AggregatesWithAttachments;
  file: IFile;
  isAvailableOnDriversTablet: boolean;
};

export const WorkshopAttachmentModel = types
  .model('WorkshopAttachmentModel', {
    attachments: types.optional(types.array(types.frozen<AttachmentDto>()), []),
    assetAttachments: types.optional(types.array(types.frozen<AssetAttachmentsDto>()), []),
  })
  .actions(self => {
    const ajax = getAjax(self);
    const addAssetAttachment = flow(function*(data: AddAssetAttachmentData) {
      if (!isValidAttachment(data.file)) {
        return;
      }
      const command: AddAssetAttachmentCommand = {
        ...data,
        mimeType: data.file.file!.type,
        data: yield getBase64(data.file.file!),
        isAvailableOnDriversTablet: data.isAvailableOnDriversTablet,
      };
      yield ajax.workshop.attachments.addAttachment(command, 'asset');
    });

    const updateAssetAttachment = flow(function*(data: UpdateAttachmentFormData) {
      const command: UpdateAssetAttachmentCommand = {
        attachmentDetailsId: data.attachmentDetailsId,
        aggregateId: data.aggregateId,
        fileName: data.fileName,
        isAvailableOnDriversTablet: data.isAvailableOnDriversTablet,
      };
      yield ajax.workshop.attachments.updateAttachment(command, 'asset');
    });

    const addWorkshopAttachment = flow(function*(data: AddAttachmentData) {
      if (!isValidAttachment(data.file)) {
        return;
      }
      if (data.aggregateType === 'asset') {
        const command: AddAssetAttachmentCommand = {
          ...data,
          mimeType: data.file.file!.type,
          data: yield getBase64(data.file.file!),
          isAvailableOnDriversTablet: data.isAvailableOnDriversTablet,
        };

        yield ajax.workshop.attachments.addAttachment(command, data.aggregateType);
      } else {
        const command: AddAttachmentCommand = {
          ...data,
          mimeType: data.file.file!.type,
          data: yield getBase64(data.file.file!),
          attachmentExpiryDateTo: data.attachmentExpiryDateTo,
          attachmentExpiryDateFrom: data.attachmentExpiryDateFrom,
        };

        yield ajax.workshop.attachments.addAttachment(command, data.aggregateType);
      }
    });

    const loadWorkshopAttachmentDetails = flow(function*(query: LoadAttachmentsQuery) {
      self.attachments = yield ajax.workshop.attachments.loadAttachments(
        query.aggregateId,
        query.aggregateType
      );
    });

    const downloadWorkshopAttachment = flow(function*(query: DownloadAttachmentQuery) {
      yield ajax.workshop.attachments.downloadAttachment(
        query.attachmentDetailsId,
        query.aggregateId,
        query.aggregateType
      );
    });

    const deleteWorkshopAttachment = flow(function*(data: DeleteAttachmentFormData) {
      const command: DeleteAttachmentCommand = {
        documentId: data.documentId,
        aggregateId: data.aggregateId,
        attachmentDetailsId: data.attachmentDetailsId,
      };
      yield ajax.workshop.attachments.deleteAttachment(command, data.aggregateType);
    });

    const updateWorkshopAttachment = flow(function*(data: UpdateAttachmentFormData) {
      const command: UpdateAttachmentCommand = {
        attachmentDetailsId: data.attachmentDetailsId,
        aggregateId: data.aggregateId,
        fileName: data.fileName,
        attachmentExpiryDateTo: data.attachmentExpiryDateTo,
        attachmentExpiryDateFrom: data.attachmentExpiryDateFrom,
      };
      yield ajax.workshop.attachments.updateAttachment(command, data.aggregateType);
    });

    const clearWorkshopAttachments = () => {
      self.attachments.clear();
    };

    const swapWorkshopAttachment = flow(function*(data: SwapAttachmentOrderData) {
      yield ajax.workshop.attachments.swapAttachmentOrder(data);
    });

    return {
      addAssetAttachment,
      updateAssetAttachment,
      addWorkshopAttachment,
      loadWorkshopAttachmentDetails,
      downloadWorkshopAttachment,
      deleteWorkshopAttachment,
      updateWorkshopAttachment,
      swapWorkshopAttachment,
      clearWorkshopAttachments,
    };
  });
