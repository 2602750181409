import './Leaves.scss';

import { DateTime } from 'luxon';
import { AlertCard } from '../../../components/presentational/Alerts/AlertCard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlane } from '@fortawesome/free-solid-svg-icons';

type LeaveDto = Common.Dtos.LeaveDto;

interface ILeaveProps {
  leave: LeaveDto;
  timezone: string;
  link: string;
}

const dateFormat = (value?: DateTime | string, short?: boolean, timezone?: string) => {
  if (value === null || value === undefined) {
    return null;
  }

  if (typeof value === 'string') {
    value = DateTime.fromISO(value, { zone: timezone || 'local' });
  }

  return `${value.toLocaleString(short ? DateTime.DATE_SHORT : DateTime.DATE_MED)}`;
};

const formatLeaveDescription = (leaveCategory: string) => {
  switch (leaveCategory) {
    case 'Fatigue':
      return 'on Fatigue leave';
    case 'Unavailable':
      return 'Unavailable';
    case 'TimeOffInLieu':
      return `on a ${leaveCategory}`;
    default:
      return `on ${leaveCategory}`;
  }
};

const isToday = (date: DateTime) =>
  DateTime.local()
    .setZone(date.zone)
    .hasSame(date, 'day');
const isYesterday = (date: DateTime) =>
  DateTime.local()
    .setZone(date.zone)
    .plus({ days: -1 })
    .hasSame(date, 'day');

export const formatDateWithoutTime = (date: string, timezone: string) =>
  `${DateTime.fromISO(date, { zone: timezone }).toFormat('EEEE, MMMM dd, yyyy')}`;

export const formatStartDateWithTime = (date: string, timezone?: string) =>
  isYesterday(DateTime.fromISO(date, { zone: timezone || 'local' }))
    ? 'today'
    : isToday(DateTime.fromISO(date, { zone: timezone || 'local' }))
    ? `today from ${DateTime.fromISO(date, { zone: timezone || 'local' }).toFormat('HH:mm')}`
    : `from ${DateTime.fromISO(date, { zone: timezone || 'local' }).toFormat(
        'HH:mm'
      )} on ${dateFormat(date)}`;

export const formatEndDateWithTime = (date: string, timezone?: string) =>
  isToday(DateTime.fromISO(date, { zone: timezone || 'local' }))
    ? `at ${DateTime.fromISO(date, { zone: timezone || 'local' }).toFormat('HH:mm')}`
    : `at ${DateTime.fromISO(date, { zone: timezone || 'local' }).toFormat(
        'HH:mm'
      )} on ${dateFormat(date)}`;

export const LeaveMessage = ({ leave, timezone, link }: ILeaveProps) => {
  return leave.selectFullDays ? (
    <AlertCard
      showAlert={true}
      heading="Leave"
      message={`You are ${formatLeaveDescription(
        leave.leaveCategory.description
      )} today returning ${formatDateWithoutTime(leave.end, timezone)}`}
      link={link}
      linkText="View Details"
      icon={<FontAwesomeIcon icon={faPlane} />}
      iconColor="#007BFF"
    />
  ) : (
    <AlertCard
      showAlert={true}
      heading="Leave"
      message={`You are ${formatLeaveDescription(
        leave.leaveCategory.description
      )} ${formatStartDateWithTime(leave.start, timezone)} returning ${formatEndDateWithTime(
        leave.end,
        timezone
      )}`}
      link={link}
      linkText="View Details"
      icon={<FontAwesomeIcon icon={faPlane} />}
      iconColor="#007BFF"
    />
  );
};

interface ILeavesProps {
  leaves: LeaveDto[];
  timezone: string;
  link: string;
}

const Leaves: React.FC<ILeavesProps> = ({ leaves, timezone, link }) => {
  return (
    <>
      {leaves
        .sort((a, b) => a.start.localeCompare(b.start))
        .map(leave => (
          <LeaveMessage key={`leave-${leave.id}`} leave={leave} timezone={timezone} link={link} />
        ))}
    </>
  );
};

export default Leaves;
