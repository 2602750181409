import { DateTime } from 'luxon';
import { getLeaveStatusDescriptor } from 'src/api/enums';
import { PlusIcon, EditIcon, TrashIcon } from 'src/images/icons';
import { formatDuration } from 'src/infrastructure/formattingUtils';
import {
  PaneType,
  FieldType,
  ActionType,
  ShellModalSize,
  ISectionDef,
} from 'src/views/definitionBuilders/types';
import getLeaveModalDef from './getLeaveModalDef';
import { getSubmitCloseModalActionGroupDef } from 'src/views/definitionBuilders/common';

type LeaveDto = Common.Dtos.LeaveDto;
type CreateLeaveCommand = People.Domain.Commands.Leaves.CreateLeave.CreateLeaveCommand;
type UpdateLeaveCommand = People.Domain.Commands.Leaves.UpdateLeave.UpdateLeaveCommand;

export default function getLeaveSectionDef(
  canSave: boolean,
  timezone: string,
  hideRecurCallback: (hide: boolean) => void,
  hideRecur: boolean,
  staffMemberId: string,
  createLeave: (cmd: CreateLeaveCommand) => Promise<void>,
  updateLeave: (cmd: UpdateLeaveCommand) => Promise<void>,
  deleteLeave: (leaveId: string, deleteRecurrences: boolean) => Promise<void>,
  primarySectionEditing: boolean,
  leaveCategories: Common.Dtos.LeaveCategoryDto[],
  leaves?: LeaveDto[]
): ISectionDef {
  return {
    title: 'Leave',
    panels: [
      {
        panes: [
          {
            paneType: PaneType.tablePane,
            dataAddr: 'leaves',
            neverEditable: true,
            fields: [
              {
                fieldType: FieldType.readonlyField,
                dataAddr: 'leaveNumber',
                label: 'Leave Number',
                linkTo: d => `/people/leaves/${d.parentValue.id}`,
              },
              {
                fieldType: FieldType.readonlyField,
                dataAddr: 'leaveCategory.id',
                label: 'Type',
                formatReadonly: d => {
                  const desc = leaveCategories.find(c => c.id === d.fieldValue)?.description;
                  return (
                    <>
                      <p>{desc}</p>
                      <p>
                        {d.parentValue &&
                          (d.parentValue.sourceLeaveId
                            ? '(Recurrence)'
                            : d.parentValue.repeats && d.parentValue.repeats.length > 0
                            ? '(Recurred)'
                            : '')}
                      </p>
                    </>
                  );
                },
              },
              {
                fieldType: FieldType.readonlyField,
                dataAddr: 'status',
                label: 'Status',
                formatReadonly: d => getLeaveStatusDescriptor(d.fieldValue).description,
              },
              {
                fieldType: FieldType.dateTimeField,
                dataAddr: 'start',
                label: 'Start',
                timezone: timezone,
              },
              {
                fieldType: FieldType.dateTimeField,
                dataAddr: 'end',
                label: 'End',
                timezone: timezone,
              },
              {
                fieldType: FieldType.readonlyField,
                label: 'Duration',
                formatReadonly: d => {
                  const leave = d.parentValue as LeaveDto;
                  const start = DateTime.fromISO(leave.start);
                  const end = DateTime.fromISO(leave.end);
                  const duration = end.diff(start);
                  return formatDuration(duration);
                },
              },
              {
                fieldType: FieldType.actionListField,
                columnWidth: '1px',
                hidden: () => !canSave || primarySectionEditing,
                actionGroups: [
                  {
                    actions: [
                      {
                        actionType: ActionType.modalActionButton,
                        label: 'Edit Leave',
                        icon: <EditIcon />,
                        modalSize: ShellModalSize.twoThirds,
                        modalDef: getLeaveModalDef(
                          'edit',
                          staffMemberId,
                          createLeave,
                          updateLeave,
                          timezone,
                          leaves,
                          leaveCategories,
                          hideRecurCallback,
                          hideRecur
                        ),
                      },
                      {
                        actionType: ActionType.modalActionButton,
                        icon: <TrashIcon />,
                        label: 'Delete Leave',
                        modalSize: ShellModalSize.oneQuarter,
                        modalDef: modalDefApi => ({
                          title: 'Delete Leave',
                          asForm: true,
                          panels: [
                            {
                              panes: [
                                {
                                  paneType: PaneType.formFieldsPane,
                                  fields: [
                                    {
                                      fieldType: FieldType.yesNoField,
                                      dataAddr: 'deleteRecurrences',
                                      label: 'Also delete any recurrences?',
                                      mandatory: true,
                                      hidden: d => {
                                        const leaveItem = d.parentValue as LeaveDto;
                                        return !(leaveItem.repeats && leaveItem.repeats.length > 0);
                                      },
                                    },
                                  ],
                                },
                                {
                                  paneType: PaneType.customPane,
                                  render: _ => <p>Are you sure you want to delete this leave?</p>,
                                },
                              ],
                            },
                          ],
                          secondaryActions: [getSubmitCloseModalActionGroupDef('Ok')],
                          onFormSubmit: f => {
                            const deleteRecurrences = f.deleteRecurrences === true;

                            const leave = modalDefApi.actionData.parentValue as LeaveDto;
                            return deleteLeave(leave.id, deleteRecurrences);
                          },
                        }),
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
    secondaryActions: [
      {
        actions: [
          {
            hidden: () => !canSave || primarySectionEditing,
            actionType: ActionType.modalActionButton,
            label: 'Add Leave',
            icon: <PlusIcon />,
            level: 'primary',
            modalSize: ShellModalSize.twoThirds,
            modalDef: getLeaveModalDef(
              'add',
              staffMemberId,
              createLeave,
              updateLeave,
              timezone,
              leaves,
              leaveCategories,
              hideRecurCallback,
              hideRecur
            ),
          },
        ],
      },
    ],
  };
}
