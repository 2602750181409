import { Link } from 'react-router-dom';
import { WorkshopConflictStatus } from 'src/api/enums';
import { CheckIcon, UndoIcon } from 'src/images/icons';
import {
  ISectionDef,
  PaneType,
  ActionType,
  ShellModalSize,
} from 'src/views/definitionBuilders/types';
import { getSubmitCloseModalActionGroupDef } from 'src/views/definitionBuilders/common';
import ActionBar from 'src/views/components/Page/actions/ActionBar';
import { IntervalFormat } from 'src/views/components/IntervalFormat';

type AcceptConflictCommand = Workshop.Domain.Commands.Conflict.AcceptConflict.AcceptConflictCommand;

export default function getConflictsSectionDef(
  canManageShifts: boolean,
  shiftId: string,
  acceptConflict: (command: AcceptConflictCommand) => Promise<void>,
  cancelAcceptanceConflict: (conflictId: string) => Promise<void>,
  loadShift: () => Promise<void>
): ISectionDef {
  return {
    title: 'Conflicts',
    hidden: !shiftId,
    panels: [
      {
        panes: [
          {
            paneType: PaneType.customPane,
            dataAddr: 'conflicts.conflictsCount',
            hidden: d => d.paneValue,
            render: () => (
              <div>
                <em>There are no conflicts for this job</em>
              </div>
            ),
          },
          {
            paneType: PaneType.repeatingPane,
            dataAddr: 'conflicts.shiftConflicts',
            noItemsMessage: null,
            hidden: d => !d.paneValue || !d.paneValue.length,
            itemPanes: [
              {
                paneType: PaneType.customPane,
                render: api => {
                  return (
                    <div>
                      <div>
                        <strong>Conflict Type </strong>
                        {api.data.parentValue.conflictType.description}
                      </div>
                      <div>
                        <strong>Job </strong>
                        <Link
                          to={`/workshop/shifts/${api.data.parentValue.shiftId}`}>{`${api.data.parentValue.shiftNumber} - ${api.data.parentValue.shiftName}`}</Link>
                      </div>
                      <div>
                        <strong>Conflicting Times </strong>
                        <IntervalFormat
                          startValue={api.data.parentValue.conflictStart}
                          endValue={api.data.parentValue.conflictEnd}
                        />
                      </div>
                      <div>
                        <strong>Conflict Status </strong>
                        {api.data.parentValue.conflictStatus.description}
                      </div>
                      <div>
                        {api.data.parentValue.conflictStatus.id !==
                        WorkshopConflictStatus.Accepted ? null : (
                          <span>
                            <strong>Accepted by</strong> {api.data.parentValue.lastModifiedBy}
                          </span>
                        )}
                      </div>
                      <div>
                        {api.data.parentValue.conflictStatus.id ===
                          WorkshopConflictStatus.Accepted || !canManageShifts ? null : (
                          <ActionBar
                            actionGroupDefs={[
                              {
                                actions: [
                                  {
                                    actionType: ActionType.modalActionButton,
                                    label: 'Accept',
                                    icon: <CheckIcon />,
                                    modalSize: ShellModalSize.oneThird,
                                    modalDef: () => ({
                                      title: 'Accept Conflict',
                                      asForm: true,
                                      panels: [
                                        {
                                          panes: [
                                            {
                                              paneType: PaneType.customPane,
                                              render: () => (
                                                <>
                                                  <p>
                                                    Are you sure you want to accept the conflict?
                                                  </p>
                                                </>
                                              ),
                                            },
                                          ],
                                        },
                                      ],
                                      secondaryActions: [
                                        getSubmitCloseModalActionGroupDef('Accept'),
                                      ],
                                      onFormSubmit: v => {
                                        return acceptConflict({
                                          conflictId: api.data.parentValue.conflictId,
                                        } as AcceptConflictCommand).then(loadShift);
                                      },
                                    }),
                                  },
                                ],
                              },
                            ]}
                            actionMeta={{
                              formSubmitting: false,
                              borderless: true,
                            }}
                            actionData={{ ...api.data, actionValue: undefined }}
                          />
                        )}
                      </div>
                      <div>
                        {api.data.parentValue.conflictStatus.id ===
                          WorkshopConflictStatus.Accepted && canManageShifts ? (
                          <ActionBar
                            actionGroupDefs={[
                              {
                                actions: [
                                  {
                                    actionType: ActionType.modalActionButton,
                                    label: 'Cancel Acceptance',
                                    icon: <UndoIcon />,
                                    modalSize: ShellModalSize.oneThird,
                                    modalDef: modalDefApi => ({
                                      title: 'Cancel Conflict Acceptance',
                                      asForm: true,
                                      panels: [
                                        {
                                          panes: [
                                            {
                                              paneType: PaneType.customPane,
                                              render: d =>
                                                'Are you sure you want to cancel your acceptance?',
                                            },
                                          ],
                                        },
                                      ],
                                      secondaryActions: [getSubmitCloseModalActionGroupDef()],
                                      onFormSubmit: v => {
                                        return cancelAcceptanceConflict(
                                          api.data.parentValue.conflictId
                                        ).then(loadShift);
                                      },
                                    }),
                                  },
                                ],
                              },
                            ]}
                            actionMeta={{
                              formSubmitting: false,
                              borderless: true,
                            }}
                            actionData={{ ...api.data, actionValue: undefined }}
                          />
                        ) : null}
                      </div>
                    </div>
                  );
                },
              },
            ],
          },
          {
            paneType: PaneType.repeatingPane,
            dataAddr: 'conflicts.peopleLeaveConflicts',
            noItemsMessage: null,
            hidden: d => !d.paneValue || !d.paneValue.length,
            itemPanes: [
              {
                paneType: PaneType.customPane,
                render: api => {
                  return (
                    <div>
                      <div>
                        <strong>Conflict Type </strong>
                        {api.data.parentValue.conflictType.description}
                      </div>
                      <div>
                        <strong>Leave Number </strong>
                        <Link to={`/people/leaves/${api.data.parentValue.peopleLeaveId}`}>
                          {api.data.parentValue.leaveNumber}
                        </Link>
                      </div>
                      <div>
                        <strong>Leave Type </strong>
                        {api.data.parentValue.leaveCategory.description}
                      </div>
                      <div>
                        <strong>Conflicting Times </strong>
                        <IntervalFormat
                          startValue={api.data.parentValue.conflictStart}
                          endValue={api.data.parentValue.conflictEnd}
                        />
                      </div>
                      <div>
                        <strong>Conflict cannot be accepted</strong>
                      </div>
                    </div>
                  );
                },
              },
            ],
          },
        ],
      },
    ],
  };
}
