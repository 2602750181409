import { buildListPageApiSearchModelTypedQuery } from 'src/domain/modelBuilders/buildListPageApiSearchModel';
import { flow, getRoot, types } from 'mobx-state-tree';
import * as queryString from 'query-string';
import { getAjax, NotificationType } from 'src/domain/services';
import { IRootStoreModel } from 'src/domain/entities/RootStoreModel';

type LeaveCategoryDto = Common.Dtos.LeaveCategoryDto;

const ListLeavesModel = buildListPageApiSearchModelTypedQuery<
  People.Domain.Queries.ListLeave.ListLeaveQuery
>()('ListLeavesModel', d =>
  d.ajax.raw
    .get(`/api/people/leaves?${queryString.stringify(d.query)}`)
    .map(r => r.response as Common.Dtos.ListResult<People.Domain.Queries.ListLeave.LeaveItem>)
);

export const LeavesOptionsModel = types
  .model('LeavesModel', {
    leaveCategories: types.array(types.frozen<LeaveCategoryDto>()),
  })
  .actions(self => {
    const ajax = getAjax(self);
    const root = getRoot(self) as IRootStoreModel;

    const exportToExcel = flow(function*(
      query: Partial<People.Domain.Queries.ListLeave.ListLeaveQuery>
    ) {
      root.notifications.addNotification(`The file is being generated ...`, {
        type: NotificationType.info,
      });

      return yield ajax.raw
        .getFile(`/api/people/leaves/excel?${queryString.stringify(query)}`)
        .toPromise()
        .then<Blob>(r => r.response);
    });

    const loadLeaveCategories = flow(function*() {
      self.leaveCategories.clear();
      self.leaveCategories = yield ajax.raw
        .get(`/api/people/leave-categories`)
        .toPromise()
        .then<LeaveCategoryDto[]>(r => r.response);
    });

    return {
      exportToExcel,
      loadLeaveCategories,
    };
  });

export const LeavesModel = types.compose(ListLeavesModel, LeavesOptionsModel);
