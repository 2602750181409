import { ChangeState } from 'src/api/enums';
import {
  ActionType,
  ModalDefBuilder,
  IHasChangeState,
  PaneType,
} from 'src/views/definitionBuilders/types';

import { getLeaveEditPanelDef } from '../../leaves/MaintainLeave/getLeaveEditPanelDef';
import { recurPanelPane } from '../../leaves/MaintainLeave/getRecurPanelDef';
import { DateTime } from 'luxon';
import { Link } from 'react-router-dom';

type CreateLeaveCommand = People.Domain.Commands.Leaves.CreateLeave.CreateLeaveCommand;
type UpdateLeaveCommand = People.Domain.Commands.Leaves.UpdateLeave.UpdateLeaveCommand;
type LeaveDto = Common.Dtos.LeaveDto;
type LeaveRepeatDto = Common.Dtos.LeaveRepeatDto;
type Repeat = People.Domain.Queries.ViewLeave.LeaveItem.Repeat;
type LeaveCategoryDto = Common.Dtos.LeaveCategoryDto;

const mapRepeats = (leave: LeaveDto, repeats: LeaveRepeatDto[], timezone: string) => {
  const leaveStart = DateTime.fromISO(leave.start, { zone: timezone });

  return (
    repeats &&
    repeats.map((d: Repeat & IHasChangeState) => {
      const repeatStart = DateTime.fromISO(d.start, { zone: timezone });

      return {
        id: d.id,
        changeState: d.changeState || ChangeState.Unchanged,
        start: DateTime.fromObject({
          year: repeatStart.year,
          month: repeatStart.month,
          day: repeatStart.day,
          hour: leaveStart.hour,
          minute: leaveStart.minute,
          zone: timezone,
        }).toISO(),
      };
    })
  );
};

// CalendarPicker can return dates without times and zones (i.e. not an instant, which is what I need in the Leave areas)
const getISODateString = (date: string, timezone: string): string =>
  DateTime.fromISO(date, { zone: timezone }).toISO();

export default function getLeaveModalDef(
  mode: 'add' | 'edit',
  staffMemberId: string,
  createLeave: (command: CreateLeaveCommand) => Promise<void>,
  updateLeave: (command: UpdateLeaveCommand) => Promise<void>,
  timezone: string,
  leaves: LeaveDto[] | undefined,
  leaveCategories: LeaveCategoryDto[],
  hideRecurCallback: (hide: boolean) => void,
  hideRecur: boolean
): ModalDefBuilder {
  const isUpdateMode = mode !== 'add';

  return modalDefApi => {
    return {
      title: `${mode === 'add' ? 'Add' : 'Edit'} Leave`,
      asForm: true,
      explicitData: mode === 'add' ? { selectFullDays: true } : modalDefApi.actionData.paneValue,
      panels: formApi => {
        const leaveId = formApi.values.id;
        const matchingLeave = leaves && leaves.find(x => x.id === leaveId);

        return [
          getLeaveEditPanelDef(
            staffMemberId,
            isUpdateMode,
            leaveCategories,
            timezone,
            hideRecurCallback,
            () => matchingLeave
          ),
          {
            title: 'Recur',
            isLogicalSubsection: true,
            key: 'recur-section',
            panes: [
              {
                paneType: PaneType.customPane,
                hidden: !hideRecur,
                dataAddr: 'start',
                render: () => {
                  if (!matchingLeave) {
                    return undefined;
                  }

                  if (matchingLeave.repeats.length > 0) {
                    return (
                      <strong className="recur-warnings">
                        Changing the date of the leave will delete existing recurrences and will
                        need to be reconfigured.
                      </strong>
                    );
                  } else if (matchingLeave.sourceLeaveId) {
                    return (
                      <strong className="recur-warnings">
                        This is a recurrence. Changing the date will unlink this leave from it's
                        <Link to={`/people/leaves/${matchingLeave.sourceLeaveId}`}>
                          {' '}
                          Original Leave
                        </Link>
                      </strong>
                    );
                  }
                  return undefined;
                },
              },
              {
                paneType: PaneType.nestingPane,
                panes: recurPanelPane(
                  isUpdateMode,
                  true,
                  !isUpdateMode,
                  formApi,
                  timezone,
                  matchingLeave && (matchingLeave.repeats as Repeat[])
                ),
              },
            ],
          },
        ];
      },
      secondaryActions: [
        {
          actions: [
            {
              actionType: ActionType.submitActionButton,
              label: 'Ok',
              level: 'primary',
            },
            {
              actionType: ActionType.closeModalActionButton,
              closeAction: () => hideRecurCallback(false),
            },
          ],
        },
      ],
      onFormPreSubmit: (leave: LeaveDto): CreateLeaveCommand | UpdateLeaveCommand => {
        return {
          staffMemberId: staffMemberId,
          leaveId: leave.id,
          leaveCategoryId: leave.leaveCategory.id,
          start: getISODateString(leave.start, timezone),
          end: getISODateString(leave.end, timezone),
          description: leave.description,
          selectFullDays: leave.selectFullDays,
          resetAcknowledgement: leave.resetAcknowledgement,
          repeats: mapRepeats(leave, leave.repeats, timezone),
          overseasTravel: leave.overseasTravel,
        };
      },
      onFormSubmit: mode === 'add' ? createLeave : updateLeave,
    };
  };
}
