import './PeopleLeave.scss';
import { Component } from 'react';
import { IPeopleLeaveItem } from 'src/domain/entities/operations/job/ListJobsForAllocationsModel';

interface IPeopleLeaveProps {
  item: IPeopleLeaveItem;
}

export default class PeopleLeave extends Component<IPeopleLeaveProps> {
  render() {
    const { item } = this.props;
    const leaveTypeDescr = item.leaveCategory.description;
    const description = `L • ${leaveTypeDescr}`;
    return (
      <div className="allocations-people-leave-component" title={description}>
        <div className="description">{description}</div>
      </div>
    );
  }
}
