import './PeopleLeavePopup.scss';
import { Component } from 'react';
import humanizeDuration from 'src/infrastructure/humanizeDuration';
import { IPeopleLeaveItem } from 'src/domain/entities/operations/job/ListJobsForAllocationsModel';
import { IntervalFormat } from 'src/views/components/IntervalFormat';
import PopupField from './PopupField';

const humanize = humanizeDuration.humanizer({ units: ['d', 'h'], round: true });

interface IPeopleLeavePopupProps {
  leave: IPeopleLeaveItem;
}

class PeopleLeavePopup extends Component<IPeopleLeavePopupProps> {
  render() {
    const { leave } = this.props;
    return (
      <section className={`people-leave-popup-component allocations-item-popup`}>
        <PopupField
          className="leave-number"
          label="Leave Number"
          value={leave.leaveNumber}
          valueLinkTo={`/people/leaves/${leave.id}`}
        />
        <PopupField
          className="leave-type"
          label="Leave Type"
          value={leave.leaveCategory.description}
        />
        <PopupField
          className="leave-timing"
          label="Scheduled"
          value={
            <div>
              <IntervalFormat startValue={leave.begin} endValue={leave.end} /> - (
              {humanize(leave.bounds.toDuration('milliseconds').milliseconds)})
            </div>
          }
        />
        <PopupField
          className="description"
          label="Description"
          value={leave.description}
          hidden={!leave.description}
        />
      </section>
    );
  }
}

export default PeopleLeavePopup;
