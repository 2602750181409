import './LeaveLegend.scss';
import cn from 'classnames';
import { observer } from 'mobx-react';
interface ILeaveLegendProps {
  leaveCategories: any[];
}

const LeaveLegend: React.FC<ILeaveLegendProps> = observer((props: ILeaveLegendProps) => {
  return (
    <div className="leave-legend-component">
      <div className="title">Leave Legend</div>
      <div className="categories">
        {props.leaveCategories.map(leaveCategory => {
          return (
            <div key={leaveCategory.id} className="leave-category">
              <span className={cn('leave-box', `leaveCategory-` + leaveCategory.id)} />{' '}
              {leaveCategory.description}
            </div>
          );
        })}
        <div className="leave-category">
          <span className={cn('leave-box', 'pending')} /> Pending
        </div>
      </div>
    </div>
  );
});

export default LeaveLegend;
