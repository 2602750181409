import './MaintainLeave.scss';
import { useEffect, useState } from 'react';
import { RouteComponentProps, Link } from 'react-router-dom';
import CrudPage, { CrudPageMode, ICrudPageDef } from 'src/views/components/Page/pages/CrudPage';
import { getLeaveEditPanelDef } from './getLeaveEditPanelDef';

import {
  PagePrimarySize,
  PaneType,
  FieldType,
  ActionType,
  ActionDefs,
  IHasChangeState,
} from 'src/views/definitionBuilders/types';
import { ShellModalSize } from 'src/views/components/Shell/ShellModal';
import { TrashIcon, CheckIcon, TimesIcon } from 'src/images/icons';
import { leaveStatusDescription, LeaveStatus, ChangeState } from 'src/api/enums';
import getActivityLogPanelDef from './getActivityLogPanelDef';
import { recurPanelPane } from './getRecurPanelDef';
import { DateTime } from 'luxon';
import { getSubmitCloseModalActionGroupDef } from 'src/views/definitionBuilders/common';
import { observer } from 'mobx-react';
import { useRootStore } from 'src/domain/entities/RootStoreModel';
import { TIMEZONE, ENABLE_SHOW_OVERSEAS_TRAVEL_FIELD } from 'src/appSettings';

type CreateLeaveCommand = People.Domain.Commands.Leaves.CreateLeave.CreateLeaveCommand;
type UpdateLeaveCommand = People.Domain.Commands.Leaves.UpdateLeave.UpdateLeaveCommand;
type LeaveItem = People.Domain.Queries.ViewLeave.LeaveItem;
type DeclineLeaveCommand = People.Domain.Commands.Leaves.DeclineLeave.DeclineLeaveCommand;

type Repeat = People.Domain.Queries.ViewLeave.LeaveItem.Repeat;

export interface IMaintainLeaveProps {
  mode: CrudPageMode;
  route: RouteComponentProps<{ [x: string]: string | undefined }>;
}

const timezone = TIMEZONE;
const showOverseasTravelField = ENABLE_SHOW_OVERSEAS_TRAVEL_FIELD;

const MaintainLeave: React.FC<IMaintainLeaveProps> = observer((props: IMaintainLeaveProps) => {
  const rootStore = useRootStore();
  const peopleModel = rootStore.people;
  const loadStaffMembers = peopleModel.staffMembers.loadAllStaffMembers;
  const createLeave = peopleModel.leave.createLeave;
  const loadLeave = peopleModel.leave.loadLeave;
  const leave = peopleModel.leave.leave;
  const canModifyLeave =
    rootStore.account.isPeopleDepartmentMember ||
    rootStore.account.isOperationsDepartmentMember ||
    rootStore.account.isWorkshopDepartmentMember;
  const updateLeave = peopleModel.leave.updateLeave;
  const deleteLeave = peopleModel.leave.deleteLeave;
  const approveLeave = peopleModel.leave.approveLeave;
  const declineLeave = peopleModel.leave.declineLeave;
  const listActivityLogs = peopleModel.leave.listActivityLogs;
  const activityLogs = peopleModel.leave.activityLogs.slice();
  const loadLeaveCategories = peopleModel.leaves.loadLeaveCategories;
  const leaveCategories = peopleModel.leaves.leaveCategories.slice();

  const [hideRecur, setHideRecur] = useState<boolean>(false);

  const isCreateMode = props.mode === 'create';
  const isUpdateMode = props.mode === 'update';
  const leaveId = props.route.match.params.id!;

  useEffect(() => {
    loadStaffMembers();
    loadLeaveCategories();
  }, []);

  const loadData = async () => {
    if (leaveId) {
      await loadLeave(leaveId);
      await listActivityLogs(leaveId);
    }
  };

  const getISODateString = (date: string): string =>
    DateTime.fromISO(date, { zone: timezone }).toISO();

  const handlePreSubmitForCreate = (leave: LeaveItem): CreateLeaveCommand => {
    return {
      description: leave.description,
      start: getISODateString(leave.start),
      end: getISODateString(leave.end),
      staffMemberId: leave.staffMember.id,
      leaveCategoryId: leave.leaveCategoryId,
      selectFullDays: leave.selectFullDays,
      resetAcknowledgement: leave.resetAcknowledgement,
      repeats: mapRepeats(leave, timezone),
      overseasTravel: showOverseasTravelField ? leave.overseasTravel : false,
    };
  };

  const handlePreSubmitForUpdate = (leave: LeaveItem): UpdateLeaveCommand => {
    setHideRecur(false);
    return {
      leaveId: leaveId,
      description: leave.description,
      end: getISODateString(leave.end),
      start: getISODateString(leave.start),
      leaveCategoryId: leave.leaveCategoryId,
      selectFullDays: leave.selectFullDays,
      resetAcknowledgement: leave.resetAcknowledgement,
      repeats: mapRepeats(leave, timezone),
      overseasTravel: showOverseasTravelField ? leave.overseasTravel : false,
    };
  };

  const mapRepeats = (leave: LeaveItem, timezone: string) => {
    const leaveStart = DateTime.fromISO(leave.start, { zone: timezone });

    return (
      leave &&
      leave.repeats &&
      leave.repeats.map((d: Repeat & IHasChangeState) => {
        const repeatStart = DateTime.fromISO(d.start, { zone: timezone });

        return {
          id: d.id,
          changeState: d.changeState || ChangeState.Unchanged,
          start: DateTime.fromObject({
            year: repeatStart.year,
            month: repeatStart.month,
            day: repeatStart.day,
            hour: leaveStart.hour,
            minute: leaveStart.minute,
            zone: timezone,
          }).toISO(),
        };
      })
    );
  };

  const getActionLabel = (
    leaveVerb: string,
    resetAcknowledgement: boolean,
    includeFutureRepeats: boolean
  ) => {
    const resetClause = resetAcknowledgement ? ' and reset job acknowledgements' : '';
    const repeatClause = includeFutureRepeats ? ' & all future recurrences' : '';
    return `${leaveVerb} leave${resetClause}${repeatClause}`;
  };

  const getApproveLeaveActionDef = (
    leave: LeaveItem,
    approveLeave: (
      id: string,
      resetAcknowledgement: boolean,
      applyToFutureRecurringLeave: boolean
    ) => Promise<void>,
    resetAcknowledgement: boolean,
    includeFutureRepeats: boolean,
    hideCondition: () => boolean
  ): ActionDefs => {
    const label = getActionLabel('Approve', resetAcknowledgement, includeFutureRepeats);
    return {
      actionType: ActionType.modalActionButton,
      label: label,
      icon: <CheckIcon />,
      hidden: hideCondition,
      modalSize: ShellModalSize.oneQuarter,
      modalDef: () => ({
        title: label,
        asForm: true,
        panels: [
          {
            panes: [
              {
                paneType: PaneType.customPane,
                hidden: !resetAcknowledgement,
                render: () => (
                  <>
                    <span>
                      Jobs occurring after the start date of this leave will have their
                      acknowledgement reset. On the day before this staff member's next job starts,
                      they will be able to acknowledge their work via the kiosk or driver's app.
                    </span>
                  </>
                ),
              },
            ],
          },
          {
            panes: [
              {
                paneType: PaneType.customPane,
                render: () => (
                  <span>
                    {getActionLabel(
                      'Are you sure you want to approve',
                      resetAcknowledgement,
                      includeFutureRepeats
                    )}
                  </span>
                ),
              },
            ],
          },
        ],
        secondaryActions: [getSubmitCloseModalActionGroupDef('Ok')],
        onFormSubmit: () => approveLeave(leave!.id, resetAcknowledgement, includeFutureRepeats),
      }),
    };
  };

  const getDeclineLeaveActionDef = (
    leave: LeaveItem,
    declineLeave: (command: DeclineLeaveCommand) => Promise<void>,
    includeFutureRepeats: boolean,
    hideCondition: () => boolean
  ): ActionDefs => {
    return {
      actionType: ActionType.modalActionButton,
      label: getActionLabel('Decline', false, includeFutureRepeats),
      icon: <TimesIcon />,
      hidden: hideCondition,
      modalSize: ShellModalSize.oneQuarter,
      modalDef: () => ({
        title: getActionLabel('Decline', false, includeFutureRepeats),
        asForm: true,
        panels: [
          {
            panes: [
              {
                paneType: PaneType.customPane,
                render: () => <span>Are you sure you want to decline leave?</span>,
              },
              {
                paneType: PaneType.formFieldsPane,
                fields: [
                  {
                    fieldType: FieldType.textAreaField,
                    label: 'Decline Reason',
                    dataAddr: 'declineReason',
                    mandatory: true,
                    rows: 6,
                  },
                ],
              },
            ],
          },
        ],
        secondaryActions: [getSubmitCloseModalActionGroupDef('Ok')],
        onFormSubmit: v =>
          declineLeave({
            id: leaveId,
            declineReason: v.declineReason,
            applyToFutureRecurringLeave: includeFutureRepeats,
          }),
      }),
    };
  };
  const getDeleteLeaveActionDef = (
    leave: LeaveItem,
    deleteLeave: (
      id: string,
      deleteRecurrences: boolean,
      applyToFutureRecurringLeave: boolean
    ) => Promise<void>,
    includeFutureRepeats: boolean,
    hideCondition: () => boolean
  ): ActionDefs => {
    return {
      actionType: ActionType.modalActionButton,
      label: getActionLabel('Delete', false, includeFutureRepeats),
      icon: <TrashIcon />,
      modalSize: ShellModalSize.oneQuarter,
      hidden: hideCondition,
      modalDef: modalDefApi => ({
        title: getActionLabel('Delete', false, includeFutureRepeats),
        asForm: true,
        panels: [
          {
            panes: [
              {
                paneType: PaneType.formFieldsPane,
                fields: [
                  {
                    fieldType: FieldType.yesNoField,
                    dataAddr: 'deleteRecurrences',
                    label: 'Also delete any recurrences?',
                    mandatory: true,
                    hidden: d => {
                      const leaveItem = d.parentValue as LeaveItem;
                      return !leaveItem.repeats || leaveItem.repeats.length === 0;
                    },
                  },
                ],
              },
              {
                paneType: PaneType.customPane,
                render: () => <span>Are you sure you want to delete leave?</span>,
              },
            ],
          },
        ],
        secondaryActions: [getSubmitCloseModalActionGroupDef('Ok')],
        onFormSubmit: f => {
          const deleteRecurrences = f.deleteRecurrences === true;
          return deleteLeave(leaveId, deleteRecurrences, includeFutureRepeats);
        },
      }),
    };
  };

  const getPageDef = (updating: boolean): ICrudPageDef => {
    const updateRecurPanelVisibility = (hide: boolean) => {
      setHideRecur(hide);
    };

    const hasRepeats = (leave: LeaveItem) => {
      return !!(leave.repeats && leave.repeats.length > 0);
    };

    const isPartOfRecurringSequence = (leave: LeaveItem) => {
      return !!(leave.sourceLeaveId && leave.sourceLeaveId.length > 0);
    };

    return {
      primarySize: PagePrimarySize.twoThirds,
      primarySection: {
        title: isCreateMode ? 'Create a Leave Request' : `Leave ${leave?.leaveNumber}`,
        badge: {
          label: (isUpdateMode && leave && leaveStatusDescription(leave.status)) || '',
        },
        primaryActions: [
          {
            actions: leave
              ? [
                  {
                    actionType: ActionType.actionCollection,
                    hidden: updating || !isUpdateMode || !canModifyLeave,
                    actionGroups: [
                      {
                        actions: [
                          getApproveLeaveActionDef(
                            leave,
                            approveLeave,
                            false,
                            hasRepeats(leave),
                            () => leave.status === LeaveStatus.Approved
                          ),
                          getApproveLeaveActionDef(
                            leave,
                            approveLeave,
                            true,
                            false,
                            () => leave.status === LeaveStatus.Approved
                          ),
                          getApproveLeaveActionDef(
                            leave,
                            approveLeave,
                            false,
                            true,
                            () =>
                              !(
                                leave.status === LeaveStatus.Declined &&
                                isPartOfRecurringSequence(leave)
                              )
                          ),
                          getDeclineLeaveActionDef(
                            leave,
                            declineLeave,
                            false,
                            () => leave.status === LeaveStatus.Declined
                          ),
                          getDeclineLeaveActionDef(
                            leave,
                            declineLeave,
                            true,
                            () =>
                              !(
                                leave.status === LeaveStatus.Approved &&
                                isPartOfRecurringSequence(leave)
                              )
                          ),
                        ],
                      },
                      {
                        actions: [
                          getDeleteLeaveActionDef(leave, deleteLeave, false, () => false),
                          getDeleteLeaveActionDef(
                            leave,
                            deleteLeave,
                            true,
                            () =>
                              !(
                                isPartOfRecurringSequence(leave) &&
                                (leave.status === LeaveStatus.Declined ||
                                  leave.status === LeaveStatus.Approved)
                              )
                          ),
                        ],
                      },
                    ],
                  },
                ]
              : [],
          },
        ],

        panels: [
          getLeaveEditPanelDef(
            undefined,
            isUpdateMode,
            leaveCategories,
            timezone,
            updateRecurPanelVisibility,
            () => leave // Required to bypass some javascript/react weirdness
          ),
        ],
        onFormPreSubmit: isCreateMode ? handlePreSubmitForCreate : handlePreSubmitForUpdate,
        onFormSubmit: values => (isCreateMode ? createLeave(values) : updateLeave(values)),
      },
      secondarySections: [
        {
          title: 'Recur',
          isLogicalSubsection: true,
          key: 'recur-section',
          panels: formApi => [
            {
              panes: [
                {
                  paneType: PaneType.customPane,
                  dataAddr: 'none',
                  hidden: !hideRecur,
                  render: () => {
                    if (!leave) {
                      return undefined;
                    }

                    if (leave.repeats.length > 0) {
                      return (
                        <strong className="recur-warnings">
                          Changing the date of the leave will delete existing recurrences and will
                          need to be reconfigured.
                        </strong>
                      );
                    } else if (leave.sourceLeaveId) {
                      return (
                        <strong className="recur-warnings">
                          This is a recurrence. Changing the date will unlink this leave from it's
                          <Link to={`/people/leaves/${leave.sourceLeaveId}`}> Original Leave</Link>
                        </strong>
                      );
                    }
                    return undefined;
                  },
                },
                {
                  paneType: PaneType.nestingPane,
                  panes: recurPanelPane(
                    isUpdateMode,
                    updating,
                    isCreateMode,
                    formApi,
                    timezone,
                    leave && leave.repeats
                  ),
                },
              ],
            },
          ],
        },
        getActivityLogPanelDef(leaveId, activityLogs),
      ],
    };
  };

  return (
    <CrudPage
      key={leaveId}
      def={({ updating }) => getPageDef(updating)}
      mode={props.mode}
      isEditingForbidden={!canModifyLeave}
      onLoadData={loadData}
      data={leave}
      createDefaultData={{ selectFullDays: true }}
      onCancel={() => setHideRecur(false)}
    />
  );
});

export default MaintainLeave;
