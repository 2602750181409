import { IAjax } from 'src/infrastructure/ajax';
import { workshopDocumentsUrls } from 'src/domain/services/apiUrls';

type UpdateDocumentCommand = Workshop.Domain.Commands.Document.UpdateDocument.UpdateDocumentCommand;
type CreateDocumentCommand = Workshop.Domain.Commands.Document.CreateDocument.CreateDocumentCommand;
type ReplaceDocumentCommand = Workshop.Domain.Commands.Document.ReplaceDocument.ReplaceDocumentCommand;

const returnVoid = () => {};
export default function init(ajax: IAjax) {
  function createDocument(command: CreateDocumentCommand) {
    return ajax
      .post(workshopDocumentsUrls.createDocument(), command)
      .toPromise()
      .then(r => {
        return r.response;
      });
  }
  function updateDocument(command: UpdateDocumentCommand, documentId: string) {
    return ajax.put(workshopDocumentsUrls.updateDocument(documentId), command).toPromise();
  }
  function replaceDocument(command: ReplaceDocumentCommand, documentId: string) {
    return ajax
      .put(workshopDocumentsUrls.replaceDocument(documentId), command)
      .toPromise()
      .then(r => {
        return r.response;
      });
  }
  function deleteDocument(documentId: string) {
    return ajax
      .httpDelete(workshopDocumentsUrls.deleteDocument(documentId))
      .toPromise()
      .then(_ => returnVoid());
  }
  return {
    documents: {
      createDocument,
      updateDocument,
      replaceDocument,
      deleteDocument,
    },
  };
}
